import React from 'react';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';
import { useSelector } from 'react-redux';

//props => images, setImages

// const FileUpload = ({ values, setValues, imageToLoad }) => {
const FileUpload = (props) => {
  const user = useSelector((state) => state.user);

  const addImage = (image) => {
    props.addImageHandler(image);
  };

  const removeImage = () => {
    props.removeImageHandler();
  };

  const fileUploadAndResize = (e) => {
    // console.log(e.target.files);
    // resize
    let files = e.target.files; // 3
    //let allUploadedFile = props.image;
    // console.log('ALL UPLAOD FILES ---->');

    if (files) {
      // setLoading(true);
      for (let i = 0; i < files.length; i++) {
        Resizer.imageFileResizer(
          files[i],
          1500,
          1500,
          'PNG',
          100,
          0,
          (uri) => {
            // console.log(uri);
            axios
              .post(
                `/api/images/uploadimages`,
                { image: uri },
                {
                  headers: {
                    authtoken: user ? user.token : '',
                  },
                }
              )
              .then((res) => {
                //console.log('IMAGE UPLOAD RES DATA', res);
                // setLoading(false);
                //allUploadedFile.push(res.data);

                addImage(res.data);
              })
              .catch((err) => {
                // setLoading(false);
                //console.log('CLOUDINARY UPLOAD ERR', err);
              });
          },
          'base64'
        );
      }
    }
    // send back to server to upload to cloudinary
    // set url to images[] in the parent component state - ProductCreate
  };

  const handleImageRemove = (public_id) => {
    // setLoading(true);
    // console.log("remove image", public_id);
    axios
      .post(
        `/api/images/removeimage`,
        { public_id },
        {
          headers: {
            authtoken: user ? user.token : '',
          },
        }
      )
      .then((res) => {
        // setLoading(false);
        //const { images } = values;
        // let filteredImages = props.images.filter((item) => {
        //   return item.public_id !== public_id;
        // });
        removeImage();
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (
    <div className="choose-img-box">
      <label className="add-new-image">
        Add image
        <input
          type="file"
          multiple={false}
          hidden
          accept="images/*"
          onChange={fileUploadAndResize}
        />
      </label>

      {props.image.url !== undefined && props.image.url !== '' && (
        <div className="image-container">
          <img
            src={`${props.image.url}`}
            style={{ width: '70px' }}
            alt="resource"
          />
          <div
            className="circle"
            onClick={() => handleImageRemove(props.image.public_id)}
          >
            <img src="/images/close.svg" className="close-icon" alt="close" />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
