export const updateProductionValidation = (product) => {
  //console.log('Product to validate', product);

  let isValidated = true;

  //no empty values
  // for (const value of Object.values(product)) {
  //   if (
  //     value === undefined ||
  //     value === null ||
  //     value === '' ||
  //     (Array.isArray(value) && value.length === 0)
  //   ) {
  //     isValidated = false; // At least one value is empty
  //     //console.log('NO EMPTY VALUES ACCEPTED');
  //     return isValidated;
  //   }
  // }
  for (const [key, value] of Object.entries(product)) {
    if (
      (key !== 'tech' &&
        (value === undefined ||
          value === null ||
          value === '' ||
          (Array.isArray(value) && value.length === 0))) ||
      (key === 'tech' && !Array.isArray(value))
    ) {
      isValidated = false; // At least one value is empty
      // console.log('NO EMPTY VALUES ACCEPTED');
      return isValidated;
    }
  }

  //price vs srp
  if (
    parseFloat(product.price) > parseFloat(product.srp) ||
    parseFloat(product.price) === parseFloat(product.srp)
  ) {
    //console.log('PRICE HAS TO BE LARGER THAN SRP', product.price, product.srp);
    isValidated = false;
    return isValidated;
  }

  // // Validate Data Types
  // if (
  //   typeof product.name !== 'string' ||
  //   typeof product.composition !== 'string' ||
  //   typeof product.description !== 'string' ||
  //   typeof product.price !== 'string' ||
  //   typeof product.srp !== 'string' ||
  //   typeof product.color !== 'string' ||
  //   typeof product.fabric !== 'string' ||
  //   typeof product.tech !== 'string' ||
  //   typeof product.type !== 'string'
  //   //typeof product.available !== 'boolean'
  // ) {
  //   console.log('Validate Data Types!');
  //   isValidated = false;
  //   return isValidated;
  // }

  // Validate Numeric Values
  if (isNaN(product.price) || isNaN(product.srp)) {
    console.log('Validate Numeric Values!');
    isValidated = false;
    return isValidated;
  }

  // Validate Price Validity
  if (product.price < 0 || product.srp < 0) {
    console.log('Validate Price Validity!');
    isValidated = false;
    return isValidated;
  }

  // Validate String Length (Maximum 100 characters)
  if (
    product.name.length > 100 ||
    product.composition.length > 100 ||
    product.description.length > 100 ||
    product.color.length > 100 ||
    product.fabric.length > 100 ||
    product.tech.length > 100
    //product.type.length > 100
  ) {
    console.log('Validate String Length (Maximum 100 characters)!');
    isValidated = false;
    return isValidated;
  }

  // Input Sanitization (Note: This is a simplified example and may not cover all cases)
  product.name = product.name.trim();
  product.composition = product.composition.trim();
  product.description = product.description.trim();
  //product.color = product.color.trim();
  product.fabric = product.fabric.trim();
  //product.tech = product.tech.trim();
  //product.type = product.type.trim();

  return isValidated;
};
