// import React, { useEffect, useState } from 'react';
// import AdminBodyStruct from './AdminBodyStruct';
// import StatisticsDashboard from '../../components/UI/admin/dashboard-page/StatisticsDashboard';
// import HeaderDashboard from '../../components/UI/admin/dashboard-page/HeaderDashboard';
// import { getOrdersForStats } from '../../functions/orders';
// import { useSelector } from 'react-redux';
// import InfoLoaderAdmin from '../../components/loaders/InfoLoaderAdmin';
// import { listCollections } from '../../functions/collections';

// function DashboardPage() {
//   const user = useSelector((state) => state.user);
//   //state
//   const [pCapsulesMen, setPCapsulesMen] = useState({});
//   const [pCapsulesWomen, setPCapsulesWomen] = useState({});
//   const [pMen, setPMen] = useState({});
//   const [pWomen, setPWomen] = useState({});
//   const [currentTypeMen, setCurrentTypeMen] = useState();
//   const [currentTypeWomen, setCurrentTypeWomen] = useState();
//   const [loading, setLoading] = useState(false);
//   const [isCollectionChoosen, setIsCollectionChoosen] = useState(false);
//   const [collections, setCollections] = useState([]);
//   const [isCollectionsLoaded, setIsCollectionsLoaded] = useState(false);

//   //effect
//   useEffect(() => {
//     setLoading(true);
//     loadCollections();
//     //loadStats() THIS IS DONE ONCE A COLLECTION IS CHOOSEN;
//   }, []);

//   // const loadCollections = () => {
//   //   listCollections(user.token)
//   //     .then((res) => {
//   //       setCollections(res.data.collections);
//   //       console.log('collections--->', res.data.collections);
//   //       setIsCollectionsLoaded(true);
//   //     })
//   //     .catch((err) =>
//   //       console.log('Could not load collections in DASHBOARD PAGE', err)
//   //     );
//   // };

//   const loadCollections = async () => {
//     try {
//       const res = await listCollections(user.token);
//       setCollections(res.data.collections);
//       console.log('collections--->', res.data.collections);
//       setIsCollectionsLoaded(true);
//     } catch (err) {
//       console.error('Could not load collections in DASHBOARD PAGE', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // const loadStats = (id) => {
//   //   //RESET
//   //   setIsCollectionChoosen(true);
//   //   //
//   //   getOrdersForStats({ collectionId: id }, user.token)
//   //     .then((res) => {
//   //       setPMen(res.data.mendict);
//   //       setPWomen(res.data.womendict);
//   //       setPCapsulesMen(res.data.capsulesmendict);
//   //       setPCapsulesWomen(res.data.capsuleswomendict);

//   //       // Check if mendict is not empty and set the first key
//   //       if (res.data.mendict && Object.keys(res.data.mendict).length > 0) {
//   //         const firstKey = Object.keys(res.data.mendict)[0];
//   //         setCurrentTypeMen(firstKey);
//   //       }

//   //       if (res.data.womendict && Object.keys(res.data.womendict).length > 0) {
//   //         const firstKey = Object.keys(res.data.womendict)[0];
//   //         setCurrentTypeWomen(firstKey);
//   //       }

//   //       setLoading(false);
//   //       setIsCollectionChoosen(true);
//   //       // console.log('STATS men data->', res.data.mendict);
//   //       // console.log('STATS women data->', res.data.womendict);
//   //       // console.log('STATS capsules men data->', res.data.capsulesmendict);
//   //       // console.log('STATS capsules women data->', res.data.capsuleswomendict);
//   //     })
//   //     .catch((err) => console.log(err));
//   // };

//   const loadStats = async (id) => {
//     try {
//       setLoading(true);
//       setIsCollectionChoosen(true);

//       const res = await getOrdersForStats({ collectionId: id }, user.token);

//       setPMen(res.data.mendict);
//       setPWomen(res.data.womendict);
//       setPCapsulesMen(res.data.capsulesmendict);
//       setPCapsulesWomen(res.data.capsuleswomendict);

//       if (res.data.mendict && Object.keys(res.data.mendict).length > 0) {
//         setCurrentTypeMen(Object.keys(res.data.mendict)[0]);
//       } else {
//         setCurrentTypeMen(null);
//       }

//       if (res.data.womendict && Object.keys(res.data.womendict).length > 0) {
//         setCurrentTypeWomen(Object.keys(res.data.womendict)[0]);
//       } else {
//         setCurrentTypeWomen(null);
//       }
//     } catch (err) {
//       console.error(err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   function getTotalQuantity(product) {
//     if (!product || !product.pidOrder) {
//       return 0; // Return 0 if the product or pidOrder is not provided or empty
//     }

//     return product.pidOrder.reduce((total, orderItem) => {
//       return total + orderItem.qty;
//     }, 0);
//   }

//   const sortProductsByTotalQuantity = (menDict, calculateTotalAmount) => {
//     const sortedMenDict = {};

//     // Sort by total quantity
//     const sortedMenEntries = Object.entries(menDict).sort(
//       ([typeNameA, typeDataA], [typeNameB, typeDataB]) => {
//         const totalA = calculateTotalAmount(typeDataA.products);
//         const totalB = calculateTotalAmount(typeDataB.products);
//         return totalB - totalA;
//       }
//     );

//     // Reconstruct the sorted dictionary
//     for (const [typeName, typeData] of sortedMenEntries) {
//       sortedMenDict[typeName] = typeData;
//     }

//     return sortedMenDict;
//   };

//   const handleLoadingData = (collectionId) => {
//     //console.log('ID', collectionId);
//     loadStats(collectionId);
//   };

//   return (
//     <AdminBodyStruct>
//       {/* <div className="flex-container-dashboard"> */}
//       {/* <div className="redirecting">
//         <h2>Page under construction</h2>
//       </div> */}
//       <div className="dashboard-page-box">
//         <HeaderDashboard
//           userName={user.name && user.name}
//           text={
//             'This is your dashboard, providing you with a comprehensive overview of a specific collection at a glance.'
//           }
//         />

//         <>
//           <h2>Elige una colección:</h2>
//           <div>
//             {isCollectionsLoaded ? (
//               <ul>
//                 {collections.map((collection, index) => (
//                   <li
//                     key={index}
//                     onClick={() => handleLoadingData(collection._id)}
//                   >
//                     {collection.name}
//                   </li>
//                 ))}
//               </ul>
//             ) : (
//               <InfoLoaderAdmin />
//             )}
//           </div>
//         </>

//         {isCollectionChoosen ? (
//           <>
//             <StatisticsDashboard
//               gender={'men'}
//               products={pMen}
//               pCapsules={pCapsulesMen}
//               currentType={currentTypeMen}
//               setCurrentType={setCurrentTypeMen}
//             />
//             <StatisticsDashboard
//               gender={'women'}
//               products={pWomen}
//               pCapsules={pCapsulesWomen}
//               currentType={currentTypeWomen}
//               setCurrentType={setCurrentTypeWomen}
//             />
//           </>
//         ) : (
//           ''
//         )}
//       </div>
//     </AdminBodyStruct>
//   );
// }

// export default DashboardPage;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdminBodyStruct from './AdminBodyStruct';
import StatisticsDashboard from '../../components/UI/admin/dashboard-page/StatisticsDashboard';
import HeaderDashboard from '../../components/UI/admin/dashboard-page/HeaderDashboard';
import { getOrdersForStats } from '../../functions/orders';
import InfoLoaderAdmin from '../../components/loaders/InfoLoaderAdmin';
import { listCollections } from '../../functions/collections';
import ChooseCollectionHeader from '../../components/UI/admin/ChooseCollectionHeader';

function DashboardPage() {
  const user = useSelector((state) => state.user);

  // State
  const [pCapsulesMen, setPCapsulesMen] = useState({});
  const [pCapsulesWomen, setPCapsulesWomen] = useState({});
  const [pMen, setPMen] = useState({});
  const [pWomen, setPWomen] = useState({});
  const [currentTypeMen, setCurrentTypeMen] = useState(null);
  const [currentTypeWomen, setCurrentTypeWomen] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCollectionChoosen, setIsCollectionChoosen] = useState(false);
  const [currentCollection, setCurrentCollection] = useState('');
  const [collections, setCollections] = useState([]);
  const [isCollectionsLoaded, setIsCollectionsLoaded] = useState(false);

  useEffect(() => {
    loadCollections();
  }, []);

  const loadCollections = async () => {
    try {
      const res = await listCollections(user.token);
      setCollections(res.data.collections);
      console.log('collections--->', res.data.collections);
      setIsCollectionsLoaded(true);
    } catch (err) {
      console.error('Could not load collections in DASHBOARD PAGE', err);
    } finally {
      setLoading(false);
    }
  };

  const loadStats = async (id, collectionName) => {
    try {
      setLoading(true);
      setIsCollectionChoosen(true);
      setCurrentCollection(collectionName);

      const res = await getOrdersForStats({ collectionId: id }, user.token);

      setPMen(res.data.mendict);
      setPWomen(res.data.womendict);
      setPCapsulesMen(res.data.capsulesmendict);
      setPCapsulesWomen(res.data.capsuleswomendict);

      if (res.data.mendict && Object.keys(res.data.mendict).length > 0) {
        setCurrentTypeMen(Object.keys(res.data.mendict)[0]);
      } else {
        setCurrentTypeMen(null);
      }

      if (res.data.womendict && Object.keys(res.data.womendict).length > 0) {
        setCurrentTypeWomen(Object.keys(res.data.womendict)[0]);
      } else {
        setCurrentTypeWomen(null);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getTotalQuantity = (product) => {
    if (!product || !product.pidOrder) {
      return 0;
    }

    return product.pidOrder.reduce(
      (total, orderItem) => total + orderItem.qty,
      0
    );
  };

  const sortProductsByTotalQuantity = (menDict, calculateTotalAmount) => {
    return Object.entries(menDict)
      .sort(([typeNameA, typeDataA], [typeNameB, typeDataB]) => {
        const totalA = calculateTotalAmount(typeDataA.products);
        const totalB = calculateTotalAmount(typeDataB.products);
        return totalB - totalA;
      })
      .reduce((acc, [typeName, typeData]) => {
        acc[typeName] = typeData;
        return acc;
      }, {});
  };

  const handleLoadingData = (collectionId, collectionName) => {
    loadStats(collectionId, collectionName);
  };

  return (
    <AdminBodyStruct>
      <div className="dashboard-page-box">
        <HeaderDashboard
          userName={user.name || 'User'}
          text="This is your dashboard, providing you with a comprehensive overview of a specific collection at a glance."
        />

        <div className="dashboard-page-container-choose-collection">
          {isCollectionChoosen && !loading && (
            <h1>Showing statistics for: {currentCollection}</h1>
          )}
          <ChooseCollectionHeader
            isCollectionsLoaded={isCollectionsLoaded}
            collections={collections}
            handleLoadingData={handleLoadingData}
          />
        </div>

        {isCollectionChoosen && loading ? (
          <InfoLoaderAdmin />
        ) : isCollectionChoosen && !loading ? (
          <>
            <StatisticsDashboard
              gender="men"
              products={pMen}
              pCapsules={pCapsulesMen}
              currentType={currentTypeMen}
              setCurrentType={setCurrentTypeMen}
            />
            <StatisticsDashboard
              gender="women"
              products={pWomen}
              pCapsules={pCapsulesWomen}
              currentType={currentTypeWomen}
              setCurrentType={setCurrentTypeWomen}
            />
          </>
        ) : (
          ''
        )}
      </div>
    </AdminBodyStruct>
  );
}

export default DashboardPage;
