import AdminBodyStruct from './AdminBodyStruct';
import { listOrders } from './../../functions/orders';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { listCapsules } from '../../functions/capsules';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import {
  getOrderById,
  deleteOrderById,
  changeOrderStatus,
} from '../../functions/orders';
import Backdrop from '../../backdrop/Backdrop';
import OrderDetails from '../../components/UI/OrderDetails';
import OrderDetailsDrawer from '../../drawers/OrderDetailsDrawer';
import InfoLoaderAdmin from '../../components/loaders/InfoLoaderAdmin';
import * as XLSX from 'xlsx/xlsx.mjs';
import { homePageOrderTotal } from '../../functions/qty-check';
import { listCollections } from '../../functions/collections';
import InputBRK from '../../components/input/InputBRK';
import Modal from '../../modals/Modal';
import CreateOrderFromAdmin from '../../components/UI/admin/CreateOrderFromAdmin';

function OrdersPage() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  //state
  const [orders, setOrders] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [collectionId, setCollectionId] = useState('6526a9adad300220c9b74da1');
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  //one holds the "cart" form of the order, the other the details
  const [individualOrder, setIndividualOrder] = useState([]);
  const [individualOrderDetails, setIndividualOrderDetails] = useState([]);
  const [capsules, setCapsules] = useState([]);
  const [loading, setLoading] = useState(false);

  //TO HANDLE COLLECTIONS
  const [isCollectionChoosen, setIsCollectionChoosen] = useState(false);
  const [currentCollection, setCurrentCollection] = useState('');
  const [collections, setCollections] = useState([]);
  const [isCollectionsLoaded, setIsCollectionsLoaded] = useState(false);

  const [foundEmail, setFoundEmail] = useState('');

  const [isModalToCreateOrderOpen, setIsModalToCreateOrderOpen] =
    useState(false);

  //effect
  useEffect(() => {
    setLoading(true);
    loadOrdersDB();
    loadCollections();
  }, []);

  const loadCollections = async () => {
    try {
      const res = await listCollections(user.token);
      setCollections(res.data.collections);
      console.log('collections--->', res.data.collections);
      setIsCollectionsLoaded(true);
    } catch (err) {
      console.error('Could not load collections in DASHBOARD PAGE', err);
    } finally {
      //setLoading(false);
    }
  };

  const loadOrdersDB = () => {
    listOrders(user.token)
      .then((res) => {
        setOrders(res.data.orders);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        toast.error('Could not load orders');
        console.log(err);
      });
  };

  const closeOrderDetails = () => {
    setShowOrderDetails(false);
    setIndividualOrder([]);
    setIndividualOrderDetails();
  };

  const openOrderDetails = (orderId) => {
    //prepare the details
    let order = orders.find((o) => o._id === orderId);
    if (order) {
      setIndividualOrderDetails(order);
    } else {
      toast.error('Could not find the order!');
      return;
    }

    //prepare capsules
    listCapsules(user.token)
      .then((res) => {
        setCapsules(res.data.capsules);
      })
      .catch((err) => console.log(err));

    //prepare the products
    getOrderById(orderId, user.token)
      .then((res) => {
        setIndividualOrder(res.data.cart);
        setTimeout(() => setShowOrderDetails(true), 200);
      })
      .catch((err) => {
        toast.error('Could not load the order. Please refresh the page.');
      });
  };

  const downloadSheetDetails = (orderId) => {
    //prepare the products
    getOrderById(orderId, user.token)
      .then((res) => {
        //console.log('DATA --> ', res.data.cart);
        const transformedData = transformData(res.data.cart);

        if (transformedData) {
          let wb = XLSX.utils.book_new();
          let ws = XLSX.utils.json_to_sheet(transformedData);

          XLSX.utils.book_append_sheet(wb, ws, 'orderDetails');

          let workBookName = `order_details_to_bot.xlsx`;
          XLSX.writeFile(wb, workBookName);
        } else {
          console.log('Could not export excel sheet');
        }
      })
      .catch((err) => {
        toast.error('Could not load the order. Please refresh the page.');
      });
  };

  const deleteOrder = (orderId, orderEmail) => {
    // Ask for confirmation
    const isConfirmed = window.confirm(
      `WARNING : Are you sure you want to delete this order for ${orderEmail}?`
    );

    const userEmail = user.email;

    // If the user confirms, proceed with the delete operation
    if (isConfirmed) {
      //download order in sheets before deleting
      downloadSheetDetails(orderId);
      // Perform the delete operation (replace this with your actual logic)
      deleteOrderById(orderId, userEmail, user.token)
        .then((res) => {
          //console.log('DATA --> ', res.data.cart);
          if (res.data.message === 'Order deleted successfully') {
            toast.success('Order deleted successfully');
            loadOrdersDB();
          }

          if (res.data.message === 'No auth') {
            toast.error('You are not allowed to delete orders.');
            loadOrdersDB();
          }
        })
        .catch((err) => {
          toast.error('Could not load the order. Please refresh the page.');
        });

      // Reload the page or update the UI as needed
      // window.location.reload();
    } else {
      // User chose not to delete, you can handle this case if needed
      console.log('Delete operation canceled');
    }
  };

  const editOrder = (orderId) => {
    //
    getOrderById(orderId, user.token)
      .then((res) => {
        if (res.data.isOrderConfirmed) {
          toast.info('This order has already being confirmed.');
          // loadOrders();
          return;
        }
        //if order has not being confirmed yet
        navigate(`/order/${orderId}`);
      })
      .catch((err) => {
        toast.error('Could not load your orders. Please refresh the page.');
      });
  };

  const transformData = (cart) => {
    return cart.map((item) => {
      const { product, pidOrder } = item;

      // Generate the "Size-Pieces" string and calculate total pieces
      const sizePieces = pidOrder
        .map((sizeQty) => `${sizeQty.size}-${sizeQty.qty}`)
        .join(', ');
      const totalPieces = pidOrder.reduce(
        (total, sizeQty) => total + sizeQty.qty,
        0
      );

      // Create the transformed object
      return {
        Producto: `Style: ${product.name}, Color Ref:${product.color[0]}, Size-Pieces: ${sizePieces}`,
        Piezas: totalPieces,
        Precio: product.price,
      };
    });
  };

  const handleLoadingData = (collectionId, collectionName) => {
    //we have to load orders again as after filtering we cut down the orders
    listOrders(user.token)
      .then((res) => {
        // use the id to filter collections and only leave the ones we want
        console.log('collectionId*****', collectionId);
        const ordersForOneCollection = res.data.orders.filter(
          (order) => String(order.pxCollection) === String(collectionId)
        );

        setOrders(ordersForOneCollection);
        setCurrentCollection(collectionName);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        toast.error('Could not load orders');
        console.log(err);
      });
  };

  const checkCollectionName = (pxCollection) => {
    if (collections.length > 0) {
      const collection = collections.find(
        (c) => String(pxCollection) === String(c._id)
      );

      if (collection) {
        return collection.name;
      } else {
        return 'Collection name not found';
      }
    }
    return 'Collection name not found';
  };

  const changeDateFormat = (originalDate) => {
    // Parse the original date string
    const dateObj = new Date(originalDate);

    // Define an array of month names
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Get the month, day, and year from the date object
    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    // Construct the formatted date string
    const formattedDate = `${month} ${day < 10 ? '0' + day : day} ${year}`;
    return formattedDate;
  };

  const checkTotalNeto = () => {
    if (!Array.isArray(orders)) {
      throw new Error('Invalid input: orders should be an array');
    }

    return orders.reduce((totalNeto, order) => {
      return totalNeto + (order.orderSubTotal || 0);
    }, 0);
  };

  const handleFindEmailChange = (e) => {
    setFoundEmail(e.target.value);
  };

  // const searched = (keyword) => (c) =>
  //   c.email.toLowerCase().includes(keyword.toLowerCase());
  const searched = (keyword) => (c) =>
    c.email.toLowerCase().includes(keyword.toLowerCase());

  const handleOrderStatus = (orderId, action) => {
    const isConfirmed = window.confirm(
      'Do you want to change the status of this order?'
    );

    if (isConfirmed) {
      changeOrderStatus({ orderId, action }, user.token)
        .then((res) => {
          //console.log('DATA --> ', res.data.cart);
          if (res.data.message === 'Order status updated successfully') {
            toast.success('Order status updated successfully');
            loadOrdersDB();
          }

          // if (res.data.message === 'No auth') {
          //   toast.error('You are not allowed to delete orders.');
          //   loadOrdersDB();
          // }
        })
        .catch((err) => {
          toast.error('Could not load the order. Please refresh the page.');
        });
    } else {
      toast.info('Change confirmation operation canceled');
    }
  };

  const closeModalToCreateOrder = () => setIsModalToCreateOrderOpen(false);

  return (
    <AdminBodyStruct>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {showOrderDetails && <Backdrop onClick={closeOrderDetails} />}
      {individualOrderDetails && (
        <OrderDetailsDrawer show={showOrderDetails}>
          <OrderDetails
            order={individualOrder}
            orderDet={individualOrderDetails}
            closeOrderDetails={closeOrderDetails}
            capsules={capsules}
          />
        </OrderDetailsDrawer>
      )}

      {/* MODAL FOR CREATING ORFER FROM ZERO FOR CLIENT*/}
      <Modal
        show={isModalToCreateOrderOpen}
        onCancel={closeModalToCreateOrder}
        header={'CREATE ORDER'}
      >
        <CreateOrderFromAdmin />
      </Modal>
      {/* END OF MODAL FOR CREATING ORFER FROM ZERO FOR CLIENT*/}

      <div className="flex-container-collections">
        <div className="flex-container-title-selector mb-1">
          <h2>
            Orders{' '}
            <span>
              ({`${orders.length} orders`})
              {` ${checkTotalNeto().toFixed(2)} € (sin IVA)`}
            </span>
          </h2>
          {/* <InputBRK
            placeholder={'Find a product'}
            onChange={handleFindProductChange}
          /> */}
          {/* <SelectorBRK onSelectGender={changeCapsule} options={capsules} /> */}
          <div className="flex-container-collections-orders">
            {collections.length > 0 &&
              collections.map((collection) => (
                <p
                  key={collection._id}
                  onClick={() =>
                    handleLoadingData(collection._id, collection.name)
                  }
                  style={{
                    fontWeight:
                      collection.name === currentCollection ? 'bold' : 'normal',
                    textDecoration:
                      collection.name === currentCollection
                        ? 'underline'
                        : 'none',
                  }}
                >
                  {collection.name}
                </p>
              ))}
          </div>
        </div>
        <hr className="horizontal-line" />
        <div className="finder-box-order-page">
          <InputBRK placeholder={'Email'} onChange={handleFindEmailChange} />
          <p onClick={() => setIsModalToCreateOrderOpen(true)}>
            + Create Order
          </p>
        </div>
        <hr className="horizontal-line" />

        <div className="order-admin-container">
          {loading ? (
            <InfoLoaderAdmin />
          ) : (
            orders.length > 0 &&
            orders.filter(searched(foundEmail)).map((order) => {
              return (
                <div key={order._id}>
                  <div className="product-info-flex-container">
                    <div className="info-item">
                      <div className="name-price-box">
                        <h2>{`Order: #${order.orderReference.slice(-6)}`}</h2>
                        {order.discount && order.discount > 0 ? (
                          <h2>{`Total neto: ${homePageOrderTotal(
                            order
                          ).totalAmountDiscounted.toFixed(2)} €`}</h2>
                        ) : (
                          <h2>{`Total neto: ${homePageOrderTotal(
                            order
                          ).totalAmount.toFixed(2)} €`}</h2>
                        )}
                      </div>

                      {/* {order.confirmed ? (
                        <>
                          <h4>
                            Order confirmed!{' '}
                            <span
                              className="change-confirmed"
                              onClick={() =>
                                handleOrderStatus(
                                  order._id,
                                  'orderConfirmationFalse'
                                )
                              }
                            >
                              Change
                            </span>
                          </h4>
                        </>
                      ) : (
                        <h3>
                          Order in progress{' '}
                          <span
                            className="change-confirmed"
                            onClick={() =>
                              handleOrderStatus(
                                order._id,
                                'orderConfirmationTrue'
                              )
                            }
                          >
                            Change
                          </span>
                        </h3>
                      )} */}
                      {order.confirmed ? (
                        <p>
                          Order:{' '}
                          <span
                            className="order-status-true"
                            onClick={() =>
                              handleOrderStatus(
                                order._id,
                                'orderConfirmationFalse'
                              )
                            }
                          >
                            Confirmed
                          </span>
                        </p>
                      ) : (
                        <p>
                          Order:{' '}
                          <span
                            className="order-status-false"
                            onClick={() =>
                              handleOrderStatus(
                                order._id,
                                'orderConfirmationTrue'
                              )
                            }
                          >
                            In progress
                          </span>
                        </p>
                      )}
                      <p>{`Customer: ${order.email}`}</p>
                      <p>{`Order date: ${changeDateFormat(
                        order.updatedAt
                      )}`}</p>
                      <p>{`Collection: ${checkCollectionName(
                        order.pxCollection
                      )}`}</p>
                      <p>{`Logos: ${order.logoTotal} € (${
                        order.logoTotal / 3
                      } items)`}</p>

                      {order.confirmed && order.paid === true ? (
                        <p>
                          Payment status:{' '}
                          <span
                            className="order-status-true"
                            onClick={() =>
                              handleOrderStatus(order._id, 'orderPaymentFalse')
                            }
                          >
                            Paid
                          </span>
                        </p>
                      ) : order.confirmed && order.paid === false ? (
                        <p>
                          Payment status:{' '}
                          <span
                            className="order-status-false"
                            onClick={() =>
                              handleOrderStatus(order._id, 'orderPaymentTrue')
                            }
                          >
                            Not paid
                          </span>
                        </p>
                      ) : null}

                      {order.confirmed && order.delivered === true ? (
                        <p>
                          Shipping:{' '}
                          <span
                            className="order-status-true"
                            onClick={() =>
                              handleOrderStatus(order._id, 'orderShippingFalse')
                            }
                          >
                            Delivered
                          </span>
                        </p>
                      ) : order.confirmed && order.delivered === false ? (
                        <p>
                          Shipping:{' '}
                          <span
                            className="order-status-false"
                            onClick={() =>
                              handleOrderStatus(order._id, 'orderShippingTrue')
                            }
                          >
                            Not delivered
                          </span>
                        </p>
                      ) : null}
                      {/* <p>{`Payment status: ${order.paid}`}</p> */}

                      {order.discount && order.discount !== 0 ? (
                        <h3>{`${order.discount}% discount applied`}</h3>
                      ) : null}

                      <div className="edit-order-admin">
                        <p onClick={() => openOrderDetails(order._id)}>
                          Order details
                        </p>
                        <div className="sheet-delete-order-admin">
                          <p
                            className="edit-product"
                            onClick={() => downloadSheetDetails(order._id)}
                          >
                            Sheet
                          </p>

                          {/* Make that sandra can only delete her orders*/}
                          {user.email === 'sandra@brk.es' &&
                            order.email === 'sandra@brk.es' && (
                              <>
                                <span> / </span>
                                <p
                                  className="edit-product"
                                  onClick={() =>
                                    deleteOrder(order._id, order.email)
                                  }
                                >
                                  Delete
                                </p>
                              </>
                            )}

                          {/* Make that eduardo can delete any orders*/}
                          {user.email === 'eduardo@brk.es' && (
                            <>
                              <span> / </span>
                              <p
                                className="edit-product"
                                onClick={() =>
                                  deleteOrder(order._id, order.email)
                                }
                              >
                                Delete
                              </p>
                            </>
                          )}

                          <span> / </span>
                          <p
                            className="edit-product"
                            onClick={() => editOrder(order._id)}
                          >
                            Edit
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="horizontal-line" />
                </div>
              );
            })
          )}
        </div>
      </div>
    </AdminBodyStruct>
  );
}

export default OrdersPage;
