import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { listStock } from '../../functions/stock';
import { capFirstLetter } from '../../functions/cap-first-letter';
import InfoLoaderAdmin from '../loaders/InfoLoaderAdmin';
import { listCapsules } from '../../functions/capsules';
import ButtonInfo from '../buttons/ButtonInfo';

const initialStateCapsules = [{ _id: '', name: '' }];

function StockClientPage(props) {
  const user = useSelector((state) => state.user);

  // States
  const [loading, setLoading] = useState(true);
  const [stockList, setStockList] = useState([]);
  const [capsules, setCapsules] = useState(initialStateCapsules);

  useEffect(() => {
    loadCapsules();
    loadStock();
  }, []);

  const loadCapsules = () => {
    listCapsules(user.token)
      .then((res) => {
        setCapsules(res.data.capsules);
      })
      .catch((err) => console.log(err));
  };

  const loadStock = async () => {
    try {
      const res = await listStock(user.token);
      setStockList(res.data.stock);
      //console.log('STOCK--', res.data.stock);
      setLoading(false);
    } catch (err) {
      console.error('Error loading stock', err);
      setLoading(false);
    }
  };

  const sortedProductsByCapsules = (products, capsules) => {
    const productsByCapsules = {};

    products.forEach((product) => {
      const capsuleId = product.pxProduct.pxCapsule;
      const capsule = capsules.find((c) => String(c._id) === String(capsuleId));

      if (capsule) {
        const capsuleName = capsule.name;
        if (!productsByCapsules[capsuleName]) {
          productsByCapsules[capsuleName] = [];
        }
        productsByCapsules[capsuleName].push(product);
      }
    });

    return productsByCapsules;
  };

  if (loading) {
    return (
      <div className="available-stock-client-page">
        <InfoLoaderAdmin />
      </div>
    );
  }

  const showStockList = (productId) => {
    let product = stockList.find((p) => p.pxProduct._id === productId);

    if (product && product.stockAvailable) {
      const sizeOrder = [
        'XXS',
        'XS',
        'S',
        'M',
        'L',
        'XL',
        'XXL',
        '32',
        '34',
        '36',
        '38',
        '40',
        '42',
        '44',
        '46',
        '48',
        '50',
        '52',
        '54',
        '56',
        '58',
        '60',
      ];

      product.stockAvailable.sort((a, b) => {
        return sizeOrder.indexOf(a.size) - sizeOrder.indexOf(b.size);
      });

      return (
        <div className="stock-available-box">
          <h3>
            Stock:{' '}
            {product.stockAvailable.map((stock, index) => (
              <span key={index}>
                {stock.size}:{stock.qty}
                {index < product.stockAvailable.length - 1 ? ' / ' : ''}
              </span>
            ))}
          </h3>
        </div>
      );
    }

    return null;
  };

  const createNewStockOrder = () => {
    props.handleStockOrderClick();
  };

  const productsByCapsules = sortedProductsByCapsules(stockList, capsules);

  return (
    <div className="flex-container-collection-products available-stock">
      <div className="header-stock-available">
        <h2>Products in stock are ready for immediate delivery.</h2>
        <p onClick={() => props.closeStockModal()}>Go back</p>

        <ButtonInfo
          name={'+ Create stock order'}
          classes={'create-order-btn floating-btn'}
          onClick={() => createNewStockOrder()}
        />
      </div>

      {Object.keys(productsByCapsules).map((capsuleName) => (
        <div key={capsuleName}>
          <h2 className="capsule-title-stock">{capsuleName}</h2>
          {productsByCapsules[capsuleName].map((product) => (
            <div key={product.pxProduct._id}>
              <hr className="horizontal-line available-stock-hr"></hr>
              <div className="product-info-flex-container ">
                <div className="info-box-flex-container">
                  {product.pxProduct.image && product.pxProduct.image.url && (
                    <img
                      src={product.pxProduct.image.url}
                      alt="product-img"
                      className="info-img"
                    />
                  )}
                  <div className="info-item">
                    <div className="name-price-box">
                      <h2>{capFirstLetter(product.pxProduct.name)}</h2>
                      <div className="price-item">
                        <h2>{product.pxProduct.price}€</h2>
                        <p>/ SRP: {product.pxProduct.srp}€</p>
                      </div>
                    </div>
                    <p>{`Ref. ${product.pxProduct.reference}`}</p>
                    <p>
                      {product.pxProduct.color} / {product.pxProduct.fabric}
                    </p>
                    <p>{product.pxProduct.composition}</p>
                    <p>{product.pxProduct.description}</p>
                  </div>
                </div>
              </div>
              {showStockList(product.pxProduct._id)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default StockClientPage;
