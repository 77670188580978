import axios from 'axios';

export const listCollections = async (authtoken) =>
  await axios.get('/api/collections', {
    headers: {
      authtoken,
    },
  });

export const listCollectionsPricing = async (authtoken) =>
  await axios.get('/api/collections/papp', {
    headers: {
      authtoken,
    },
  });

export const createCollection = async (newCollection, authtoken) =>
  await axios.post(`/api/collections`, newCollection, {
    headers: {
      authtoken,
    },
  });
