import React from 'react';

function ProductsCatalogue(props) {
  return (
    <div className="wrapper-cataloue-box">
      <div className="catalogue-box">
        {props.capsules[props.currentCapsule].products.map((product) => (
          <div className="" key={product._id}>
            {product.image.url && (
              <img
                onClick={() => props.handleImage(product)}
                src={`${product.image.url}`}
                alt="product-img"
                className="product-catalogue-img"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductsCatalogue;
