import React, { useState, useEffect } from 'react';
import { capFirstLetter } from '../../../functions/cap-first-letter';
import { checkSizes } from '../../../functions/check-sizes';
import ButtonChangeQty from '../../../components/buttons/ButtonChangeQty';
import { getProductById } from '../../../functions/products';
import { useSelector } from 'react-redux';
import ButtonChangeQtyStock from '../../buttons/ButtonChangeQtyStock';
import { listStock, updateStock } from '../../../functions/stock';
import ButtonBRK from '../../buttons/ButtonBRK';

const initialStateProduct = {
  //pxCapsule and reference should not change
  reference: '',
  name: '',
  type: '',
  composition: '',
  description: '',
  price: '',
  srp: '',
  color: '',
  fabric: '',
  tech: [],
  type: '',
  available: true,
  image: {},
};

function AddStock(props) {
  const user = useSelector((state) => state.user);
  const [image, setImage] = useState([]);
  //States
  const [product, setProduct] = useState(initialStateProduct);
  const [stockAvailable, setStockAvailable] = useState([]);
  console.log('IN ADD STOCK');

  //useffect
  useEffect(() => {
    console.log('props.CAPSULES', props.capsules);
    loadProduct();
  }, []);

  //functions
  const loadProduct = () => {
    //console.log('props.addProductStockId', props.addProductStockId);
    getProductById(props.addProductStockId, user.token)
      .then((res) => {
        console.log('getProductById PRODUCT***', product);
        setProduct({
          id: res.data.product._id,
          reference: res.data.product.reference,
          type: res.data.product.type,
          name: res.data.product.name,
          composition: res.data.product.composition,
          description: res.data.product.description,
          price: res.data.product.price,
          srp: res.data.product.srp,
          fabric: res.data.product.fabric,
          tech: res.data.product.tech,
          color: res.data.product.color,
          pxCapsule: res.data.product.pxCapsule,
          image: {
            public_id: res.data.product.image.public_id
              ? res.data.product.image.public_id
              : undefined,
            url: res.data.product.image.url
              ? res.data.product.image.url
              : undefined,
          },
        });
        //console.log('PRODUCT FROM DB TO UPDATE->', res.data.product);
        setImage({
          public_id: res.data.product.image.public_id,
          url: res.data.product.image.url,
        });

        //we list and try to find product within list
        loadStock();
      })
      .catch((err) => console.log(err));
  };

  const loadStock = () => {
    //1.- List stock and depending on
    listStock(user.token)
      .then((res) => {
        console.log('res.data.stock', res.data.stock);

        //try to fond if product already exist in stock list
        let product = res.data.stock.find(
          (p) => String(props.addProductStockId) === String(p.pxProduct._id)
        );

        if (!product) {
          console.log('PRODUCT NOT FOUND IN STOCK');
        } else {
          console.log('FOUND STOCK res.data.stock', product);
          setStockAvailable(product.stockAvailable);
        }
      })
      .catch((err) => console.log(err));
  };

  const addQty = (size) => {
    console.log('in add qty size', size);

    // Check if size already added
    let updatedStock = [...stockAvailable];
    let sizeIndex = updatedStock.findIndex((s) => s.size === size);

    if (sizeIndex !== -1) {
      // If size already exists
      updatedStock[sizeIndex].qty += 1;
    } else {
      updatedStock.push({ size, qty: 1 });
    }

    setStockAvailable(updatedStock);
  };

  const reduceQty = (size) => {
    console.log('in reduce qty size', size);

    // Check if size already added
    let updatedStock = [...stockAvailable];
    let sizeIndex = updatedStock.findIndex((s) => s.size === size);

    if (sizeIndex !== -1) {
      // If size exists and quantity is greater than 1
      if (updatedStock[sizeIndex].qty > 1) {
        updatedStock[sizeIndex].qty -= 1;
      } else {
        updatedStock.splice(sizeIndex, 1); // Remove the size entry if qty is 1
      }

      setStockAvailable(updatedStock);
    }
  };

  //console.log('STOCK AVAILABLE', stockAvailable);

  const updateStockAvailable = () => {
    //update stock
    updateStock({ stockAvailable, productId: product.id }, user.token)
      .then((res) => {
        props.loadStockList(); // Call parent function to update the stock list
      })
      .catch((err) => {
        console.log(err);
      });

    setStockAvailable();
    props.closeAddProductModal();
  };

  return (
    <div className="add-stock-box">
      <div className="product-info-flex-container">
        <div className="info-box-flex-container">
          {product.image.url ? (
            <img
              // onClick={() => handleImage(product)}
              // src={`/images/${product.name}.svg`}

              src={`${product.image.url}`}
              alt="product-img"
              className="info-img"
            />
          ) : (
            <img
              // onClick={() => handleImage(product)}
              // src={`/images/${product.name}.svg`}

              src="/images/not-found-image.png"
              alt="product-img"
              className="info-img"
            />
          )}

          <div className="info-item">
            <div className="name-price-box">
              <h2>{`${capFirstLetter(product.name)}`}</h2>
              <div className="price-item">
                <h2>{product.price}€</h2>
                <p>/ SRP: {product.srp}€</p>
              </div>
            </div>
            <p>{`Ref. ${product.reference}`}</p>
            <p>{product.color}</p>
            <p>{product.composition}</p>
            <p>{product.description}</p>
          </div>
        </div>
      </div>
      <div className="size-box-flex-container">
        {product &&
          checkSizes(product, props.capsules).map((item, index) => {
            return (
              <ButtonChangeQtyStock
                key={index}
                size={item}
                addQty={() => addQty(item)}
                reduceQty={() => reduceQty(item)}
                stockAvailable={stockAvailable}
                pid={product._id}
                // onClick={() => addQtyHandler(product._id, item)}
              />
            );
          })}
      </div>

      <ButtonBRK
        name="Update Stock"
        classes={'btn-accept-updated-product'}
        onClick={updateStockAvailable}
      />

      {/* <hr className="horizontal-line"></hr> */}
    </div>
  );
}

export default AddStock;
