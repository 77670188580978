import { useNavigate } from 'react-router-dom';
//https://bereallykeen.com/
//https://www.instagram.com/bereallykeen/
function Footer() {
  const navigate = useNavigate();
  function openNewWindow(url) {
    window.open(url, '_blank');
  }
  return (
    <footer className="footer-box">
      <div className="flex-continer-footer">
        <div className="footer-contact">
          <img
            className="logo logo-footer"
            src="/images/bereallykeen_white.svg"
            // onClick={props.goHomeHandleClick}
          />
          <div
            className="follow-us"
            onClick={() => openNewWindow('https://bereallykeen.com/')}
          >
            <h3>Visit our official online store!</h3>
            <img
              src="/images/white-arrow.svg"
              className="arrow-exit-white-reverse"
              alt="arrow"
            />
          </div>
          <p style={{ fontStyle: 'italic' }}>Contact us at sandra@brk.es</p>
        </div>
        <div className="footer-info">
          <div
            className="follow-us"
            onClick={() =>
              openNewWindow('https://www.instagram.com/bereallykeen/')
            }
          >
            <img src="/images/instagram.svg" className="instagram-icon" />
            <p>Follow us on Instagram!</p>
          </div>
          <div
            className="follow-us"
            onClick={() => navigate('/terms-and-conditions')}
          >
            <h3>Terms and Conditions </h3>
            <img
              src="/images/white-arrow.svg"
              className="arrow-exit-white-reverse"
              alt="arrow"
            />
          </div>

          <h3 style={{ fontStyle: 'italic' }}>
            No transactions are processed on this platform.
          </h3>
        </div>
      </div>

      <div className="flex-container-copyright">
        <p>Copyright © 2023 BE REALLY KEEN</p>
      </div>
    </footer>
  );
}

export default Footer;
