import React from 'react';
import { CSSTransition } from 'react-transition-group';

const ProductsDrawer = (props) => {
  return (
    <CSSTransition
      in={props.show}
      timeout={200}
      classNames="slide-in-left"
      mountOnEnter
      unmountOnExit
    >
      <aside className="product-capsule-side-drawer">{props.children}</aside>
    </CSSTransition>
  );
};

export default ProductsDrawer;
