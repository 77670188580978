import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const LoadingToRedirect = (props) => {
  const [count, setCount] = useState(5);
  let navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount);
    }, 500);
    // redirect once count is equal to 0
    count === 0 && navigate('/login');
    // cleanup
    return () => clearInterval(interval);
  }, [count, navigate]);

  return (
    <div className="redirecting">
      {/* <p>{count}</p> */}
      {/* <img src="images/logo.svg" alt="BRK" width="80px" height="35px" /> */}
      {props.msgRefreshPage ? (
        <div className="login-to-redirect-error">
          <h2>Please refresh the page.</h2>
          <p>
            If the error persists, log out and sign in again with your
            credentials.
          </p>
        </div>
      ) : (
        <h2>Redirecting you in {count} seconds</h2>
      )}

      {/* <Spinner animation="border" /> */}
    </div>
  );
};

export default LoadingToRedirect;
