import React from 'react';

function SectionBlock(props) {
  return (
    <div className="flex-container-home-page-block-2">
      <div className="flex-container-home-page-highlights">
        <div className="flex-container-title-detail">
          <div>
            <p>{props.subtitle}</p>
          </div>

          <div className="thin-rectangle"></div>
        </div>

        <h1>{props.title}</h1>
      </div>
      <div className="flex-container-image-description-block">
        <img src={`/images/${props.imageName}`} className="img-home-page" />
        <div className="flex-container-description">
          <div className="thin-rectangle"></div>
          <h2>{props.header}</h2>
          <p>{props.body}</p>
        </div>
      </div>
      <div className="flex-container-highlights-description-block">
        {props.keyPoints.map((kp, index) => {
          {
            /* return index % 2 === 0 ? ( */
          }
          return true ? (
            <div className="flex-container-individual-highlight" key={index}>
              <h2>{kp.title}</h2>
              <p>{kp.body}</p>
            </div>
          ) : (
            <div
              className="flex-container-individual-highlight-reverse"
              key={index}
            >
              <p>{kp.body}</p>
              <h2>{kp.title}</h2>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SectionBlock;
