function InputBRK(props) {
  return (
    <input
      className={['input-log-in-username', props.classes].join(' ')}
      type="text"
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      name={props.name}
    ></input>
  );
}

export default InputBRK;
