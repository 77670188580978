import axios from 'axios';

//WE HAVE TO ADD AUTH TO EVERYTHING!!!!

// export const createOrder = async (collectionId, authtoken) =>
//   await axios.post(`/api/orders`, collectionId, {
//     headers: {
//       authtoken,
//     },
//   });

// export const createOrderFromAdmin = async (data, authtoken) =>
//   await axios.post(`/api/orders/orderFromAdmin`, data, {
//     headers: {
//       authtoken,
//     },
//   });

// export const getOrderById = async (orderId, authtoken) =>
//   await axios.get(`/api/orders/${orderId}`, {
//     headers: {
//       authtoken,
//     },
//   });

export const deleteStockById = async (stockId, userEmail, authtoken) =>
  await axios.delete(`/api/stock/${stockId}/${userEmail}`, {
    headers: {
      authtoken,
    },
  });

// export const updateOrderById = async (orderId, order, authtoken) =>
//   await axios.post(`/api/orders/${orderId}`, order, {
//     headers: {
//       authtoken,
//     },
//   });

export const listStock = async (authtoken) =>
  await axios.get(`/api/stock`, {
    headers: {
      authtoken,
    },
  });

export const listStockForOrderPage = async (authtoken) =>
  await axios.get(`/api/stock/orderPage`, {
    headers: {
      authtoken,
    },
  });

// export const getOrdersClient = async (clientId, authtoken) =>
//   await axios.get(`/api/orders/order/client/${clientId}`, {
//     headers: {
//       authtoken,
//     },
//   });

// export const confirmOrderById = async (orderId, authtoken) =>
//   await axios.post(`/api/orders/order/order-confirm`, orderId, {
//     headers: {
//       authtoken,
//     },
//   });

//UPDATE PASSING COLLECTION ID!!!!!
export const updateStock = async (data, authtoken) =>
  await axios.post(`/api/stock/updateStock`, data, {
    headers: {
      authtoken,
    },
  });

// export const getIndividualOrdersForStats = async (authtoken) =>
//   await axios.get(`/api/orders/order/individuals/stats`, {
//     headers: {
//       authtoken,
//     },
//   });

// // export const changeOrderConfirmation = async (orderId, authtoken) =>
// //   await axios.get(`/api/orders/changeConfirmation/${orderId}`, {
// //     headers: {
// //       authtoken,
// //     },
// //   });

// //data => orderId, action
// export const changeOrderStatus = async (data, authtoken) =>
//   await axios.post(`/api/orders/changeOrderStatus`, data, {
//     headers: {
//       authtoken,
//     },
//   });
