import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';

function ClientMobileMenuDrawer(props) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const logOutHandler = () => {
    //logout from firebase
    getAuth().signOut();

    //remove from redux
    dispatch({
      type: 'LOGOUT',
      payload: null,
    });

    //push to another page
    navigate('/login');
  };

  //const adminHandler = () => navigate('/dashboard-page');
  const adminHandler = () => {
    if (user.name !== 'Gabriela') {
      navigate('/orders-page');
    } else {
      navigate('/products-page');
    }
  };

  return (
    <CSSTransition
      in={props.show}
      timeout={200}
      classNames="slide-in-right"
      mountOnEnter
      unmountOnExit
    >
      <aside className="mobile-side-drawer-right">
        <div className="flex-container-mobile-menu">
          {/* <h2>Orders</h2>
          <h2>Download brochure</h2> */}
          {/* <h2>AW24</h2> */}
          <h2 onClick={() => navigate('/product-page/men')}>Men</h2>
          <h2 onClick={() => navigate('/product-page/women')}>Women</h2>
          <h2 onClick={() => navigate('/custom-design')}>Custom Design</h2>
          <h2 onClick={() => navigate('/tech')}>Tech +</h2>
          <h2 onClick={() => navigate('/client-home-page')}>My Orders</h2>

          {user.role === 'admin' && (
            <h2 style={{ fontStyle: 'italic' }} onClick={adminHandler}>
              Admin
            </h2>
          )}
          <hr className="horizontal-line hl-m-2" />
          <div className="bottom-menu-box">
            <div className="bottom-menu">
              <img
                alt="img"
                src="/images/user-bold.svg"
                className="img-mobile-menu"
              />
              {user && <p>{user.name}</p>}
            </div>
            <div className="bottom-menu" onClick={logOutHandler}>
              <img
                alt="img"
                src="/images/logout.svg"
                className="img-mobile-menu"
              />
              <p>Log out</p>
            </div>
          </div>

          {/* <div className="box-img-nav">
            <img alt="img" src="/images/nav-img.jpg" className="img-nav" />
          </div> */}
        </div>
      </aside>
    </CSSTransition>
  );
}

export default ClientMobileMenuDrawer;
