import React, { useState, useEffect } from 'react';
import ButtonBRK from '../../buttons/ButtonBRK';
import InputBRK from '../../input/InputBRK';
import { updateProduct, getProductById } from '../../../functions/products';
import { updateProductionValidation } from '../../../validation/products-validation';
import FileUpload from './FileUpload';
import { useSelector } from 'react-redux';

const initialStateProduct = {
  //pxCapsule and reference should not change
  reference: '',
  name: '',
  type: '',
  composition: '',
  description: '',
  price: '',
  srp: '',
  color: '',
  fabric: '',
  tech: [],
  type: '',
  available: true,
  image: {},
};

const TechSelector = ({ onSelect }) => {
  const techOptions = [
    'Breathable',
    'UV-protection',
    '4-way stretch',
    'Perfect fit',
    'Quick drying',
    'Recycled yarn',
    'Soft',
    'Antibacterial',
    'Feather weight',
    'Thermal',
    'Fresh control',
    'Waterproof',
    'Windproof',
  ];

  return (
    <div>
      <select
        className="edit-product-tech-selector"
        onChange={(e) => onSelect(e.target.value)}
      >
        <option value="" disabled selected>
          Add tech
        </option>
        {techOptions.map((tech, index) => (
          <option key={index} value={tech}>
            {tech}
          </option>
        ))}
      </select>
    </div>
  );
};

const TypeSelector = ({ onSelect }) => {
  const garmentTypes = [
    'Polo',
    'Polo manga larga',
    'Pantalón largo',
    'Pantalón corto',
    'Mid-layer',
    'Mid-layer sin manga',
    'Jersey',
    'Falda',
    'Vestido',
    'Cortavientos',
    'Abrigo',
    'Chaqueta',
    'Camiseta',
    'Sudadera',
    'Calcetín corto',
    'Calcetín largo',
    'Manguito',
    'Gorra',
    'Cinturón',
    'Chaqueta acolchada',
    'Chaleco',
    'Chaleco reversible cortaviento',
    'Chaqueta reversible cortaviento',
  ];

  return (
    <div>
      <select
        className="edit-product-tech-selector"
        onChange={(e) => onSelect(e.target.value)}
      >
        <option value="" disabled selected>
          Change type
        </option>
        {garmentTypes.map((type, index) => (
          <option key={index} value={type}>
            {type}
          </option>
        ))}
      </select>
    </div>
  );
};

function EditProduct(props) {
  const user = useSelector((state) => state.user);
  //state
  const [updatedProduct, setUpdatedProduct] = useState(initialStateProduct);
  const [image, setImage] = useState([]);

  // console.log('****image', image);
  // console.log('****updatedProduct', updatedProduct);

  //setEditProductId('652a3dae0a6876a0a60c72c9');

  //useffect
  useEffect(() => {
    loadProduct();
  }, []);

  //functions
  const loadProduct = () => {
    //console.log('editProductId', editProductId);
    getProductById(props.editProductId, user.token)
      .then((res) => {
        setUpdatedProduct({
          reference: res.data.product.reference,
          type: res.data.product.type,
          name: res.data.product.name,
          composition: res.data.product.composition,
          description: res.data.product.description,
          price: res.data.product.price,
          srp: res.data.product.srp,
          fabric: res.data.product.fabric,
          tech: res.data.product.tech,
          color: res.data.product.color,
          image: {
            public_id: res.data.product.image.public_id
              ? res.data.product.image.public_id
              : undefined,
            url: res.data.product.image.url
              ? res.data.product.image.url
              : undefined,
          },
        });
        //console.log('PRODUCT FROM DB TO UPDATE->', res.data.product);
        setImage({
          public_id: res.data.product.image.public_id,
          url: res.data.product.image.url,
        });
      })
      .catch((err) => console.log(err));
  };

  const updateProductDB = () => {
    //validation before updating
    const isValidated = updateProductionValidation(updatedProduct);

    if (isValidated) {
      updateProduct(updatedProduct, props.editProductId, user.token)
        .then((res) => {
          // console.log('UPDATED PRODUCT->', res.data.product);
          // console.log('UPDATED PRODUCT MSG->', res.data.message);
          props.closeEditProductModal();
          props.loadProducts();
        })
        .catch((err) => console.log(err));
    }
  };

  const changeProductHandler = (event) => {
    const updatedName = event.target.name;
    let updatedValue = event.target.value;

    // if (updatedName === 'tech') {
    //   //first find if tech already included
    //   let tech = updateProduct.find((t) => t.name === updatedValue);

    //   if (tech) {
    //     //already in group
    //     return;
    //   } else {
    //     // Add the new tech to the list
    //     const updatedTech = [...updatedProduct.tech, updatedValue];
    //     setUpdatedProduct({ ...updatedProduct, tech: updatedTech });
    //   }
    // }

    // if (updatedName === 'price' || updatedName === 'srp') {
    //   updatedValue = parseFloat(updatedValue);
    // }

    setUpdatedProduct({ ...updatedProduct, [updatedName]: updatedValue });
  };

  const deleteTechHandler = (deletedTech) => {
    const updatedTech = updatedProduct.tech.filter(
      (tech) => tech !== deletedTech
    );
    setUpdatedProduct({ ...updatedProduct, tech: updatedTech });
  };

  //console.log('STATE', updatedProduct);

  const addImageHandler = (image) => {
    //console.log('***res data  --->', image);
    setImage(image);

    setUpdatedProduct({
      ...updatedProduct,
      image: { public_id: image.public_id, url: image.url },
    });
  };

  const removeImageHandler = () => {
    setImage({ public_id: undefined, url: undefined });

    setUpdatedProduct({
      ...updatedProduct,
      image: {},
    });
  };

  const selectTechHandler = (selectedTech) => {
    if (selectedTech && !updatedProduct.tech.includes(selectedTech)) {
      const updatedTech = [...updatedProduct.tech, selectedTech];
      setUpdatedProduct({ ...updatedProduct, tech: updatedTech });
    }
  };

  const selectTypeHandler = (selectedType) => {
    if (selectedType) {
      setUpdatedProduct({ ...updatedProduct, type: selectedType });
    }
  };

  return (
    <div className="flex-container-edit-product">
      <div className="flex-container-edit-product-input">
        {/* <img
          alt="Image"
          src={`/images/${updatedProduct.name}.svg`}
          style={{ width: '100%' }}
        /> */}
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Reference</h2>
        <InputBRK
          placeholder={'Reference'}
          classes={'edit-product-input'}
          value={updatedProduct.reference}
          name={'reference'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Type</h2>
        <div className="tech-group-edit">
          <div className="tech-item">
            <p>{updatedProduct.type}</p>
          </div>
        </div>
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Choose a new type</h2>
        <TypeSelector onSelect={selectTypeHandler} />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Name</h2>
        <InputBRK
          placeholder={'Name'}
          classes={'edit-product-input'}
          value={updatedProduct.name}
          name={'name'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Composition</h2>
        <InputBRK
          placeholder={'Composition'}
          classes={'edit-product-input'}
          value={updatedProduct.composition}
          name={'composition'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Description</h2>
        <InputBRK
          placeholder={'Description'}
          classes={'edit-product-input'}
          value={updatedProduct.description}
          name={'description'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Color</h2>
        <InputBRK
          placeholder={'Color'}
          classes={'edit-product-input'}
          value={updatedProduct.color}
          name={'color'}
          onChange={changeProductHandler}
        />
      </div>

      <div className="flex-container-edit-product-input">
        <h2>Fabric</h2>
        <InputBRK
          placeholder={'Fabric'}
          classes={'edit-product-input'}
          value={updatedProduct.fabric}
          name={'fabric'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Price (€)</h2>
        <InputBRK
          placeholder={'Price (€)'}
          classes={'edit-product-input'}
          value={updatedProduct.price}
          name={'price'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>SRP Price (€)</h2>
        <InputBRK
          placeholder={'SRP Price (€)'}
          classes={'edit-product-input'}
          value={updatedProduct.srp}
          name={'srp'}
          onChange={changeProductHandler}
        />
      </div>

      <div className="flex-container-edit-product-input">
        <h2>Tech</h2>
        <div className="tech-group-edit">
          {updatedProduct.tech.map((t, index) => (
            <div key={index} className="tech-item">
              <p>{t}</p>
              <button
                className="delete-tech-button"
                onClick={() => deleteTechHandler(t)}
              >
                &#10006;
              </button>
            </div>
          ))}
          {updatedProduct.tech.length === 0 && (
            <h3>No techs found for this product.</h3>
          )}
          {/* Add Tech Selector */}
        </div>
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Add new techs</h2>
        <TechSelector onSelect={selectTechHandler} />
      </div>

      <div className="flex-container-edit-product-input">
        <h2>Image</h2>
        <FileUpload
          image={image}
          addImageHandler={addImageHandler}
          removeImageHandler={removeImageHandler}
        />
      </div>

      <ButtonBRK
        name="Save product"
        classes={'btn-accept-updated-product'}
        onClick={updateProductDB}
      />
    </div>
  );
}

export default EditProduct;
