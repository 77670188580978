import React from 'react';

function OfficialSuppliers() {
  const handleClickGoToArticleSolheim = () => {
    openNewWindow(
      'https://golfindustria.es/brk-be-really-keen-dara-a-conocer-en-finca-cortesin-su-coleccion-especial-solheim-cup-2023-durante-la-semana-del-evento/'
    ); // Replace with your desired URL
  };

  const handleClickGoToArticleTheOpen = () => {
    openNewWindow(
      'https://golfindustria.es/brk-be-really-keen-repite-como-colaborador-oficial-de-the-open-con-colecciones-de-ropa-deportiva-disenadas-y-fabricadas-en-espana/#'
    ); // Replace with your desired URL
  };

  function openNewWindow(url) {
    window.open(url, '_blank');
  }

  return (
    <React.Fragment>
      <div className="flex-container-official-supplier">
        <div>
          <h1>The Open</h1>
          <h2>Proud official supplier for the British Open</h2>
          <h3>St. Andrews 150th & Royal Liverpool 151th</h3>
          <div className="flex-container-read-article">
            <h2 onClick={handleClickGoToArticleTheOpen}>Read the article</h2>
            <img
              src="/images/exit-arrow.svg"
              className="arrow-exit-white-reverse"
              alt="arrow"
              onClick={handleClickGoToArticleTheOpen}
            />
          </div>
          {/* <ButtonInfo
            name="Read article"
            onClick={handleClickGoToArticleTheOpen}
          /> */}
        </div>
        {/* <div className="flex-container-image"> */}
        <img
          src="/images/the_open.svg"
          alt="img-the-open"
          className="img-the-open"
        />
        {/* </div> */}
      </div>
      <div className="flex-container-official-supplier solheim-reverse">
        <img
          src="/images/solheim-logo.svg"
          alt="img-the-open"
          className="img-the-solheim"
        />
        <div>
          <h1>The Solheim Cup</h1>
          <h2>Proud official supplier for the Solheim Cup</h2>
          <h3>Finca Cortesín 2023</h3>
          <div className="flex-container-read-article">
            <h2 onClick={handleClickGoToArticleSolheim}>Read the article</h2>
            <img
              src="/images/exit-arrow.svg"
              className="arrow-exit-white-reverse"
              alt="arrow"
              onClick={handleClickGoToArticleSolheim}
            />
          </div>
          {/* <ButtonInfo
            name="Read article"
            onClick={handleClickGoToArticleSolheim}
          /> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default OfficialSuppliers;
