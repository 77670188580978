import axios from 'axios';

export const listCapsules = async (authtoken) =>
  await axios.get(`/api/capsules`, {
    headers: {
      authtoken,
    },
  });

export const createCapsule = async (capsule, authtoken) =>
  await axios.post(`/api/capsules`, capsule, {
    headers: {
      authtoken,
    },
  });

export const updateCapsuleAvailability = async (
  capsuleId,
  isAvailable,
  authtoken
) =>
  await axios.post(
    `/api/capsules/${capsuleId}`,
    { availability: isAvailable },
    {
      headers: {
        authtoken,
      },
    }
  );
