import React, { useState } from 'react';
import ButtonBRK from '../buttons/ButtonBRK';
import InputBRK from '../input/InputBRK';

import { updateProductionValidation } from '../../validation/products-validation';
import FileUpload from './admin/FileUpload';
import { useSelector } from 'react-redux';

const initialStateProduct = {
  //pxCapsule and reference should not change
  name: '',
  reference: '',
  type: '',
  composition: '',
  description: '',
  price: '',
  srp: '',
  color: '',
  fabric: '',
  tech: [],
  available: true,
  image: {},
};

const TechSelector = ({ onSelect }) => {
  const techOptions = [
    'Breathable',
    'UV-protection',
    '4-way stretch',
    'Perfect fit',
    'Quick drying',
    'Recycled yarn',
    'Soft',
    'Antibacterial',
    'Feather weight',
    'Thermal',
    'Fresh control',
    'Waterproof',
    'Windproof',
  ];

  return (
    <div>
      <select
        className="edit-product-tech-selector"
        onChange={(e) => onSelect(e.target.value)}
      >
        <option value="" disabled selected>
          Add tech
        </option>
        {techOptions.map((tech, index) => (
          <option key={index} value={tech}>
            {tech}
          </option>
        ))}
      </select>
    </div>
  );
};

const TypeSelector = ({ onSelect }) => {
  const garmentTypes = [
    'Polo',
    'Polo manga larga',
    'Pantalón largo',
    'Pantalón corto',
    'Mid-layer',
    'Mid-layer sin manga',
    'Jersey',
    'Falda',
    'Vestido',
    'Cortavientos',
    'Abrigo',
    'Chaqueta',
    'Camiseta',
    'Sudadera',
    'Calcetín corto',
    'Calcetín largo',
    'Manguito',
    'Gorra',
    'Cinturón',
    'Chaqueta acolchada',
    'Chaleco',
    'Chaleco reversible cortaviento',
    'Chaqueta reversible cortaviento',
  ];

  return (
    <div>
      <select
        className="edit-product-tech-selector"
        onChange={(e) => onSelect(e.target.value)}
      >
        <option value="" disabled selected>
          Change type
        </option>
        {garmentTypes.map((type, index) => (
          <option key={index} value={type}>
            {type}
          </option>
        ))}
      </select>
    </div>
  );
};

function AddProduct(props) {
  const user = useSelector((state) => state.user);
  //state
  const [newProduct, setNewProduct] = useState(initialStateProduct);
  const [image, setImage] = useState([]);
  const [techList, setTechList] = useState([]);

  const changeProductHandler = (event) => {
    const updatedName = event.target.name;
    let updatedValue = event.target.value;

    setNewProduct({ ...newProduct, [updatedName]: updatedValue });
  };

  const addImageHandler = (image) => {
    //console.log('***res data  --->', image);
    setImage(image);

    setNewProduct({
      ...newProduct,
      image: { public_id: image.public_id, url: image.url },
    });
  };

  const removeImageHandler = () => {
    setImage({ public_id: undefined, url: undefined });

    setNewProduct({
      ...newProduct,
      image: {},
    });
  };

  console.log('NEW PRODUCT--->', newProduct);

  const selectTechHandler = (selectedTech) => {
    if (selectedTech && !newProduct.tech.includes(selectedTech)) {
      const updatedTech = [...newProduct.tech, selectedTech];
      setNewProduct({ ...newProduct, tech: updatedTech });
    }
  };

  const selectTypeHandler = (selectedType) => {
    if (selectedType) {
      setNewProduct({ ...newProduct, type: selectedType });
    }
  };

  const deleteTechHandler = (deletedTech) => {
    const updatedTech = newProduct.tech.filter((tech) => tech !== deletedTech);
    setNewProduct({ ...newProduct, tech: updatedTech });
  };

  return (
    <div className="flex-container-edit-product">
      <div className="flex-container-edit-product-input">
        {/* <img
          alt="Image"
          src={`/images/${updatedProduct.name}.svg`}
          style={{ width: '100%' }}
        /> */}
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Reference</h2>
        <InputBRK
          placeholder={'Reference'}
          classes={'edit-product-input'}
          value={newProduct.reference}
          name={'reference'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Type</h2>
        <div className="tech-group-edit">
          {newProduct.type === '' ? (
            <p>No type selected yet</p>
          ) : (
            <div className="tech-item">
              <p>{newProduct.type}</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Choose a new type</h2>
        <TypeSelector onSelect={selectTypeHandler} />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Name</h2>
        <InputBRK
          placeholder={'Name'}
          classes={'edit-product-input'}
          value={newProduct.name}
          name={'name'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Composition</h2>
        <InputBRK
          placeholder={'Composition'}
          classes={'edit-product-input'}
          value={newProduct.composition}
          name={'composition'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Description</h2>
        <InputBRK
          placeholder={'Description'}
          classes={'edit-product-input'}
          value={newProduct.description}
          name={'description'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Color</h2>
        <InputBRK
          placeholder={'Color'}
          classes={'edit-product-input'}
          value={newProduct.color}
          name={'color'}
          onChange={changeProductHandler}
        />
      </div>

      <div className="flex-container-edit-product-input">
        <h2>Fabric</h2>
        <InputBRK
          placeholder={'Fabric'}
          classes={'edit-product-input'}
          value={newProduct.fabric}
          name={'fabric'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Price (€)</h2>
        <InputBRK
          placeholder={'Price (€)'}
          classes={'edit-product-input'}
          value={newProduct.price}
          name={'price'}
          onChange={changeProductHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>SRP Price (€)</h2>
        <InputBRK
          placeholder={'SRP Price (€)'}
          classes={'edit-product-input'}
          value={newProduct.srp}
          name={'srp'}
          onChange={changeProductHandler}
        />
      </div>

      <div className="flex-container-edit-product-input">
        <h2>Tech</h2>
        <div className="tech-group-edit">
          {newProduct.tech.map((t, index) => (
            <div key={index} className="tech-item">
              <p>{t}</p>
              <button
                className="delete-tech-button"
                onClick={() => deleteTechHandler(t)}
              >
                &#10006;
              </button>
            </div>
          ))}
          {newProduct.tech.length === 0 && (
            <h3>No techs found for this product.</h3>
          )}
          {/* Add Tech Selector */}
        </div>
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Add new techs</h2>
        <TechSelector onSelect={selectTechHandler} />
      </div>

      <div className="flex-container-edit-product-input">
        <h2>Image</h2>
        <FileUpload
          image={image}
          addImageHandler={addImageHandler}
          removeImageHandler={removeImageHandler}
        />
      </div>

      <ButtonBRK
        name="Save product"
        classes={'btn-accept-updated-product'}
        onClick={() => props.createNewProduct(newProduct)}
      />
    </div>
  );
}

export default AddProduct;
