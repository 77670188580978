// export const sortByGenderHold = (order, capsules) => {
//   try {
//     console.log('ORDER TO SORT', order, capsules);
//     let orderMen = {};
//     let orderWomen = {};
//     console.log(capsules);
//     for (let i = 0; i < capsules.length; i++) {
//       //iterate for each capsules
//       let capsule = capsules[i];
//       let tempCapsuleList = [];
//       for (let j = 0; j < order.length; j++) {
//         //iterate for all products looking for products belonging to the current capsule
//         let p = order[j];
//         //console.log('aver', p.product.pxCapsule, capsule._id);
//         if (p.product && p.product.pxCapsule === capsule._id) {
//           tempCapsuleList.push(p);
//         }
//       }
//       //when finishing loop, add the list of prod to the dict
//       if (tempCapsuleList.length > 0) {
//         if (capsule.gender === 'men') {
//           orderMen[capsule.name] = tempCapsuleList;
//         } else {
//           orderWomen[capsule.name] = tempCapsuleList;
//         }
//       }
//     }

//     return { orderMen, orderWomen };
//   } catch (error) {
//     console.error('Error in sortByGender function:', error);
//     throw new Error('Error sorting order by gender');
//   }
// };

export const sortByGender = (order, requiredGender, capsules) => {
  try {
    //console.log('ORDER TO SORT', order, capsules);
    let orderProducts = {};

    // console.log(capsules);
    for (let i = 0; i < capsules.length; i++) {
      //iterate for each capsules
      let capsule = capsules[i];
      let tempCapsuleList = [];
      for (let j = 0; j < order.length; j++) {
        //iterate for all products looking for products belonging to the current capsule
        let p = order[j];
        //console.log('aver', p.product.pxCapsule, capsule._id);
        if (p.product && p.product.pxCapsule === capsule._id) {
          tempCapsuleList.push(p);
        }
      }
      //when finishing loop, add the list of prod to the dict
      if (tempCapsuleList.length > 0) {
        if (capsule.gender === requiredGender) {
          orderProducts[capsule.name] = tempCapsuleList;
        }
      }
    }

    return { orderProducts };
  } catch (error) {
    console.error('Error in sortByGender function:', error);
    throw new Error('Error sorting order by gender');
  }
};

export const sortByGenderSummaryBag = (order, requiredGender, capsules) => {
  try {
    // console.log('ORDER TO SORT', order, capsules);
    let orderSorted = {};

    //console.log(capsules);
    for (let i = 0; i < capsules.length; i++) {
      //iterate for each capsules
      let capsule = capsules[i];
      let tempCapsuleList = [];
      for (let j = 0; j < order.length; j++) {
        //iterate for all products looking for products belonging to the current capsule
        let p = order[j];
        //console.log('aver', p.product.pxCapsule, capsule._id);
        if (p.product && p.product.pxCapsule === capsule.capsuleId) {
          tempCapsuleList.push(p);
        }
      }
      //when finishing loop, add the list of prod to the dict
      if (tempCapsuleList.length > 0) {
        if (capsule.gender === requiredGender) {
          orderSorted[capsule.capsuleName] = tempCapsuleList;
        }
      }
    }
    // console.log('order sorted', orderSorted);

    return { orderSorted };
  } catch (error) {
    console.error('Error in sortByGender function:', error);
    throw new Error('Error sorting order by gender');
  }
};

export const sortByGenderSummaryBagForOrderDetails = (
  order,
  requiredGender,
  capsules
) => {
  try {
    //console.log('capsules ORDER TO SORT', capsules);
    let orderSorted = {};

    //console.log(capsules);
    for (let i = 0; i < capsules.length; i++) {
      //iterate for each capsules
      let capsule = capsules[i];
      let tempCapsuleList = [];
      for (let j = 0; j < order.length; j++) {
        //iterate for all products looking for products belonging to the current capsule
        let p = order[j];
        //console.log('aver', p.product.pxCapsule, capsule._id);
        if (p.product && p.product.pxCapsule === capsule._id) {
          tempCapsuleList.push(p);
        }
      }
      //when finishing loop, add the list of prod to the dict
      if (tempCapsuleList.length > 0) {
        if (capsule.gender === requiredGender) {
          orderSorted[capsule.name] = tempCapsuleList;
        }
      }
    }
    //console.log('order sorted ICONS', orderSorted);

    return { orderSorted };
  } catch (error) {
    console.error('Error in sortByGender function:', error);
    throw new Error('Error sorting order by gender');
  }
};

// export const sortByGender = (order, capsules) => {
//   //console.log('ORDER TO SORT', order, capsules);
//   let orderMen = {};
//   let orderWomen = {};

//   for (let i = 0; i < capsules.length; i++) {
//     //iterate for each capsules
//     let capsule = capsules[i];
//     let tempCapsuleList = [];
//     for (let j = 0; j < order.length; j++) {
//       //iterate for all products looking for products belonging to current capsule
//       let p = order[j];
//       //console.log('aver', p.product.pxCapsule, capsule._id);
//       if (p.product.pxCapsule === capsule._id) {
//         tempCapsuleList.push(p);
//       }
//     }
//     //when finishing loop, add list of prod to dict
//     if (tempCapsuleList.length > 0) {
//       if (capsule.gender === 'men') {
//         orderMen[capsule.name] = tempCapsuleList;
//       } else {
//         orderWomen[capsule.name] = tempCapsuleList;
//       }
//     }
//   }
//   return { orderMen, orderWomen };
// };
