import React, { useState } from 'react';
import HeaderBRK from '../components/HeaderBRKmobile';
import SectionBlock from '../components/UI/SectionBlock';
import Footer from '../components/Footer';
import ClientMobileMenuDrawer from '../drawers/ClientMobileMenuDrawer';
import BackdropMobile from '../backdrop/BackdropMobile';
import { useNavigate } from 'react-router-dom';

const CLUB_MEMBERS = [
  {
    title: '01 Premium Fabric Selection',
    body: 'Choose from high quality fabrics that not only look sophisticated but also enhance comfort and performance on the course',
  },
  {
    title: '02 Exclusive Embroidery and Branding',
    body: 'Personalize your golf attire with exclusive embroidery and branding options. Showcase your club affiliation or add a personal touch with custom logos and monograms for a truly distinctive look.',
  },
  {
    title: '03 Stylish Versatility',
    body: 'Express your style with a range of design options. From classic looks to modern trends, tailor your golf apparel to seamlessly transition from the course to social events with unmatched style and versatility.',
  },
  {
    title: '04 Tailored Accessories',
    body: 'Complete your golf ensemble with perfectly coordinated accessories. Explore custom-designed performance caps, socks, and other accessories that complement your outfit, adding a finishing touch to your overall golfing aesthetic.',
  },
  {
    title: '05 Dedicated Styling Consultations',
    body: 'Enjoy personalized attention with dedicated styling consultations. Our experts are here to guide you through the design process, offering insights and recommendations to ensure your custom-designed golf attire perfectly aligns with your individual style and preferences.',
  },
];

const GUESTS = [
  {
    title: '01 Signature Golfing Experience',
    body: 'Immerse your guests in a unique golfing experience with personalized attire that complements the luxurious atmosphere of your hotel.',
  },
  {
    title: '02 Tailored Vacation Wardrobe',
    body: 'Enhance your guests vacation wardrobe with custom-designed golf apparel that caters to both their leisurely pursuits and their time on the course.',
  },
  {
    title: '03 Resort-Exclusive Designs',
    body: 'Stand out on the golf course with resort-exclusive patterns and styles that capture the essence of your guests stay at your golfing oasis.',
  },
  {
    title: '04 Seamless Transition from Hotel to Tee',
    body: 'Experience the convenience of a wardrobe that effortlessly transitions from the hotel to the golf course. Our custom designs ensure your guest will be ready for a round of golf without compromising on style during your entire stay',
  },
];

const COMPETITION = [
  {
    title: '01 Prestigious Tournament Tradition',
    body: 'Celebrate the tradition of your prestigious annual golf tournaments with custom-designed tournament uniforms. Showcase the historical significance of these events by offering participants exclusive, commemorative garments that become cherished mementos of their participation.',
  },
  {
    title: '02 Diverse Tournament Formats',
    body: "Introduce custom-designed uniforms tailored to each tournament's theme or format. From unique color schemes to thematic embroidery, provide golfers with custom apparel that reflects the specific character and style of each competition.",
  },
  {
    title: '03 Exclusive Member Events',
    body: 'Elevate the sense of community among club members by offering custom-designed garments exclusively for member-only tournaments. Create a sense of belonging with personalized pieces that showcase membership pride and participation in these exclusive events.',
  },
  {
    title: '04 Spectacular Prizes and Incentives',
    body: 'Attract participants with the allure of custom-designed tournament winner apparel. Craft bespoke garments, such as championship jackets or embroidered shirts, that symbolize the achievement and exclusivity of being a tournament champion.',
  },
  {
    title: '05 Showcase Course Features',
    body: 'Highlight the unique features of your golf course through custom-designed tournament attire. Incorporate course elements into the garment design, such as subtle patterns or embroidered emblems, creating a visual connection between the apparel and the distinctive aspects of your golf course.',
  },
];

function CustomDesign() {
  const imageStyle = {
    backgroundImage: `url("images/designStyle.png")`,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '700px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    //backgroundAttachment: 'fixed',
    position: 'relative',
    backgroundColor: '#070707',

    // '@media (maxWidth: 850px)': {
    //   height: '200px', // Adjusted height for smaller screens
    // },
  };

  const navigate = useNavigate();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const exitBoxStyle = {
    backgroundColor: 'black',
    paddingLeft: '2rem',
  };

  return (
    <React.Fragment>
      <div className="custom-design-page-box">
        <HeaderBRK
          classes={'dark-header'}
          location={'home'}
          openMobileMenu={openMobileMenu}
        />
        <ClientMobileMenuDrawer show={isMobileMenuOpen} />
        {isMobileMenuOpen && <BackdropMobile onClick={closeMobileMenu} />}
        {/* <div className="exit-box" style={exitBoxStyle}>
          <div className="exit-container">
            <div className="exit-2-container">
              <img
                src="/images/white-arrow.svg"
                className="arrow-exit"
                alt="white-arrow"
              />
              <p style={{ color: 'white' }}>Go back</p>{' '}
              <span style={{ fontStyle: 'italic', color: 'white' }}>
                / Custom design
              </span>
            </div>
          </div>
        </div> */}
        <div className="custom-design-box">
          <div style={imageStyle}>
            <div className="custom-design-container">
              <h1>Custom Design</h1>
              <h2>
                Recognizing the importance of your golf store we offer custom
                design services to elevate your diverse clientele's golfing
                experience with a touch of personal elegance.
              </h2>
            </div>
          </div>
        </div>
        <SectionBlock
          title={'For Club Members'}
          subtitle={'TAILORED EXCELLENCE'}
          header={'Fidelity'}
          body={
            'Select fabrics, colors, and details that resonate with your golfing identity and showcase your passion for the sport.'
          }
          // imageName={'/home-page/Ana-home-page.jpg'}
          imageName={'/custom-design/1.png'}
          keyPoints={CLUB_MEMBERS}
        />
        <SectionBlock
          title={'For Your Guest'}
          subtitle={'DISTINCTIVE WELCOME'}
          header={'Unique memento'}
          body={
            'Welcome to a golfing experience like no other. Enhance their stay with personalized golf apparel that reflects the spirit of their visit.'
          }
          // imageName={'/home-page/Ana-home-page.jpg'}
          imageName={'/custom-design/2.png'}
          keyPoints={GUESTS}
        />
        <SectionBlock
          title={'For Events'}
          subtitle={'EXCLUSIVITY'}
          header={'Tradition'}
          body={
            'A legacy of competition, camaraderie, and skill, each event carries the spirit of golfing excellence.'
          }
          // imageName={'/home-page/Ana-home-page.jpg'}
          imageName={'/custom-design/tour.png'}
          keyPoints={COMPETITION}
        />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default CustomDesign;
