import React from 'react';
import ButtonBRK from '../../../buttons/ButtonBRK';

function HeaderDashboard(props) {
  const changeDateFormat = (originalDate) => {
    // Parse the original date string
    const dateObj = new Date(originalDate);

    // Define an array of month names
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Get the month, day, and year from the date object
    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    // Construct the formatted date string
    const formattedDate = `${month} ${day < 10 ? '0' + day : day} ${year}`;
    return formattedDate;
  };

  function TodayDate() {
    // Get the current date
    const currentDate = new Date();

    // Call the function to format the date
    const formattedTodayDate = changeDateFormat(currentDate);

    return <div>{formattedTodayDate}</div>;
  }

  return (
    <div className="grid-container-title-admin-dash">
      <div className="title-admin-dash card c-short">
        <div className="title-admin-dash-info">
          <h2>{`Welcome ${props.userName}!`}</h2>
          <p>{props.text}</p>
        </div>
      </div>
      <div className="date-admin-dash">
        <h2>{TodayDate()}</h2>
        {/* <div className="collection-admin-dash">
          <p>Choose a collection</p>
          <ButtonBRK name={'AW24 Collection'} classes={'btn-admin-dash'}>
            AW24 Collection
          </ButtonBRK>
        </div> */}
      </div>
    </div>
  );
}

export default HeaderDashboard;
