import React from 'react';
import { capFirstLetter } from '../../../functions/cap-first-letter';

function ProductSimulation(props) {
  return (
    <React.Fragment>
      {
        <div>
          <div className="products-box-flex-container">
            {props.products.map(
              (product) =>
                props.capsule === product.pxCapsule && (
                  <div className="product-box-flex-container" key={product._id}>
                    {product.image.url ? (
                      <img
                        //onClick={() => handleImage(product)}
                        src={`${product.image.url}`}
                        alt="product-img"
                        className="product-page-img"
                      />
                    ) : (
                      <img
                        //onClick={() => handleImage(product)}
                        src="/images/not-found-image.png"
                        alt="product-img"
                        className="product-page-img"
                      />
                    )}

                    <div className="info-product-item">
                      <div className="name-ref">
                        <h2>{`${capFirstLetter(product.name)}`}</h2>
                        <h2>{`${product.price} €`}</h2>
                      </div>
                      <div className="name-ref">
                        <p>{`Ref. ${product.reference}`}</p>
                        <p>{`srp. ${product.srp} €`}</p>
                      </div>
                      {/* <div className="name-ref">
                            <p className="prod-composition">
                              {product.composition}
                            </p>
                          </div> */}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default ProductSimulation;
