import SelectorBRK from '../../input/SelectorBRK';
import React, { useState, useEffect } from 'react';
import ButtonBRK from '../../buttons/ButtonBRK';
import { listCollections } from '../../../functions/collections';
import { listCapsules } from '../../../functions/capsules';
import { listCollectionsPricing } from '../../../functions/collections';
import { useSelector } from 'react-redux';

const initialStateCollections = [{ _id: '', name: '' }];
const initialStateCapsules = [{ _id: '', name: '' }];
const initialPricingCollections = [{ _id: '', name: '' }];

function AddProducts(props) {
  const user = useSelector((state) => state.user);
  //States
  const [newProducts, setNewProducts] = useState({
    pricingCollectionId: '',
    capsuleId: '',
    // collectionId: '',
  });
  const [collections, setCollections] = useState(initialStateCollections);
  const [capsules, setCapsules] = useState(initialStateCapsules);
  const [pricingColl, setPricingColl] = useState(initialPricingCollections);

  console.log(newProducts);
  //useEffect
  //Useeffect
  useEffect(() => {
    loadCollections();
    loadCapsules();
    loadPricingCollections();
  }, []);

  useEffect(() => {
    //to load of data the first round newProducts
    // setNewProducts({
    //   pricingCollectionId: pricingColl[0]._id,
    //   capsuleId: capsules[0]._id,
    //   // collectionId: collections[0]._id,
    // });
  }, [collections, capsules, pricingColl]);

  const loadCollections = () => {
    listCollections(user.token)
      .then((res) => {
        setCollections(res.data.collections);
      })
      .catch((err) => console.log(err));
  };

  const loadCapsules = () => {
    listCapsules(user.token)
      .then((res) => {
        setCapsules(res.data.capsules);
      })
      .catch((err) => console.log(err));
  };

  const loadPricingCollections = () => {
    listCollectionsPricing(user.token)
      .then((res) => {
        setPricingColl(res.data.pricingCollections);
      })
      .catch((err) => console.log(err));
  };

  //Functions
  const changeCapsuleId = (capId) => {
    setNewProducts({ ...newProducts, capsuleId: capId });
  };

  const changeCollectionId = (colId) => {
    setNewProducts({ ...newProducts, collectionId: colId });
  };

  const changePricingCollectionId = (colId) => {
    setNewProducts({ ...newProducts, pricingCollectionId: colId });
  };

  const addNewProductsHandler = () => {
    //check there are no empty fields
    const isFormValid = () => {
      for (const key in newProducts) {
        if (newProducts[key] === '') {
          return false;
        }
      }
      return true;
    };

    if (isFormValid()) {
      // Perform the submission or other actions
      //we can execute the function on the parent component
      props.onCreateProducts(newProducts);
      setNewProducts({
        pricingCollectionId: '',
        capsuleId: '',
        // collectionId: '',
      });
    } else {
      // console.log('Make sure there is no empty fields!');
    }
  };

  return (
    <div className="flex-container-create-new-collection">
      {/* <h2>Choose collection</h2>
      <SelectorBRK
        classes={'color-white'}
        onSelectGender={changeCollectionId}
        options={collections}
      /> */}
      <h2>Choose a capsule</h2>
      <SelectorBRK
        classes={'color-white'}
        onSelectGender={changeCapsuleId}
        options={capsules}
      />
      <h2>Choose collection from PAPP</h2>
      <SelectorBRK
        classes={'color-white'}
        onSelectGender={changePricingCollectionId}
        options={pricingColl}
      />

      <ButtonBRK
        name="Add products"
        classes={'btn-accept-create-new-collection'}
        onClick={addNewProductsHandler}
      />
    </div>
  );
}

export default AddProducts;
