import React, { useState, useEffect } from 'react';
import { listCapsules } from '../../../functions/capsules';
import { listCollections } from '../../../functions/collections';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function AdminPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  const dashboardClickHandler = () => navigate('/dashboard-page');
  const collectionsClickHandler = () => navigate('/collections-page');
  const productsClickHandler = () => navigate('/products-page');
  const ordersClickHandler = () => navigate('/orders-page');
  const analyticsClickHandler = () => navigate('/analytics-page');
  const clientsClickHandler = () => navigate('/clients-page');

  //states
  const [collections, setCollections] = useState([]);
  const [capsules, setCapsules] = useState([]);
  const [capsuleOpen, setCapsuleOpen] = useState(true);
  const [capIndexHelper, setCapIndexHelper] = useState('');

  const [currentLocation, setCurrentLocation] = useState({
    dashboard: false,
    collections: false,
    products: false,
    orders: false,
    stats: false,
    clients: false,
  });

  //useEffect
  useEffect(() => {
    loadCollections();
    loadCapsules();
  }, []);

  useEffect(() => {
    const path = location.pathname;
    setCurrentLocation({
      dashboard: path === '/dashboard-page',
      products: path === '/products-page',
      collections: path === '/collections-page',
      orders: path === '/orders-page',
      stats: path === '/analytics-page',
      clients: path === '/clients-page',
    });
  }, [location.pathname]);

  const loadCollections = () => {
    listCollections(user.token)
      .then((res) => {
        setCollections(res.data.collections);
      })
      .catch((err) => console.log(err));
  };

  const loadCapsules = () => {
    listCapsules(user.token)
      .then((res) => {
        setCapsules(res.data.capsules);
      })
      .catch((err) => console.log(err));
  };

  const printCapsules = (currentCollId) => {
    return capsules.map((c) => (
      <p key={c._id}>{currentCollId === c.pxCollection && c.name}</p>
    ));
  };

  const openCapsulesHandler = (index) => {
    //setCapsuleOpen(!capsuleOpen);
    setCapIndexHelper(index);
    //console.log('index?', index);
  };

  return (
    <div className="flex-container-admin-panel-desktop">
      <img
        className="logo"
        src="/images/bereallykeen_white.svg"
        onClick={props.goHomeHandleClick}
      />
      {user.name !== 'Gabriela' ? (
        <div className="flex-container-menu">
          <p>Menu</p>
          <div className="flex-container-icon-text">
            {/* <img
                src="/images/label-collection.svg"
                alt="img"
                className="img-admin-panel"
              /> */}
            <h3
              onClick={dashboardClickHandler}
              style={currentLocation.dashboard ? { fontWeight: 'bold' } : {}}
            >
              Dashboard
            </h3>
          </div>

          <div className="box-container-collections">
            <div className="flex-container-icon-text">
              {/* <img
                  src="/images/label-collection.svg"
                  alt="img"
                  className="img-admin-panel"
                /> */}
              <h3
                onClick={collectionsClickHandler}
                style={
                  currentLocation.collections ? { fontWeight: 'bold' } : {}
                }
                //className="bold-panel"
              >
                Collections
              </h3>
            </div>

            {/* {collections.length > 0 &&
            collections.map((c, index) => {
              return (
                <div className="flex-container-gender" key={index}>
                  <h3
                    className="select-icon"
                    onClick={() => openCapsulesHandler(index)}
                  >
                    {c.name}
                  </h3>
                  <div
                    className={
                      capsuleOpen && index === capIndexHelper
                        ? 'flex-container-capsules-open'
                        : 'flex-container-capsules-close'
                    }
                  >
                    <ul>{capsules.length > 0 && printCapsules(c._id)}</ul>
                  </div>
                </div>
              );
            })} */}
          </div>
          <div className="flex-container-icon-text">
            {/* <img
                src="/images/label-collection.svg"
                alt="img"
                className="img-admin-panel"
              /> */}
            <h3
              onClick={productsClickHandler}
              style={currentLocation.products ? { fontWeight: 'bold' } : {}}
            >
              Products
            </h3>
          </div>
          <div className="flex-container-icon-text">
            {/* <img
                src="/images/label-collection.svg"
                alt="img"
                className="img-admin-panel"
              /> */}
            <h3
              onClick={ordersClickHandler}
              style={currentLocation.orders ? { fontWeight: 'bold' } : {}}
            >
              Orders
            </h3>
          </div>
          <div className="flex-container-icon-text">
            {/* <img
                src="/images/label-collection.svg"
                alt="img"
                className="img-admin-panel"
              /> */}
            <h3
              onClick={clientsClickHandler}
              style={currentLocation.clients ? { fontWeight: 'bold' } : {}}
            >
              Clients
            </h3>
          </div>
          <div className="flex-container-icon-text">
            {/* <img
                src="/images/label-collection.svg"
                alt="img"
                className="img-admin-panel"
              /> */}
            <h3
              onClick={analyticsClickHandler}
              style={currentLocation.stats ? { fontWeight: 'bold' } : {}}
            >
              Production
            </h3>
          </div>
          <p style={{ marginTop: '1rem' }}>{user && user.name}</p>
        </div>
      ) : (
        <div className="flex-container-menu">
          <p>Menu</p>

          <div className="box-container-collections">
            <div className="flex-container-icon-text">
              {/* <img
                  src="/images/label-collection.svg"
                  alt="img"
                  className="img-admin-panel"
                /> */}
              <h3
                onClick={collectionsClickHandler}
                style={
                  currentLocation.collections ? { fontWeight: 'bold' } : {}
                }
                //className="bold-panel"
              >
                Collections
              </h3>
            </div>
          </div>
          <div className="flex-container-icon-text">
            {/* <img
                src="/images/label-collection.svg"
                alt="img"
                className="img-admin-panel"
              /> */}
            <h3
              onClick={productsClickHandler}
              style={currentLocation.products ? { fontWeight: 'bold' } : {}}
            >
              Products
            </h3>
          </div>

          <div className="flex-container-icon-text">
            {/* <img
                src="/images/label-collection.svg"
                alt="img"
                className="img-admin-panel"
              /> */}
            <h3
              onClick={analyticsClickHandler}
              style={currentLocation.stats ? { fontWeight: 'bold' } : {}}
            >
              Production
            </h3>
          </div>
          <p style={{ marginTop: '1rem' }}>{user && user.name}</p>
        </div>
      )}
      {/* <p>User</p> */}
    </div>
  );
}

export default AdminPage;
