import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminMobileMenuDrawer from '../../drawers/AdminMobileMenuDrawer';
import BackdropMobile from '../../backdrop/BackdropMobile';
import AdminPanelDesktop from '../../components/UI/admin/AdminPanelDesktop';
import AdminHeaderMobile from '../../components/UI/admin/AdminHeaderMobile';
import AdminHeader from '../../components/UI/admin/AdminHeader';
import Modal from '../../modals/Modal';
import CreateCollection from '../../components/UI/admin/CreateCollection';
import AddCapsules from '../../components/UI/admin/AddCapsules';
import AddProducts from '../../components/UI/admin/AddProducts';
import AddClient from '../../components/UI/admin/AddClient';
import AddProduct from '../../components/UI/AddProduct';
import { createCollection } from '../../functions/collections';
import { createCapsule } from '../../functions/capsules';
import { addProducts, addProduct } from '../../functions/products';
import { useSelector } from 'react-redux';

function AdminBodyStruct(props) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  //States
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNewCollectionOpen, setIsNewCollectionOpen] = useState(false);
  const [isAddCapsuleOpen, setIsAddCapsuleOpen] = useState(false);
  const [isAddProductsOpen, setIsAddProductsOpen] = useState(false);
  const [isAddClientOpen, setIsAddClientOpen] = useState(false);
  const [isAddProductOpen, setIsAddProductOpen] = useState(false);

  // const [newCollection, setNewCollection] = useState('');

  //Functions
  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const goHomeHandleClick = () => {
    navigate('/');
  };

  const openNewCollectionModalhandleClick = () => {
    setIsNewCollectionOpen(true);
  };

  const closeNewCollectionModalhandleClick = () => {
    setIsNewCollectionOpen(false);
  };

  const openAddCapsuleModalhandleClick = () => {
    setIsAddCapsuleOpen(true);
  };

  const closeAddCapsuleModalhandleClick = () => {
    setIsAddCapsuleOpen(false);
  };

  const openAddProductsModalhandleClick = () => {
    setIsAddProductsOpen(true);
  };

  const closeAddProductsModalhandleClick = () => {
    setIsAddProductsOpen(false);
  };

  const openAddClientModalhandleClick = () => {
    setIsAddClientOpen(true);
  };

  const closeAddClientModalhandleClick = () => {
    setIsAddClientOpen(false);
  };

  const openAddProductModalhandleClick = () => {
    setIsAddProductOpen(true);
  };

  const closeAddProductModalhandleClick = () => {
    setIsAddProductOpen(false);
  };

  const createNewCollection = (collectionName) => {
    //Save new collection into db
    const newCollection = { name: collectionName };

    createCollection(newCollection, user.token)
      .then((res) =>
        console.log('NEW COLLECTION CREATED -> ', res.data.newCollection)
      )
      .catch((err) => console.log(err));
  };

  const createNewCapsule = (newCapsule) => {
    if (
      newCapsule.name === '' ||
      newCapsule.gender === '' ||
      newCapsule.collectionId === ''
    ) {
      //console.log('CANNOT SAVE EMPTY VALUES');
      return;
    }

    createCapsule(newCapsule, user.token)
      .then((res) => {
        //console.log('NEW CAPSULE CREATED -> ', res.data);
        closeAddCapsuleModalhandleClick();
      })
      .catch((err) => console.log(err));
  };

  const createNewProducts = (newProducts) => {
    //send new products to DB
    addProducts(newProducts, user.token)
      .then((res) => {
        // console.log('NEW PRODUCTS CREATED -> ', res.data);
        closeAddProductsModalhandleClick();
      })
      .catch((err) => console.log(err));
  };

  const createNewProduct = (newProduct) => {
    //send new products to DB
    addProduct(newProduct, user.token)
      .then((res) => {
        //console.log('NEW PRODUCT CREATED -> ', res.data);
        closeAddProductModalhandleClick();
      })
      .catch((err) => console.log(err));
  };

  const createNewClient = (Name) => {
    //Save new collection into db
    //const newClient = { name: clientName };
    // createCollection(newCollection)
    //   .then((res) =>
    //     console.log('NEW COLLECTION CREATED -> ', res.data.newCollection)
    //   )
    //   .catch((err) => console.log(err));
  };

  return (
    <React.Fragment>
      <Modal
        show={isNewCollectionOpen}
        onCancel={closeNewCollectionModalhandleClick}
        header="Create new collection"
      >
        <CreateCollection onCreateNewCollection={createNewCollection} />
      </Modal>

      <Modal
        show={isAddCapsuleOpen}
        onCancel={closeAddCapsuleModalhandleClick}
        header="Create capsule"
      >
        <AddCapsules onCreateNewCapsule={createNewCapsule} />
      </Modal>

      <Modal
        show={isAddProductsOpen}
        onCancel={closeAddProductsModalhandleClick}
        header="Add products"
      >
        <AddProducts onCreateProducts={createNewProducts} />
      </Modal>

      <Modal
        show={isAddClientOpen}
        onCancel={closeAddClientModalhandleClick}
        header="Add client"
      >
        <AddClient
          //onCreateProducts={createNewProducts}
          closeAddClientModalhandleClick={closeAddClientModalhandleClick}
        />
      </Modal>

      <Modal
        show={isAddProductOpen}
        onCancel={closeAddProductModalhandleClick}
        header="Add product"
      >
        <AddProduct
          createNewProduct={createNewProduct}
          closeAddProductModalhandleClick={closeAddProductModalhandleClick}
        />
      </Modal>

      <AdminMobileMenuDrawer show={isMobileMenuOpen} />
      {isMobileMenuOpen && <BackdropMobile onClick={closeMobileMenu} />}
      <div className="grid-admin-structure">
        <AdminPanelDesktop goHomeHandleClick={goHomeHandleClick} />

        <div className="flex-container-general-admin-page">
          <div className="card-collection-page">
            <AdminHeaderMobile
              goHomeHandleClick={goHomeHandleClick}
              openMobileMenu={openMobileMenu}
              isMobileMenuOpen={isMobileMenuOpen}
            />

            <AdminHeader
              onClickCreateCollection={openNewCollectionModalhandleClick}
              onClickAddCapsule={openAddCapsuleModalhandleClick}
              onClickAddProducts={openAddProductsModalhandleClick}
              onClickCreateClient={openAddClientModalhandleClick}
              onClickCreateProduct={openAddProductModalhandleClick}
            />

            {props.children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminBodyStruct;
