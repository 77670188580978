import React from 'react';
import SizeQtyOrderDetail from './SizeQtyOrderDetail';
import { homePageOrderTotal } from '../../functions/qty-check';
import { sortByGender } from '../../functions/sortProducts';
import { checkSizes } from '../../functions/check-sizes';
import { capFirstLetter } from '../../functions/cap-first-letter';

// Object.entries(
//     sortByGenderSummaryBag(props.cart, props.requiredGender, props.capsules)
//       .orderSorted
//   ).length === 0 ? (
//     <div className="products-icons-setup-box">
//       <p style={{ fontStyle: 'italic' }}>
//         (No products have been added from the {props.requiredGender}'s
//         collection yet)
//       </p>
//     </div>
//   ) :

function ProductsOrderDetails(props) {
  return (
    <div className="flex-container-order-details-products">
      {Object.entries(
        sortByGender(props.order, props.requiredGender, props.capsules)
          .orderProducts.length > 0
      ) &&
        Object.entries(
          sortByGender(props.order, props.requiredGender, props.capsules)
            .orderProducts
        ).map(([capsuleName, productList]) => (
          <div key={capsuleName}>
            <h1>
              {props.requiredGender.toUpperCase()}
              <span>{` / ${capsuleName}`}</span>{' '}
            </h1>
            <div key={capsuleName}>
              {/* <h3>{capsuleName}</h3> */}
              <ul>
                {productList.map((p) => (
                  <React.Fragment key={p.product._id}>
                    <div className="product-info-flex-container">
                      <div className="info-box-flex-container">
                        {p.product.image.url && (
                          <img
                            //onClick={() => handleImage(product)}
                            // src={`/images/${product.name}.svg`}

                            src={`${p.product.image.url}`}
                            alt="product-img"
                            className="info-img"
                          />
                        )}

                        <div className="info-item">
                          <div className="name-price-box">
                            <h2>{`${capFirstLetter(p.product.name)}`}</h2>
                            <div className="price-item">
                              <h2>{p.product.price}€</h2>
                              <p>/ SRP: {p.product.srp}€</p>
                            </div>
                          </div>
                          <p>{`Ref. ${p.product.reference}`}</p>
                          <p>{p.product.color}</p>
                          <p>{p.product.composition}</p>
                          <p>{p.product.description}</p>
                        </div>
                      </div>
                    </div>

                    <div className="flex-container-order-details-sizeQty">
                      <div className="flex-container-sizeQty">
                        <p>
                          <b>Size/Pcs:</b>{' '}
                        </p>
                        {props.order &&
                          props.capsules &&
                          props.capsules.length > 0 &&
                          checkSizes(p.product, props.capsules).map(
                            (item, index) => {
                              return (
                                <SizeQtyOrderDetail
                                  order={props.order}
                                  pid={p.product._id}
                                  item={item}
                                  key={index}
                                />
                              );
                            }
                          )}
                      </div>
                      <div className="order-details-img-logo-container">
                        {/* {p.logo ? (
                          <p>
                            <b>Logo:</b> Yes
                          </p>
                        ) : (
                          <p>
                            <b>Logo:</b> No
                          </p>
                        )} */}
                        {p.logo ? (
                          <img
                            src="/images/logo-added-border.svg"
                            alt="logo"
                            className="order-details-img-logo"
                          />
                        ) : (
                          <img
                            src="/images/no-logo.svg"
                            alt="logo"
                            className="order-details-img-logo"
                          />
                        )}
                      </div>
                    </div>
                    <div className="qty-flex-container">
                      <div className="flex-container-add-logo"></div>
                    </div>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        ))}
    </div>
  );
}

export default ProductsOrderDetails;
