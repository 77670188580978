import React from 'react';
import { sortByGenderSummaryBagForOrderDetails } from '../functions/sortProducts';

// Object.entries(
//     sortByGenderSummaryBag(props.cart, props.requiredGender, props.capsules)
//       .orderSorted
//   ).length === 0 ? (
//     <div className="products-icons-setup-box">
//       <p style={{ fontStyle: 'italic' }}>
//         (No products have been added from the {props.requiredGender}'s
//         collection yet)
//       </p>
//     </div>
//   ) :

function ProductIconSortedForOrderDetails(props) {
  const checkIndividualStyleNumber = (product) => {
    const sumOfQty = product.pidOrder.reduce(
      (total, obj) => total + obj.qty,
      0
    );

    return sumOfQty;
  };

  return (
    <React.Fragment>
      {props.cart &&
      props.cart.length > 0 &&
      props.isIconMode &&
      Object.entries(
        sortByGenderSummaryBagForOrderDetails(
          props.cart,
          props.requiredGender,
          props.capsules
        ).orderSorted
      ).length === 0 ? (
        <div className="products-icons-order-details-setup-box">
          <p style={{ fontStyle: 'italic' }}>
            (No products ordered from the {props.requiredGender}
            's collection)
          </p>
        </div>
      ) : (
        Object.entries(
          sortByGenderSummaryBagForOrderDetails(
            props.cart,
            props.requiredGender,
            props.capsules
          ).orderSorted
        ).map(([capsuleName, productList]) => (
          <div
            className="products-icons-order-details-setup-box"
            key={capsuleName}
          >
            <h1>
              {`${props.requiredGender.toUpperCase()}`}
              <span>{` / ${capsuleName}`}</span>{' '}
            </h1>
            {/* <h3>{capsuleName}</h3> */}
            <div className="products-icons-order-details-setup">
              {productList.map((p) => (
                <div className="image-container" key={p.product._id}>
                  <img
                    src={p.product.image.url}
                    alt="product-img"
                    className="info-img"
                  />
                  {p.logo && (
                    <div className="logo-added">
                      <img
                        src="/images/logo-added-border.svg"
                        alt="logo"
                        className="info-img"
                      />
                    </div>
                  )}

                  <div className="circle-number">
                    <p>{checkIndividualStyleNumber(p)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </React.Fragment>
  );
}

export default ProductIconSortedForOrderDetails;
