import React from 'react';

function SelectorOrder(props) {
  const numberOptions = Array.from({ length: 30 }, (_, index) => index + 1);

  //function
  const handleOrder = (event) => {
    //trigger function in CollectionsPage
    props.changeOrderHandler(event.target.value, props.product._id);
  };

  return (
    <select
      value={props.value}
      className={['styled-select', props.classes].join(' ')}
      // onSelect={handleBRKSelect}
      onChange={handleOrder}
    >
      {numberOptions.map((g, index) => (
        <option value={g} key={index} selected={props.product.order === g}>
          {g}
        </option>
      ))}
      {/* <option value="men">Men</option>
      <option value="women">Women</option> */}
    </select>
  );
}

export default SelectorOrder;
