import React from 'react';
import ButtonBRK from '../buttons/ButtonBRK';
import ButtonInfo from '../buttons/ButtonInfo';

function OrderConfirmation(props) {
  return (
    <div className="flex-container-order-confirmation">
      <div className="order-confirmation-text">
        <h3>
          You are on the verge of confirming your order. Kindly bear in mind
          that proceeding will indicate your agreement with our terms and
          conditions.
        </h3>
      </div>

      <div className="flex-container-btn-confirmation">
        <ButtonBRK
          name={'Confirm order'}
          classes={'btn-order-confirmation'}
          onClick={props.confirmOrderHandler}
        />
        <ButtonInfo
          name={'Go back'}
          classes={'btn-order-confirmation-go-back'}
          onClick={props.closeOrderConfirmationHandler}
        />
      </div>
    </div>
  );
}

export default OrderConfirmation;
