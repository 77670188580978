import axios from 'axios';

export const listProducts = async (authtoken) =>
  await axios.get(`/api/products`, {
    headers: {
      authtoken,
    },
  });

export const getProductById = async (productId, authtoken) =>
  await axios.get(`/api/products/product/${productId}`, {
    headers: {
      authtoken,
    },
  });

export const addProducts = async (newProducts, authtoken) =>
  await axios.post(`/api/products`, newProducts, {
    headers: {
      authtoken,
    },
  });

export const addProduct = async (product, authtoken) =>
  await axios.post(`/api/products/create-new/product`, product, {
    headers: {
      authtoken,
    },
  });

export const updateProduct = async (product, productId, authtoken) =>
  await axios.post(`/api/products/${productId}`, product, {
    headers: {
      authtoken,
    },
  });

export const getProductsForAvailableCollections = async (
  collectionId,
  authtoken
) =>
  await axios.get(`/api/products/capsules/${collectionId}`, {
    headers: {
      authtoken,
    },
  });

export const updateProductOrder = async (product, authtoken) =>
  await axios.post(`/api/products/order/update-order`, product, {
    headers: {
      authtoken,
    },
  });

export const updateProductCapsule = async (product, authtoken) =>
  await axios.post(`/api/products/capsule/update-capsule`, product, {
    headers: {
      authtoken,
    },
  });
