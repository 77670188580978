import axios from 'axios';

//WE HAVE TO ADD AUTH TO EVERYTHING!!!!

export const createOrder = async (collectionId, authtoken) =>
  await axios.post(`/api/orders`, collectionId, {
    headers: {
      authtoken,
    },
  });

export const createOrderFromAdmin = async (data, authtoken) =>
  await axios.post(`/api/orders/orderFromAdmin`, data, {
    headers: {
      authtoken,
    },
  });

export const getOrderById = async (orderId, authtoken) =>
  await axios.get(`/api/orders/${orderId}`, {
    headers: {
      authtoken,
    },
  });

export const deleteOrderById = async (orderId, userEmail, authtoken) =>
  await axios.delete(`/api/orders/${orderId}/${userEmail}`, {
    headers: {
      authtoken,
    },
  });

export const updateOrderById = async (orderId, order, authtoken) =>
  await axios.post(`/api/orders/${orderId}`, order, {
    headers: {
      authtoken,
    },
  });

export const listOrders = async (authtoken) =>
  await axios.get(`/api/orders`, {
    headers: {
      authtoken,
    },
  });

export const getOrdersClient = async (clientId, authtoken) =>
  await axios.get(`/api/orders/order/client/${clientId}`, {
    headers: {
      authtoken,
    },
  });

export const confirmOrderById = async (orderId, authtoken) =>
  await axios.post(`/api/orders/order/order-confirm`, orderId, {
    headers: {
      authtoken,
    },
  });

//UPDATE PASSING COLLECTION ID!!!!!
export const getOrdersForStats = async (collectionId, authtoken) =>
  await axios.post(`/api/orders/order/stats`, collectionId, {
    headers: {
      authtoken,
    },
  });

export const getIndividualOrdersForStats = async (collectionId, authtoken) =>
  await axios.post(`/api/orders/order/individuals/stats`, collectionId, {
    headers: {
      authtoken,
    },
  });

// export const changeOrderConfirmation = async (orderId, authtoken) =>
//   await axios.get(`/api/orders/changeConfirmation/${orderId}`, {
//     headers: {
//       authtoken,
//     },
//   });

//data => orderId, action
export const changeOrderStatus = async (data, authtoken) =>
  await axios.post(`/api/orders/changeOrderStatus`, data, {
    headers: {
      authtoken,
    },
  });
