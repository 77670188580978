import React, { useEffect, useState } from 'react';
import AdminBodyStruct from './AdminBodyStruct';
import { listClients } from '../../functions/clients';
import { listOrders } from '../../functions/orders';
import { useSelector } from 'react-redux';
import { capFirstLetter } from '../../functions/cap-first-letter';
import InfoLoaderAdmin from '../../components/loaders/InfoLoaderAdmin';
import InputBRK from '../../components/input/InputBRK';
import { listCollections } from '../../functions/collections';

function ClientsPage() {
  const [clients, setClients] = useState([]);
  const [sortedClients, setSortedClients] = useState([]);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [foundEmail, setFoundEmail] = useState('');
  const [orders, setOrders] = useState([]);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    setLoading(true);
    loadClients();
    loadOrdersDB();
    loadCollections();
  }, []);

  useEffect(() => {
    if (clients.length > 0 && orders.length > 0) {
      const clientsWithTotal = clients.map((client) => {
        const clientOrders = orders.filter(
          (order) => order.email === client.email
        );
        const totalSpent = clientOrders.reduce(
          (total, order) => total + order.orderTotal,
          0
        );
        return { ...client, totalSpent };
      });

      const sortedClients = clientsWithTotal.sort(
        (a, b) => b.totalSpent - a.totalSpent
      );

      setSortedClients(sortedClients);
    }
  }, [clients, orders]);

  const loadClients = () => {
    listClients(user.token)
      .then((res) => {
        setClients(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadOrdersDB = () => {
    listOrders(user.token)
      .then((res) => {
        setOrders(res.data.orders);
        console.log(res.data);
      })
      .catch((err) => {
        console.log('Could not load orders');
        console.log(err);
      });
  };

  const loadCollections = async () => {
    try {
      const res = await listCollections(user.token);
      setCollections(res.data.collections);
    } catch (err) {
      console.error('Could not load collections in DASHBOARD PAGE', err);
    } finally {
      //setLoading(false);
    }
  };

  const handleFindEmailChange = (e) => {
    setFoundEmail(e.target.value);
  };

  // const searched = (keyword) => (c) =>
  //   c.email.toLowerCase().includes(keyword.toLowerCase());
  const searched = (keyword) => (c) =>
    c.email.toLowerCase().includes(keyword.toLowerCase()) ||
    c.name.toLowerCase().includes(keyword.toLowerCase()) ||
    c.address.toLowerCase().includes(keyword.toLowerCase());

  const checkOrderHistory = (client) => {
    const clientOrders = orders.filter((order) => order.email === client.email);

    if (clientOrders.length === 0) {
      return (
        <span style={{ fontStyle: 'italic' }}>
          No orders found for this client
        </span>
      );
    }

    const totalSpent = clientOrders.reduce(
      (total, order) => total + order.orderTotal,
      0
    );

    const getStatusCircle = (status, trueColor, falseColor) => (
      <div
        className={`status-circle ${status ? 'status-true' : 'status-false'}`}
        style={{ backgroundColor: status ? trueColor : falseColor }}
      />
    );

    return (
      <ul>
        {clientOrders.map((order, index) => (
          <li key={index} className="order-item">
            <div className="order-info">
              {checkCollectionName(order.pxCollection)} : Order Total:{' '}
              {order.orderTotal} €
            </div>
            <div className="order-status-client-page">
              {getStatusCircle(order.confirmed, 'green', 'blue')}
              {order.confirmed ? (
                <>
                  {getStatusCircle(order.paid, 'green', 'red')}
                  {getStatusCircle(order.delivered, 'green', 'red')}
                </>
              ) : (
                <>
                  {getStatusCircle(false, 'gray', 'gray')}
                  {getStatusCircle(false, 'gray', 'gray')}
                </>
              )}
            </div>
          </li>
        ))}
        <li style={{ paddingTop: '0.5rem' }}>
          Total Spent: {totalSpent.toFixed(2)} €
        </li>
      </ul>
    );
  };

  const checkCollectionName = (id) => {
    const collection = collections.find((c) => c._id === id);
    return collection ? collection.name : 'Collection name not found';
  };

  const handleClickGoToGoogleMap = () => {
    const isConfirmed = window.confirm('Are you sure you want to open map?');

    //https://www.google.com/maps/d/u/0/edit?mid=1TKp_4hqpUQTvdeBhaLL3BlBg6I7zMSo&usp=sharing
    if (isConfirmed) {
      openNewWindow(
        'https://www.google.com/maps/d/u/0/edit?mid=1TKp_4hqpUQTvdeBhaLL3BlBg6I7zMSo&usp=sharing'
      ); // Replace with your desired URL
    }
  };

  function openNewWindow(url) {
    window.open(url, '_blank');
  }

  return (
    <AdminBodyStruct>
      <div className="clients-box">
        {loading ? (
          <InfoLoaderAdmin />
        ) : (
          <>
            <div className="flex-container-title-selector mb-1">
              <h2>
                Clients <span>({`${sortedClients.length}`})</span>
              </h2>
              <InputBRK
                placeholder={'Email or name or province'}
                onChange={handleFindEmailChange}
              />
            </div>

            <div className="container-point-system">
              <div>
                <h3>The point system works like this:</h3>
                <ul>
                  <li>
                    First point: Blue (Order in progress) / Green (Order
                    confirmed)
                  </li>
                  <li>Second point: Red (Not paid) / Green (Paid)</li>
                  <li>Third point: Red (Not Delivered) / Green (Delivered)</li>
                  <li>Grey for payment and shipping if Order in progress</li>
                </ul>
              </div>
              <div>
                <p onClick={() => handleClickGoToGoogleMap()}>View on map</p>
              </div>
            </div>

            <div className="flex-container-clients-board">
              {sortedClients.length > 0 &&
                sortedClients.filter(searched(foundEmail)).map((c, index) => {
                  return (
                    <div className="client-card" key={c._id}>
                      <div className="client-card-info-box">
                        <div className="client-header-info-box">
                          <h2 className="client-title">
                            {index + 1}
                            {'. '}
                            {capFirstLetter(c.name)} <span> ({c.email})</span>
                          </h2>
                          <h2 className="client-title">{c.address}</h2>
                        </div>
                        <h3 className="client-info">
                          <span>Order History:</span>
                        </h3>
                        <h3
                          className="client-info"
                          style={{ paddingTop: '0rem', paddingLeft: '2rem' }}
                        >
                          {checkOrderHistory(c)}
                        </h3>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </AdminBodyStruct>
  );
}

export default ClientsPage;
