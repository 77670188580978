import React, { useEffect, useState } from 'react';
import AdminBodyStruct from './AdminBodyStruct';
import SelectorBRK from '../../components/input/SelectorBRK';
import ProductListAdmin from '../../components/UI/ProductListAdmin';
import Modal from '../../modals/Modal';
import EditProduct from '../../components/UI/admin/EditProduct';
import {
  listCapsules,
  updateCapsuleAvailability,
} from '../../functions/capsules';
import { listProducts } from '../../functions/products';
import ImageBigScale from '../../components/UI/ImageBigScale';
import { useSelector } from 'react-redux';
import { updateProductOrder } from '../../functions/products';
import { toast, ToastContainer } from 'react-toastify';
import InfoLoader from '../../components/loaders/InfoLoader';

import ProductSimulation from '../../components/UI/admin/ProductSimulation';
import AddStock from '../../components/UI/admin/AddStock';
import {
  listStock,
  deleteStockById,
  listStockForOrderPage,
} from '../../functions/stock';

function CollectionsPage() {
  const user = useSelector((state) => state.user);
  //======== STATES
  const [capsule, setCapsule] = useState(false);
  const [capsules, setCapsules] = useState([]);
  const [products, setProducts] = useState([]);
  const [editProductId, setEditProductId] = useState({});
  const [availableCapsule, setAvailableCapsule] = useState(false);
  const [isImageBigScaleOpen, setIsImageBigScaleOpen] = useState(false);
  const [imageBigScaleInfo, setImageBigScaleInfo] = useState();

  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pageSimulation, setPageSimulation] = useState(false);

  const [isAddProductStockModalOpen, setIsAddProductStockModalOpen] =
    useState(false);
  const [addProductStockId, setAddProductStockId] = useState({});
  const [stockList, setStockList] = useState([]);

  //functions
  const closeEditProductModal = () => setIsEditProductModalOpen(false);

  const closeAddProductModal = () => setIsAddProductStockModalOpen(false);

  const openEditProductModal = (pid, name) => {
    setIsEditProductModalOpen(true);
    setEditProductId({ id: pid, name: name });
  };

  const openAddStockProductModal = (pid, name) => {
    //console.log('PRODUCT LIST ADMIN pid, name', pid, name);
    setAddProductStockId({ id: pid, name: name });
    setIsAddProductStockModalOpen(true);
  };

  //======== EFFECTS
  useEffect(() => {
    setLoading(true);
    loadCapsules();
    loadProducts();
    loadStockList();
  }, []);

  const loadStockList = () => {
    //1.- List stock and depending on
    listStock(user.token)
      .then((res) => {
        setStockList(res.data.stock);
      })
      .catch((err) => console.log(err));
  };

  const loadCapsules = () => {
    listCapsules(user.token)
      .then((res) => {
        setCapsules(res.data.capsules);
        setCapsule('');
        setAvailableCapsule(res.data.capsules[0].available);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => console.log(err));
  };

  const loadProducts = () => {
    listProducts(user.token)
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((err) => console.log(err));
  };

  const changeCapsule = (cap) => {
    setAvailableCapsule(cap.available);
    setCapsule(cap);
  };

  const checkNumProd = (products) => {
    const p = products.filter((p) => p.pxCapsule === capsule);

    if (p.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleCheckboxChange = () => {
    setAvailableCapsule(!availableCapsule);

    let capsuleId = capsule;
    let isAvailable = !availableCapsule;

    //console.log('capsule', capsuleId, isAvailable);

    updateCapsuleAvailability(capsuleId, isAvailable, user.token)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const openImageBigScale = (product) => {
    setImageBigScaleInfo(product);
    setIsImageBigScaleOpen(true);
  };

  const closeImageBigScale = () => {
    setImageBigScaleInfo();
    setIsImageBigScaleOpen(false);
  };

  const handleImage = (product) => {
    openImageBigScale(product);
  };

  const changeOrderHandler = (number, pid) => {
    let orderNumber = number;
    // const capsuleId = products.find((p) => p._id === pid).pxCapsule;
    // const capsuleOrderNumber = capsules.find((c) => c._id === capsuleId).order;

    // console.log('capsuleId', capsuleId, capsuleOrderNumber, capsules);

    // if (capsuleId && capsuleOrderNumber) {
    //   orderNumber = orderNumber * capsuleOrderNumber * 100;
    // }

    // console.log('capsules->', capsules);
    updateProductOrder({ productId: pid, orderNumber: orderNumber }, user.token)
      .then((res) => {
        toast.success('Product order changed!');
        loadProducts();
      })
      .catch((err) => {
        toast.error('Could not change product order!');
      });
  };
  const getCapsuleName = () => {
    const c = capsules.find((c) => c._id === capsule);
    if (c) {
      return c.name;
    } else {
      return '';
    }
  };

  const onClickDeleteStock = (stockId) => {
    // Ask for confirmation
    const isConfirmed = window.confirm(
      `WARNING : Are you sure you want to delete this stock?`
    );

    const userEmail = user.email;

    // If the user confirms, proceed with the delete operation
    if (isConfirmed) {
      // Perform the delete operation (replace this with your actual logic)
      deleteStockById(stockId, userEmail, user.token)
        .then((res) => {
          //console.log('DATA --> ', res.data.cart);
          if (res.data.message === 'Stock deleted successfully') {
            toast.success('Stock deleted successfully');
            loadStockList();
          }

          if (res.data.message === 'No auth') {
            toast.error('You are not allowed to delete stock.');
          }
        })
        .catch((err) => {
          toast.error('Could not delete the stock. Please refresh the page.');
          console.log(err);
        });

      // Reload the page or update the UI as needed
      // window.location.reload();
    } else {
      // User chose not to delete, you can handle this case if needed
      console.log('Delete operation canceled');
    }
  };

  return (
    <AdminBodyStruct>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        show={isEditProductModalOpen}
        onCancel={closeEditProductModal}
        header={editProductId.name}
      >
        <EditProduct
          editProductId={editProductId.id}
          closeEditProductModal={closeEditProductModal}
          loadProducts={loadProducts}
        />
      </Modal>

      <Modal
        show={isAddProductStockModalOpen}
        onCancel={closeAddProductModal}
        header={'Available Stock'}
      >
        <AddStock
          addProductStockId={addProductStockId.id}
          closeAddProductModal={closeAddProductModal}
          capsules={capsules}
          loadStockList={loadStockList}
        />
      </Modal>
      {imageBigScaleInfo !== undefined && (
        <Modal
          show={isImageBigScaleOpen}
          onCancel={closeImageBigScale}
          className={'img-big-scale-modal'}
        >
          <ImageBigScale
            closeImageBigScale={closeImageBigScale}
            product={imageBigScaleInfo}
          />
        </Modal>
      )}
      <div className="flex-container-collections">
        <div className="flex-container-title-selector">
          <h2>
            Capsules <span>({`${capsules.length}`} capsules found)</span>
          </h2>
          <SelectorBRK onSelectGender={changeCapsule} options={capsules} />
        </div>

        {loading ? (
          <InfoLoader />
        ) : (
          capsules.length > 0 && (
            <div className="capsules-collection-list-box">
              <h2>AW24 Collection: </h2>
              <div className="capsules-collection-list">
                {capsules.map((c) => (
                  <h3 key={c._id}>{c.name} / </h3>
                ))}
              </div>
            </div>
          )
        )}

        {checkNumProd(products) ? (
          <React.Fragment>
            <hr className="horizontal-line" />

            <div className="flex-container-available-collection-number-of-products">
              <h2>
                {getCapsuleName()}
                <span>
                  {' '}
                  {`[${
                    products.filter((p) => p.pxCapsule === capsule).length
                  }]`}
                </span>
              </h2>
              {/* <div className="flex-container-available-collection">
              <input
                type="checkbox"
                className="checkbox"
                checked={availableCapsule}
                value={availableCapsule}
                onChange={handleCheckboxChange}
              />
              <p>
                {availableCapsule
                  ? 'This collection is currently available to clients'
                  : 'This collection is not available to clients yet.'}
              </p>
            </div> */}
              <div className="list-icon-box">
                <img
                  src={
                    !pageSimulation
                      ? '/images/list.svg'
                      : '/images/list_gray.svg'
                  }
                  alt="list"
                  onClick={() => setPageSimulation(false)}
                />
                <img
                  src={
                    pageSimulation
                      ? '/images/multiple.svg'
                      : '/images/multiple_gray.svg'
                  }
                  alt="icons"
                  onClick={() => setPageSimulation(true)}
                />
              </div>
              {/* {!pageSimulation ? (
                <p
                  style={{ textDecoration: 'underline' }}
                  onClick={() => setPageSimulation(true)}
                >
                  See page simulation
                </p>
              ) : (
                <p
                  style={{ textDecoration: 'underline' }}
                  onClick={() => setPageSimulation(false)}
                >
                  See list
                </p>
              )} */}
            </div>
          </React.Fragment>
        ) : (
          <div className="no-products-message">
            <p>(Products can be added to capsules using "+Add products")</p>
          </div>
        )}

        {products.length > 0 && !pageSimulation ? (
          <ProductListAdmin
            products={products}
            onClick={openEditProductModal}
            capId={capsule}
            handleImage={handleImage}
            changeOrderHandler={changeOrderHandler}
            onClickAddStock={openAddStockProductModal}
            stockList={stockList ? stockList : []}
            onClickDeleteStock={onClickDeleteStock}
          />
        ) : products.length > 0 && pageSimulation ? (
          <ProductSimulation capsule={capsule} products={products} />
        ) : null}

        {!loading && !checkNumProd(products) && capsule !== '' && (
          <h2>No products found in this capsule</h2>
        )}
      </div>
    </AdminBodyStruct>
  );
}

export default CollectionsPage;
