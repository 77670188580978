import React from 'react';

function StatisticsDashboard(props) {
  //calc total
  const calculateTotalAmount = (capsuleDict) => {
    const totalFromAllCapsules = Object.values(capsuleDict).reduce(
      (acc, capsule) => {
        return acc + capsule.total;
      },
      0
    );

    return totalFromAllCapsules;
  };

  const calculateTotalQty = (capsuleDict) => {
    const totalFromAllCapsules = Object.values(capsuleDict).reduce(
      (acc, capsule) => {
        return acc + capsule.totalQty;
      },
      0
    );

    return totalFromAllCapsules;
  };

  function getTotalQuantity(product) {
    if (!product || !product.pidOrder) {
      return 0; // Return 0 if the product or pidOrder is not provided or empty
    }

    return product.pidOrder.reduce((total, orderItem) => {
      return total + orderItem.qty;
    }, 0);
  }

  // Function to generate ordinal suffix
  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  return (
    <div className="stats-dash-box">
      <div className="stats-title-gender">
        <h2>{`Statistics for ${props.gender}`}</h2>
        <div className="stats-confirm-icons">
          <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
            * IVA not included
          </span>
          {/* <img src="/images/list.svg" alt="all" />
          <img src="/images/multiple.svg" alt="all" /> */}
        </div>
      </div>
      <div className="grid-container-stats-admin-dash">
        {/* ------------------------ FIRST CARD ------------------------------ */}
        <div
          className={
            props.gender === 'men'
              ? 'grid-item-stats-admin-dash card c-stats-1 men-color-dash'
              : 'grid-item-stats-admin-dash card c-stats-1 women-color-dash'
          }
        >
          <h2>Total</h2>
          <div className="c-stats-1-info">
            <div className="c-stats-total">
              <h1>{calculateTotalQty(props.pCapsules)}</h1>
              <span>{`+ ${calculateTotalAmount(props.pCapsules).toFixed(
                2
              )}€`}</span>
            </div>

            <p>{`${props.gender.toUpperCase()} COLLECTION`}</p>
          </div>
        </div>

        {/* ------------------------ SECOND CARD / BY COLLECTIONS---------------------- */}

        <div className="grid-item-stats-admin-dash card c-stats-3">
          <h2>By Collection</h2>
          {Object.entries(props.pCapsules).length > 0 &&
            Object.entries(props.pCapsules).map(
              ([capsuleName, capsuleData]) => {
                return (
                  <div className="c-stats-2-info" key={capsuleName}>
                    <p>{capsuleName}</p>
                    <h3>{capsuleData.totalQty}</h3>
                  </div>
                );
              }
            )}
        </div>

        {/* ------------------------ THIRD CARD / BY TYPE OF PRODUCTS ---------------------- */}

        <div className="grid-item-stats-admin-dash card c-stats-2">
          <h2 className="c-stats-2-title">By Category</h2>
          {Object.entries(props.products)
            .filter(([pTypeName, pData]) => pData.totalQty > 0) // Filter out categories with no quantity
            .sort(([, a], [, b]) => b.totalQty - a.totalQty) // Sort by totalQty in descending order
            .map(([pTypeName, pData]) => (
              <div className="c-stats-2-info" key={pTypeName}>
                <p
                  onClick={() => props.setCurrentType(pTypeName)}
                  style={{
                    cursor: 'pointer',
                    fontWeight:
                      props.currentType === pTypeName ? 'bold' : 'normal',
                  }}
                >
                  {pTypeName}
                </p>
                <h3>{pData.totalQty}</h3>
              </div>
            ))}
        </div>

        {/* ------------------------ FOURTH CARD / CURRENT TYPE OF PRODUCT ---------------------- */}

        <div className="grid-item-stats-admin-dash c-stats-4-part-1">
          <h1>{props.currentType}</h1>
          {props.products && props.products[props.currentType] && (
            <div className="c-stats-4-part-1-category-info">
              <h2>
                {props.products[props.currentType]['totalQty']}{' '}
                <span style={{ fontStyle: 'italic' }}>
                  {`+${props.products[props.currentType]['total'].toFixed(2)}€`}
                </span>
              </h2>
              <h3>
                {props.products[props.currentType]['total']
                  ? `${(
                      (props.products[props.currentType]['total'] /
                        calculateTotalAmount(props.products)) *
                      100
                    ).toFixed(0)}% OF MEN'S COLLECTION`
                  : 'No data'}
              </h3>
            </div>
          )}
        </div>

        {/* ------------------------ FITH CARD / SORTED PRODUCTS ---------------------- */}

        <div className="grid-item-stats-admin-dash c-stats-4">
          <div className="c-stats-4-products-box">
            {Object.entries(props.products).length > 0 &&
              Object.entries(props.products[props.currentType].products)
                .sort(([p1TypeName, p1Data], [p2TypeName, p2Data]) => {
                  return getTotalQuantity(p2Data) - getTotalQuantity(p1Data);
                })
                .map(([pTypeName, pData], index) => (
                  <div className="c-stats-4-product" key={pData.id}>
                    <div className="c-stats-4-p-info">
                      <img src={pData.image.url} alt="img" />
                      <div className="">
                        <h3>{pData.name}</h3>
                        <p>{pData.composition}</p>
                        <p>{`Ref ${pData.reference}`}</p>
                      </div>
                    </div>
                    <div className="c-stats-4-p-position">
                      <p>{getOrdinalSuffix(index + 1)}</p>
                      <p className="position-circle">{`${getTotalQuantity(
                        pData
                      )}`}</p>
                    </div>
                  </div>
                ))}
          </div>
        </div>

        {/* ------------------------ SISTH CARD / CREATIVITY ---------------------- */}

        <div
          className={
            props.gender === 'men'
              ? 'grid-item-stats-admin-dash card c-stats-6 men-color-dash'
              : 'grid-item-stats-admin-dash card c-stats-6 women-color-dash'
          }
        >
          <div className="c-stats-men">
            <h1>{`${props.gender.toUpperCase()}`}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatisticsDashboard;
