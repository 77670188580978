// import _ from 'lodash';
let initialState = [];

if (typeof window !== 'undefined') {
  if (localStorage.getItem('cart')) {
    initialState = JSON.parse(localStorage.getItem('cart'));
  } else {
    initialState = [];
  }
}

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_QTY':
      if (typeof window !== 'undefined') {
        let cart = [];
        //if cart in local storage get it
        if (localStorage.getItem('cart')) {
          cart = JSON.parse(localStorage.getItem('cart'));
        }

        const pid = action.payload.product._id;
        const size = action.payload.size;
        const product = action.payload.product;

        //console.log('ADD QTY SIZE', size, pid);

        //check if product already in cart
        const productCheck = cart.find((p) => p.product._id === pid);

        //if first qty to be added for that product
        if (!productCheck) {
          // console.log('PRODUCT NOT IN BAG YET');
          const newProduct = { product, pidOrder: [{ size, qty: 1 }] };
          cart.push(newProduct);
          //sort products beore pushing
          cart.sort((a, b) => {
            const orderA = a.product.order || 30; // Use 0 if order property is undefined
            const orderB = b.product.order || 30; // Use 0 if order property is undefined

            return orderA - orderB;
          });
          //first remove the cart completely(to avoid unexpected problems)
          localStorage.removeItem('cart');
          //create new cart in local storage
          localStorage.setItem('cart', JSON.stringify(cart));

          //UPDATE state in redux. Cart should have now all items except the one we want to remove
          return cart;
        } else {
          //check if size has already a qty or not
          // console.log(
          //   'PRODUCT FOUND IN BAG',
          //   productCheck.pidOrder.findIndex((s) => s.size === size)
          // );

          // const sizeCheck = product.pidOrder.find((s) => s.size === size);
          const sizeIndex = productCheck.pidOrder.findIndex(
            (s) => s.size === size
          );

          if (sizeIndex !== -1) {
            //Summ qty
            // console.log(
            //   'SIZE ALREADY EXISTS -> AT:',
            //   sizeIndex,
            //   ', SIZE:',
            //   productCheck.pidOrder[sizeIndex].size
            // );
            const newQty = productCheck.pidOrder[sizeIndex].qty + 1;
            //update the quantity
            productCheck.pidOrder[sizeIndex].qty = newQty;
            //first remove the cart completely(to avoid unexpected problems)
            localStorage.removeItem('cart');
            //sort products beore pushing
            cart.sort((a, b) => {
              const orderA = a.product.order || 30; // Use 0 if order property is undefined
              const orderB = b.product.order || 30; // Use 0 if order property is undefined

              return orderA - orderB;
            });
            //create new cart in local storage
            localStorage.setItem('cart', JSON.stringify(cart));

            return cart;
          } else {
            //console.log('Size not found, pidOrder', product.pidOrder);
            const updatedSizes = [...productCheck.pidOrder, { size, qty: 1 }];
            //console.log('Updated pidOrder', updatedSizes);
            const updatedProduct = {
              product: productCheck.product,
              pidOrder: updatedSizes,
            };
            //console.log(' updatedProduct', updatedProduct);
            cart = cart.filter((p) => p.product._id !== pid);
            //console.log(' cart', cart);
            cart.push(updatedProduct);

            //sort products beore pushing
            cart.sort((a, b) => {
              const orderA = a.product.order || 30; // Use 0 if order property is undefined
              const orderB = b.product.order || 30; // Use 0 if order property is undefined

              return orderA - orderB;
            });

            //first remove the cart completely(to avoid unexpected problems)
            localStorage.removeItem('cart');
            //create new cart in local storage
            localStorage.setItem('cart', JSON.stringify(cart));

            return cart;
          }
        }

        //return state;
      }

      break;

    case 'REDUCE_QTY':
      if (typeof window !== 'undefined') {
        let cart = [];
        //if cart in local storage get it
        if (localStorage.getItem('cart')) {
          cart = JSON.parse(localStorage.getItem('cart'));
        }

        const pid = action.payload.product._id;
        const size = action.payload.size;

        // console.log('REDUCE QTY SIZE', size, pid);
        //WE NEED TO SEE THAT IF THE PRODUCT WAS ADDED BUT HAS 0 QTY NOW, IT HAS TO BE REMOVED FROM CART

        //check if product already in cart
        const foundProduct = cart.find((p) => p.product._id === pid);

        if (!foundProduct) {
          return state;
        } else {
          //let see if there is already quantites for that size or it was just zero
          const indexSize = foundProduct.pidOrder.findIndex(
            (s) => s.size === size
          );

          if (
            indexSize !== -1 &&
            foundProduct.pidOrder[indexSize].qty !== 0 &&
            foundProduct.pidOrder[indexSize].qty > 0
          ) {
            // console.log('TIME TO REDUCE QTY!');
            const newQty = foundProduct.pidOrder[indexSize].qty - 1;
            //update the quantity
            foundProduct.pidOrder[indexSize].qty = newQty;

            //WE HAVE TO CHECK IF PRODUCT COMPLETELLY ZERO, TP REMOVE IT FROM CART
            const totalQuantities = foundProduct.pidOrder.reduce(
              (total, item) => {
                return total + item.qty;
              },
              0
            );
            // console.log('totalQuantities after removing qty', totalQuantities);
            if (totalQuantities === 0) {
              cart = cart.filter((p) => p.product._id !== pid);
              //first remove the cart completely(to avoid unexpected problems)
              localStorage.removeItem('cart');
              //create new cart in local storage
              localStorage.setItem('cart', JSON.stringify(cart));
            } else {
              //first remove the cart completely(to avoid unexpected problems)
              localStorage.removeItem('cart');
              //create new cart in local storage
              localStorage.setItem('cart', JSON.stringify(cart));
            }

            return cart;
          } else {
            //it means there is zero qty
            //console.log('ZERO QTYS!');
            return state;
          }
        }
      }

      break;

    case 'REMOVE_FROM_CART':
      if (typeof window !== 'undefined') {
        let cart = [];

        if (localStorage.getItem('cart')) {
          cart = JSON.parse(localStorage.getItem('cart'));
        }

        const pid = action.payload.product._id;
        // console.log('product passed, cart', product, cart);
        cart = cart.filter((p) => p.product._id !== pid);

        //first remove the cart completely(to avoid unexpected problems)
        localStorage.removeItem('cart');
        //create new cart in local storage
        localStorage.setItem('cart', JSON.stringify(cart));

        //UPDATE state in redux. Cart should have now all items except the one we want to remove
        return cart;
      }

      break;

    case 'LOAD_CART':
      if (typeof window !== 'undefined') {
        let cart = [];

        //first remove the cart completely(to avoid unexpected problems)
        localStorage.removeItem('cart');

        //Add products to cart
        cart = action.payload;

        //sort products
        cart.sort((a, b) => {
          const orderA = a.product.order || 30; // Use 0 if order property is undefined
          const orderB = b.product.order || 30; // Use 0 if order property is undefined

          return orderA - orderB;
        });

        //create new cart in local storage
        localStorage.setItem('cart', JSON.stringify(cart));

        //UPDATE state in redux. Cart should have now all items except the one we want to remove
        return cart;
      }

      break;

    case 'ADD_LOGO':
      if (typeof window !== 'undefined') {
        let cart = [];
        const pid = action.payload.productId;

        if (localStorage.getItem('cart')) {
          cart = JSON.parse(localStorage.getItem('cart'));
        }

        for (let i = 0; i < cart.length; i++) {
          if (cart[i].product._id === pid) {
            //console.log('cart[i]', cart[i]);
            cart[i] = {
              ...cart[i],
              logo: true,
            };
          }
        }

        localStorage.removeItem('cart');
        //create new cart in local storage
        localStorage.setItem('cart', JSON.stringify(cart));
        return cart;
      }

      break;

    case 'REMOVE_LOGO':
      if (typeof window !== 'undefined') {
        let cart = [];
        const pid = action.payload.productId;

        if (localStorage.getItem('cart')) {
          cart = JSON.parse(localStorage.getItem('cart'));
        }

        for (let i = 0; i < cart.length; i++) {
          if (cart[i].product._id === pid) {
            // console.log('cart[i]', cart[i]);
            cart[i] = {
              ...cart[i],
              logo: false,
            };
          }
        }

        localStorage.removeItem('cart');
        //create new cart in local storage
        localStorage.setItem('cart', JSON.stringify(cart));
        return cart;
      }

      // case 'REMOVE_FROM_CART_IN_VERIFICATION_DB':
      //   if (typeof window !== 'undefined') {
      //     let cart = [];

      //     if (localStorage.getItem('cart')) {
      //       cart = JSON.parse(localStorage.getItem('cart'));
      //     }

      //     let product = action.payload.product;

      //     cart.map((prod, index) => {
      //       if (
      //         product.id === prod._id &&
      //         product.color.trim() === prod.bag.colorToBag.trim() &&
      //         product.size.trim() === prod.bag.sizeToBag.trim()
      //       ) {
      //         cart.splice(index, 1); //takes the element from index position and removes 1 element
      //       }
      //     });

      //     //first remove the cart completely(to avoid unexpected problems)
      //     localStorage.removeItem('cart');
      //     //create new cart in local storage
      //     localStorage.setItem('cart', JSON.stringify(cart));

      //     //UPDATE state in redux. Cart should have now all items except the one we want to remove
      //     return cart;
      //   }

      // case 'REMOVE_FROM_CART_IN_CHECKOUT':
      //   if (typeof window !== 'undefined') {
      //     let cart = [];

      //     if (localStorage.getItem('cart')) {
      //       cart = JSON.parse(localStorage.getItem('cart'));
      //     }

      //     let product = action.payload.product;

      //     cart.map((prod, index) => {
      //       if (
      //         product.product === prod._id &&
      //         product.color.trim() === prod.bag.colorToBag.trim() &&
      //         product.size.trim() === prod.bag.sizeToBag.trim()
      //       ) {
      //         cart.splice(index, 1); //takes the element from index position and removes 1 element
      //       }
      //     });

      //     //first remove the cart completely(to avoid unexpected problems)
      //     localStorage.removeItem('cart');
      //     //create new cart in local storage
      //     localStorage.setItem('cart', JSON.stringify(cart));

      //     //UPDATE state in redux. Cart should have now all items except the one we want to remove
      //     return cart;
      //   }

      // case 'REMOVE_FROM_CART_IN_VERIFICATION_PRODUCT_NOT_FOUND_DB':
      //   if (typeof window !== 'undefined') {
      //     let cart = [];

      //     if (localStorage.getItem('cart')) {
      //       cart = JSON.parse(localStorage.getItem('cart'));
      //     }

      //     let product = action.payload.product;

      //     cart.map((prod, index) => {
      //       if (product.id === prod._id) {
      //         cart.splice(index, 1); //takes the element from index position and removes 1 element
      //       }
      //     });

      //     //first remove the cart completely(to avoid unexpected problems)
      //     localStorage.removeItem('cart');
      //     //create new cart in local storage
      //     localStorage.setItem('cart', JSON.stringify(cart));

      //     //UPDATE state in redux. Cart should have now all items except the one we want to remove
      //     return cart;
      //   }

      // case 'CHANGE_QUANTITY':
      //   //get payload
      //   let prod = action.payload.product;
      //   let num = action.payload.num;

      //   let pcis = prod.productColorImagesSizes;
      //   //find comb by color and colorToBag
      //   let p = pcis.find(
      //     (comb) => comb.color.trim() === prod.bag.colorToBag.trim()
      //   );
      //   //console.log('PRODUCT ON BAG p', p);
      //   //
      //   let t = p.sizes.find(
      //     (comb) => comb.size.trim() === prod.bag.sizeToBag.trim()
      //   );
      //   //console.log('PRODUCT ON BAG prod.bag.sizeToBag', prod.bag.sizeToBag);
      //   //

      //   let maxQty = t.quantity;
      //   //console.log('PRODUCT ON BAG qty', maxQty);
      //   // console.log('IN REDUCER---num', num);
      //   let count =
      //     prod.count === 1 && num === -1
      //       ? 1
      //       : prod.count === maxQty && num === 1
      //       ? maxQty
      //       : prod.count + num;

      //   // console.log('IN REDUCER---', count);

      //   if (typeof window !== 'undefined') {
      //     let cart = [];

      //     if (localStorage.getItem('cart')) {
      //       cart = JSON.parse(localStorage.getItem('cart'));
      //     }

      //     cart.map((product, index) => {
      //       if (
      //         product._id === prod._id &&
      //         product.bag.colorToBag.trim() === prod.bag.colorToBag.trim() &&
      //         product.bag.sizeToBag.trim() === prod.bag.sizeToBag.trim()
      //       ) {
      //         cart[index].count = count;
      //       }
      //     });

      //     //first remove the cart completely(to avoid unexpected problems)
      //     localStorage.removeItem('cart');
      //     //create new cart in local storage
      //     localStorage.setItem('cart', JSON.stringify(cart));

      //     // console.log('CART FROM REDUCER', cart)

      //     return cart;
      //   }

      // case 'CHANGE_QUANTITY_IN_VERIFICATION_DB':
      //   //get payload
      //   let pr = action.payload.product;
      //   let mq = action.payload.maxQty;

      //   if (typeof window !== 'undefined') {
      //     let cart = [];

      //     if (localStorage.getItem('cart')) {
      //       cart = JSON.parse(localStorage.getItem('cart'));
      //     }

      //     cart.map((product, index) => {
      //       if (
      //         product._id === pr._id &&
      //         product.bag.colorToBag.trim() === pr.bag.colorToBag.trim() &&
      //         product.bag.sizeToBag.trim() === pr.bag.sizeToBag.trim()
      //       ) {
      //         cart[index].count = mq;
      //       }
      //     });

      //     //first remove the cart completely(to avoid unexpected problems)
      //     localStorage.removeItem('cart');
      //     //create new cart in local storage
      //     localStorage.setItem('cart', JSON.stringify(cart));

      //     return cart;
      //   }

      break;

    case 'EMPTY_CART':
      // first remove the cart completely(to avoid unexpected problems)
      localStorage.removeItem('cart');
      return [];

    // case 'SET_NEW_CART':
    //   let cart = action.payload;

    //   if (typeof window !== 'undefined') {
    //     if (localStorage.getItem('cart')) {
    //       //first remove the cart completely(to avoid unexpected problems)
    //       localStorage.removeItem('cart');
    //     }

    //     if (cart.length > 0) {
    //       //create new cart in local storage
    //       localStorage.setItem('cart', JSON.stringify(cart));
    //     }

    //     return cart;
    //   }

    default:
      return state;
  }
};
