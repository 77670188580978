import SelectorBRK from '../../input/SelectorBRK';
import React, { useState } from 'react';
import InfoLoaderAdmin from '../../loaders/InfoLoaderAdmin';

function ChooseCollectionHeader(props) {
  //States
  //Functions

  return (
    <div className="choose-collection-admin-container">
      <h2>Choose a collection:</h2>
      <div>
        {props.isCollectionsLoaded ? (
          props.collections.map((collection) => (
            <li
              key={collection._id}
              onClick={() =>
                props.handleLoadingData(collection._id, collection.name)
              }
            >
              {collection.name}
            </li>
          ))
        ) : (
          <InfoLoaderAdmin />
        )}
      </div>
    </div>
  );
}

export default ChooseCollectionHeader;
