import React, { useState, useEffect } from 'react';
import HeaderBRK from '../components/HeaderBRKmobile';
import { getProductsForAvailableCollections } from '../functions/products';
import LoadingCapsulesImages from '../components/UI/LoadingCapsulesImage';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ClientMobileMenuDrawer from '../drawers/ClientMobileMenuDrawer';
import BackdropMobile from '../backdrop/BackdropMobile';
import Footer from '../components/Footer';
import { capFirstLetter } from '../functions/cap-first-letter';
import ImageBigScale from '../components/UI/ImageBigScale';
import Modal from '../modals/Modal';
import ProductsCatalogue from '../components/UI/ProductsCatalogue';

function ProductPage() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const params = useParams();
  const gender = params.gender;

  //state
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [capsules, setCapsules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [collectionId, setCollectionId] = useState('665045c0a8c41588948cae27');
  const [currentCapsule, setCurrentCapsule] = useState(0);

  const [imageBigScaleInfo, setImageBigScaleInfo] = useState();
  const [isImageBigScaleOpen, setIsImageBigScaleOpen] = useState(false);

  const [isIconMode, setIsIconMode] = useState(false);
  // AbortController
  const abortController = new AbortController();

  //effect
  useEffect(() => {
    window.scrollTo(0, 0);
    loadProducts();
    setCurrentCapsule(0);
    //in case they navigate from men to woman o viceversa , directly
    setIsMobileMenuOpen(false);
    setLoading(true); //it will be false when products are loaded

    // Cleanup function to abort fetching when component unmounts
    return () => abortController.abort();
  }, [gender]);

  //============ FUNCTIONS
  //============ FUNCTIONS
  const loadProducts = async () => {
    try {
      //   const resolveAfter3Sec = new Promise((resolve) =>
      //     setTimeout(resolve, 1000)
      //   );

      //   await toast.promise(resolveAfter3Sec, {
      //     pending: 'Loading products...',
      //     success: 'Products loaded successfully',
      //     error: 'Error loading products',
      //     hideProgressBar: true,
      //   });

      const response = await getProductsForAvailableCollections(
        collectionId,
        user.token,
        abortController.signal // Pass the signal to the function
      );

      // remove capsules with wrong gender
      const cap = response.data.capsuleProductList.filter(
        (c) => c.gender === gender
      );

      setCapsules(reorderCapsules(cap));

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      if (error.name === 'AbortError') {
        //console.log('Fetch aborted due to component unmount');
        navigate('/');
      } else {
        //console.error('Error loading products:', error);
        toast.error('Please refresh the page.');
        //navigate('/');
      }
    }
  };

  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const changeCurrentCapsule = (index) => {
    setCurrentCapsule(index);
  };

  const openImageBigScale = (product) => {
    setImageBigScaleInfo(product);
    setIsImageBigScaleOpen(true);
  };

  const closeImageBigScale = () => {
    setImageBigScaleInfo();
    setIsImageBigScaleOpen(false);
  };

  const handleImage = (product) => {
    openImageBigScale(product);
  };

  // Order capsules so essentials and accessories are at the end
  const reorderCapsules = (capsules) => {
    const essentials = [];
    const accessories = [];
    const others = [];

    capsules.forEach((item) => {
      const nameParts = item.capsuleName.split(' ');
      const lastPart = nameParts[nameParts.length - 1];
      if (lastPart === 'Essentials') {
        essentials.push(item);
      } else if (lastPart === 'Accessories') {
        accessories.push(item);
      } else {
        others.push(item);
      }
    });

    //
    //setCapsules([...others, ...essentials, ...accessories]);

    //console.log('ORDERED CAP', [...others, ...essentials, ...accessories]);

    return [...others, ...essentials, ...accessories];
  };

  // const orderedCapsules = reorderCapsules(cap);

  // setCapsules(orderedCapsules);

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {loading ? (
        <LoadingCapsulesImages />
      ) : (
        <React.Fragment>
          <HeaderBRK
            openMobileMenu={openMobileMenu}
            // classes={'dark-header'}
            // location={'product-page'}
          />
          <ClientMobileMenuDrawer show={isMobileMenuOpen} />
          {isMobileMenuOpen && <BackdropMobile onClick={closeMobileMenu} />}
          {imageBigScaleInfo !== undefined && (
            <Modal
              show={isImageBigScaleOpen}
              onCancel={closeImageBigScale}
              className={'img-big-scale-modal'}
              headerClass={'ibg-header'}
              footerClass={'ibg-header'}
              contentClass={'ibg-content'}
            >
              <ImageBigScale
                closeImageBigScale={closeImageBigScale}
                product={imageBigScaleInfo}
                gender={gender}
                currentCapsule={currentCapsule}
                capsules={capsules}
              />
            </Modal>
          )}
          <div className="products-page-box">
            <div className="exit-container">
              <div className="exit-2-container" onClick={() => navigate('/')}>
                <img
                  src="/images/exit-arrow.svg"
                  className="arrow-exit"
                  alt="arrow-exit"
                />
                <p>Go back</p>{' '}
                <span style={{ fontStyle: 'italic' }}>{`/ ${capFirstLetter(
                  gender
                )}`}</span>
              </div>

              <h2>SS25 Collection</h2>
            </div>
            <hr className="horizontal-line"></hr>
            <div className="gender-box">
              <div className="submenu-box">
                <h1>
                  {gender.toUpperCase()}
                  <span className="prod-lenght">{`[${capsules[currentCapsule].products.length}]`}</span>
                  {/* <span> (68 products)</span> */}
                </h1>
                <div className="capsules-box-container">
                  <div className="capsules-box">
                    {capsules &&
                      capsules.map((capsule, index) => {
                        return (
                          capsule.gender === gender && (
                            <h2
                              key={capsule.capsuleId}
                              onClick={() => changeCurrentCapsule(index)}
                              className={
                                currentCapsule === index
                                  ? 'capsule-displayed'
                                  : 'capsule-not-displayed'
                              }
                            >
                              {capsule.capsuleName}
                            </h2>
                          )
                        );
                      })}
                  </div>
                </div>
              </div>
              {/* <hr className="horizontal-line"></hr> */}

              <div className="list-icon-box">
                <img
                  src={
                    !isIconMode ? '/images/list.svg' : '/images/list_gray.svg'
                  }
                  alt="list"
                  onClick={() => setIsIconMode(false)}
                />
                <img
                  src={
                    isIconMode
                      ? '/images/multiple.svg'
                      : '/images/multiple_gray.svg'
                  }
                  alt="icons"
                  onClick={() => setIsIconMode(true)}
                />
              </div>

              {isIconMode ? (
                <ProductsCatalogue
                  capsules={capsules}
                  currentCapsule={currentCapsule}
                  handleImage={handleImage}
                />
              ) : (
                <React.Fragment>
                  {capsules && (
                    <div>
                      <div className="products-box-flex-container">
                        {capsules[currentCapsule].products.map((product) => (
                          <div
                            className="product-box-flex-container"
                            key={product._id}
                          >
                            {product.image.url ? (
                              <img
                                onClick={() => handleImage(product)}
                                src={`${product.image.url}`}
                                alt="product-img"
                                className="product-page-img"
                              />
                            ) : (
                              <img
                                onClick={() => handleImage(product)}
                                src="/images/not-found-image.png"
                                alt="product-img"
                                className="product-page-img"
                              />
                            )}

                            <div className="info-product-item">
                              <div className="name-ref">
                                <h2>{`${capFirstLetter(product.name)}`}</h2>
                                <h2>{`${product.price} €`}</h2>
                              </div>
                              <div className="name-ref">
                                <p>{`Ref. ${product.reference}`}</p>
                                <p>{`srp. ${product.srp} €`}</p>
                              </div>
                              {/* <div className="name-ref">
                            <p className="prod-composition">
                              {product.composition}
                            </p>
                          </div> */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>

          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ProductPage;
