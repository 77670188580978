import React, { useState, useEffect } from 'react';
import HeaderBRK from '../components/HeaderBRKmobile';
import { capFirstLetter } from '../functions/cap-first-letter';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/Footer';

import ClientMobileMenuDrawer from '../drawers/ClientMobileMenuDrawer';
import BackdropMobile from '../backdrop/BackdropMobile';

function NotFoundScreen() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  //effect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <React.Fragment>
      <HeaderBRK
        openMobileMenu={openMobileMenu}
        // classes={'dark-header'}
        // location={'product-page'}
      />
      <ClientMobileMenuDrawer show={isMobileMenuOpen} />
      {isMobileMenuOpen && <BackdropMobile onClick={closeMobileMenu} />}
      <div className="terms-box">
        <div className="exit-container">
          <div className="exit-2-container" onClick={() => navigate('/')}>
            <img
              src="/images/exit-arrow.svg"
              className="arrow-exit"
              alt="arrow-exit"
            />
            <p>Go back</p>{' '}
          </div>

          <h2>AW24 Collection</h2>
        </div>
        <hr className="horizontal-line"></hr>
        <div className="terms-body">
          <h1>Page not found</h1>
          <h2>Sorry for the inconvenience</h2>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default NotFoundScreen;
