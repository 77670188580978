import React from 'react';

function InfoLoader() {
  return (
    <div className="loading-placeholder-box">
      <div className="loading-placeholder-b1">
        <div className="loading-placeholder-title"></div>
        <div className="loading-placeholder-sentence-1"></div>
        <div className="loading-placeholder-sentence-2"></div>
      </div>
      <div>
        <div className="loading-placeholder-btn"></div>
      </div>
    </div>
  );
}

export default InfoLoader;
