import React, { useEffect, useState } from 'react';
import AdminBodyStruct from './AdminBodyStruct';
import HeaderDashboard from '../../components/UI/admin/dashboard-page/HeaderDashboard';
import {
  getOrdersForStats,
  getIndividualOrdersForStats,
} from '../../functions/orders';
import { useSelector } from 'react-redux';
import { listCapsules } from '../../functions/capsules';
import {
  checkSizes,
  checkSizesAdmin,
  checkSizesProductQtyDetails,
} from '../../functions/check-sizes';
import InfoLoaderAdmin from '../../components/loaders/InfoLoaderAdmin';
import ButtonInfo from '../../components/buttons/ButtonInfo';
import ProductsDrawer from '../../drawers/ProductsDrawer';
import Backdrop from '../../backdrop/Backdrop';
import { listCollections } from '../../functions/collections';
import ChooseCollectionHeader from '../../components/UI/admin/ChooseCollectionHeader';
import * as XLSX from 'xlsx/xlsx.mjs';

function AnalyticsPage() {
  const user = useSelector((state) => state.user);
  const [pMen, setPMen] = useState({});
  const [pWomen, setPWomen] = useState({});
  const [capsules, setCapsules] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [fabricsTotals, setFabricsTotals] = useState([]);
  const [indPidOrderList, setIndPidOrderList] = useState([]);
  const [gender, setGender] = useState('men');
  const [loading, setLoading] = useState(false);
  const [orderRefs, setOrderRefs] = useState([]);
  const [sortByProducts, setSortByProducts] = useState(true);
  const [sortByTotalFabrics, setSortByTotalFabrics] = useState(false);
  const [showProductQtyDetails, setShowProductQtyDetails] = useState(false);
  const [productQtyDet, setProductQtyDet] = useState([]);
  const [individualOrd, setIndividualOrd] = useState([]);
  const [collections, setCollections] = useState([]);
  const [isCollectionsLoaded, setIsCollectionsLoaded] = useState(false);
  const [isCollectionChoosen, setIsCollectionChoosen] = useState(false);
  const [currentCollection, setCurrentCollection] = useState('');

  //console.log('PMEN******', pMen);

  //effect
  useEffect(() => {
    //setLoading(true);
    //loadStats();
    loadCapsules();
    loadCollections();
  }, []);

  const loadCollections = async () => {
    try {
      const res = await listCollections(user.token);
      setCollections(res.data.collections);
      console.log('collections--->', res.data.collections);
      setIsCollectionsLoaded(true);
    } catch (err) {
      console.error('Could not load collections in DASHBOARD PAGE', err);
    } finally {
      setLoading(false); //not needed
    }
  };

  const checkCollectionName = (pxCollection) => {
    if (collections.length > 0) {
      const collection = collections.find(
        (c) => String(pxCollection) === String(c._id)
      );

      if (collection) {
        return collection.name;
      } else {
        return 'Collection name not found';
      }
    }
    return 'Collection name not found';
  };

  {
    /*NEW LOAD STATS FUNCTION*/
  }
  const loadStats = async (id, collectionName) => {
    try {
      setLoading(true);
      setIsCollectionChoosen(true);
      setCurrentCollection(collectionName);

      const res = await getOrdersForStats({ collectionId: id }, user.token);

      // setPMen(res.data.mendict);
      // setPWomen(res.data.womendict);
      // setPCapsulesMen(res.data.capsulesmendict);
      // setPCapsulesWomen(res.data.capsuleswomendict);

      setPMen(res.data.mendict);
      setPWomen(res.data.womendict);
      setOrderRefs(res.data.orderRefs);
      setFabrics(res.data.fabricsdict);
      setFabricsTotals(res.data.fabricstotalsdict);

      getIndividualOrdersForStats({ collectionId: id }, user.token)
        .then((res) => {
          setIndPidOrderList(res.data.indpidorderdict);
          setLoading(false);
        })
        .catch((err) => console.log(err));

      // if (res.data.mendict && Object.keys(res.data.mendict).length > 0) {
      //   setCurrentTypeMen(Object.keys(res.data.mendict)[0]);
      // } else {
      //   setCurrentTypeMen(null);
      // }

      // if (res.data.womendict && Object.keys(res.data.womendict).length > 0) {
      //   setCurrentTypeWomen(Object.keys(res.data.womendict)[0]);
      // } else {
      //   setCurrentTypeWomen(null);
      // }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  {
    /*END OF NEW LOAD STATS FUNCTIONS*/
  }

  // const loadStats = () => {
  //   getOrdersForStats(user.token)
  //     .then((res) => {
  //       setPMen(res.data.mendict);
  //       setPWomen(res.data.womendict);
  //       setOrderRefs(res.data.orderRefs);
  //       setFabrics(res.data.fabricsdict);
  //       setFabricsTotals(res.data.fabricstotalsdict);

  //       // console.log('FABRICS->', res.data.fabricstotalsdict);
  //       // console.log('STATS men data->', res.data.mendict);
  //       // console.log('STATS women data->', res.data.womendict);
  //     })
  //     .catch((err) => console.log(err));

  //   getIndividualOrdersForStats(user.token)
  //     .then((res) => {
  //       setIndPidOrderList(res.data.indpidorderdict);
  //       setLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  // };

  //prepare capsules
  const loadCapsules = () => {
    listCapsules(user.token)
      .then((res) => {
        setCapsules(res.data.capsules);
      })
      .catch((err) => console.log(err));
  };

  const handleLoadingData = (collectionId, collectionName) => {
    loadStats(collectionId, collectionName);
  };

  function getTotalQuantity(product) {
    if (!product || !product.pidOrder) {
      return 0; // Return 0 if the product or pidOrder is not provided or empty
    }

    return product.pidOrder.reduce((total, orderItem) => {
      return total + orderItem.qty;
    }, 0);
  }

  const closeProductQtyDetails = () => {
    setShowProductQtyDetails(false);
    setProductQtyDet([]);
  };

  const openProductQtyDetails = (product) => {
    //  console.log('porduct-->', product);
    //find product in dict
    let prd = indPidOrderList[product.id];
    // console.log('prd', prd.individual);

    if (prd) {
      setProductQtyDet(product);
      setShowProductQtyDetails(true);
      setIndividualOrd(prd.individual);
    }
  };

  const renderTable = (typeName, typeData) => {
    // Sort products based on total quantity
    const sortedProducts = typeData.products.slice().sort((a, b) => {
      return getTotalQuantity(b) - getTotalQuantity(a);
    });

    return (
      <div key={typeName} className="production-admin-table">
        <table>
          <caption>{typeName}</caption>
          <thead>
            <tr>
              <th>Product description</th>
              {checkSizesAdmin(gender, typeName).map((size) => (
                <th key={size}>{size}</th>
              ))}
              <th>Total</th>
              {/* <th>S</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
              <th>XXL</th>
              <th>Total</th> */}
            </tr>
          </thead>
          <tbody>
            {sortedProducts.map((product) => (
              <tr key={product.id}>
                <td data-cell="Product description">
                  <div className="production-table-product-cell-box">
                    <img
                      src={product.image.url}
                      alt="prod"
                      style={{ height: '70px' }}
                    />
                    <div className="production-table-product-cell">
                      <h3>
                        {product.name} / {product.color}
                      </h3>
                      <p>{product.composition}</p>
                      <p>{`Ref. ${product.reference}`}</p>
                      <h2>Fabric: {product.fabric}</h2>
                    </div>
                  </div>
                </td>

                {checkSizes(product, capsules).map((size) => (
                  <td key={size} data-cell={`Size ${size}`}>
                    {product.pidOrder.reduce(
                      (acc, order) =>
                        order.size === size ? acc + order.qty : acc,
                      0
                    )}
                  </td>
                ))}

                <td
                  data-cell="Total"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => openProductQtyDetails(product)}
                >
                  {getTotalQuantity(product)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTableFabrics = (typeName, typeData) => {
    // Sort products based on total quantity
    const sortedProducts = typeData.products.slice().sort((a, b) => {
      return getTotalQuantity(b) - getTotalQuantity(a);
    });

    return (
      <div key={typeName} className="production-admin-table">
        <table>
          <caption>{typeName}</caption>
          <thead>
            <tr>
              <th>Images</th>

              <th>Name</th>

              <th>Quantities</th>

              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {sortedProducts.map((product) => (
              <tr key={product.id}>
                <td data-cell="Images">
                  <div className="production-table-product-cell-box">
                    {product.image.url && (
                      <img
                        src={product.image.url}
                        alt={`prod-${product.id}`}
                        style={{ width: '30px', marginRight: '5px' }}
                      />
                    )}
                  </div>
                </td>

                <td data-cell="Name">{product.name}</td>

                <td data-cell="Quantities">
                  {getTotalQuantity(product)}
                  {/* Assuming this is the total quantity for the fabric */}
                </td>
                <td data-cell="Total">{typeData.totalQty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTableProductQtyDetails = (productDet, individualOrd) => {
    return (
      <div className="production-admin-table">
        <table>
          <caption>{productDet.name}</caption>
          <thead>
            <tr>
              <th>Name</th>

              {checkSizesProductQtyDetails(gender, productDet.type).map(
                (size) => (
                  <th key={size}>{size}</th>
                )
              )}

              {/* <th>Total</th> */}
            </tr>
          </thead>
          <tbody>
            {individualOrd.map((individualOrd, index) => (
              <tr key={index}>
                {/* <td data-cell="Images">
                  <div className="production-table-product-cell-box">
                    {product.image.url && (
                      <img
                        src={product.image.url}
                        alt={`prod-${product.id}`}
                        style={{ width: '30px', marginRight: '5px' }}
                      />
                    )}
                  </div>
                </td> */}

                <td data-cell="Name">{individualOrd.client}</td>

                {checkSizesProductQtyDetails(gender, productDet.type).map(
                  (size) => {
                    const matchingOrderItem = individualOrd.indPidOrder.find(
                      (orderItem) => orderItem.size === size
                    );
                    return (
                      <td key={size} data-cell={`Size ${size}`}>
                        {matchingOrderItem ? matchingOrderItem.qty : 0}
                      </td>
                    );
                  }
                )}

                {/* <td data-cell="Total">//</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTableFabricsTotals = (typeName, typeData) => {
    return (
      <div key={typeName} className="production-admin-table">
        <table>
          <caption>{typeName}</caption>
          <thead>
            <tr>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-cell="Total">{typeData.totalQty}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getOrderName = (ref, name) => {
    if (ref && name) {
      const orderNumber = ref.slice(-6); // Assuming the order ID structure
      return `#${orderNumber}/${name}`;
    }
    return '';
  };

  const ProductQtyDetails = (props) => {
    return renderTableProductQtyDetails(
      props.productQtyDet,
      props.individualOrd
    );
  };

  // const downloadProductsDataSortByProducts = () => {
  //   function transformData(data) {
  //     const transformedData = {};

  //     // Iterate over each key in the data dictionary
  //     for (const key in data) {
  //       // Check if the key is a direct property of the object (not inherited)
  //       if (Object.prototype.hasOwnProperty.call(data, key)) {
  //         const products = data[key].products;

  //         // Iterate through each product of the current key
  //         products.forEach((product) => {
  //           const name = product.name;

  //           // Iterate through pidOrder to extract size and quantity
  //           product.pidOrder.forEach((item) => {
  //             const size = item.size;
  //             const qty = item.qty;

  //             // Construct the key for the transformedData object
  //             const key = `${name} - size ${size}`;

  //             // Add product name and quantity to transformedData
  //             transformedData[key] = qty.toString();
  //           });
  //         });
  //       }
  //     }
  //     console.log('transformedData*****', transformedData);
  //     //return transformedData;
  //   }

  //   if (Object.keys(pMen).length > 0) {
  //     const transformedData = transformData(pMen);
  //     //prepare the products
  //     // let wb = XLSX.utils.book_new();
  //     // let ws = XLSX.utils.json_to_sheet(transformedData);

  //     // XLSX.utils.book_append_sheet(wb, ws, 'data');

  //     // let workBookName = `order_details_to_bot.xlsx`;
  //     // XLSX.writeFile(wb, workBookName);
  //   }
  // };

  // ESTA ES LA FUNCION QUE TRANSFORMA LOS DATOS Y PREPARA PARA EXPORTAR EXCEL
  // CON DATOS PARA PRODUCCION
  const getTableDataAsObject = () => {
    // Helper function to get total quantity of a product
    function getTotalQuantity(product) {
      if (!product || !product.pidOrder) {
        return 0; // Return 0 if the product or pidOrder is not provided or empty
      }
      return product.pidOrder.reduce((total, orderItem) => {
        return total + orderItem.qty;
      }, 0);
    }

    // Helper function to transform data into the required format
    function transformData(data) {
      const transformedData = [];

      // Iterate over each key in the data dictionary (e.g., 't-shirts', 'pants')
      for (const typeName in data) {
        if (Object.prototype.hasOwnProperty.call(data, typeName)) {
          const products = data[typeName].products;

          // Sort products based on total quantity
          const sortedProducts = products.slice().sort((a, b) => {
            return getTotalQuantity(b) - getTotalQuantity(a);
          });

          // Iterate through each product and transform the data
          sortedProducts.forEach((product) => {
            const productData = {
              'Product Description': `${product.name} / ${product.color}`,
              Fabric: product.fabric,
              'Total Quantity': getTotalQuantity(product),
            };

            // Add sizes and corresponding quantities
            checkSizes(product, capsules).forEach((size) => {
              productData[`Size ${size}`] = product.pidOrder.reduce(
                (acc, order) => (order.size === size ? acc + order.qty : acc),
                0
              );
            });

            transformedData.push(productData);
          });
        }
      }
      return transformedData;
    }

    // Transform data for both men's and women's products
    const transformedDataMen = transformData(pMen);
    const transformedDataWomen = transformData(pWomen);

    // Merge both transformed data arrays
    const mergedData = [...transformedDataMen, ...transformedDataWomen];

    // Create a new workbook and worksheet
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(mergedData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Products Data');

    // Define the workbook name
    let workBookName = 'products_data.xlsx';

    // Write the workbook to a file
    XLSX.writeFile(wb, workBookName);
  };

  return (
    <AdminBodyStruct>
      {showProductQtyDetails && <Backdrop onClick={closeProductQtyDetails} />}
      {showProductQtyDetails && (
        <ProductsDrawer show={showProductQtyDetails}>
          <ProductQtyDetails
            productQtyDet={productQtyDet}
            individualOrd={individualOrd}
          />
          {/* <OrderDetails
            order={individualOrder}
            orderDet={individualOrderDetails}
            closeProductQtyDetails={closeProductQtyDetails}
            capsules={capsules}
          /> */}
        </ProductsDrawer>
      )}
      <div className="dashboard-page-box">
        <HeaderDashboard
          userName={user.name && user.name}
          text={
            'This is your production area, providing you with a comprehensive overview of a specific collection at a glance.'
          }
        />
        <div className="dashboard-page-container-choose-collection">
          {isCollectionChoosen && !loading && (
            <React.Fragment>
              <h1>Showing production for: {currentCollection}</h1>
              <div>
                <p
                  className="download-sort-products"
                  onClick={() => getTableDataAsObject()}
                >
                  Download data (sort by products)
                </p>
              </div>
            </React.Fragment>
          )}
          <ChooseCollectionHeader
            isCollectionsLoaded={isCollectionsLoaded}
            collections={collections}
            handleLoadingData={handleLoadingData}
          />
        </div>
        {loading && <InfoLoaderAdmin />}
        {isCollectionChoosen && !loading && (
          <React.Fragment>
            <div className="production-admin-sort-by">
              <h2
                className={
                  sortByProducts
                    ? 'sorted-by-selected'
                    : 'sorted-by-not-selected'
                }
                onClick={() => setSortByProducts(true)}
              >
                Sort by products
              </h2>

              <h2
                onClick={() => setSortByProducts(false)}
                className={
                  !sortByProducts
                    ? 'sorted-by-selected'
                    : 'sorted-by-not-selected'
                }
              >
                Sort by fabrics
              </h2>
            </div>
            <div className="production-admin-proshops-box">
              <p>
                These tables show the sum of all orders (both "In progress" and
                "Confirmed"). Below are shown the orders used for the
                caculations:
              </p>
              <div className="production-admin-proshops">
                {Object.entries(orderRefs).map(([key, value], index) => {
                  return (
                    <ButtonInfo
                      key={index}
                      name={getOrderName(key, value)} //
                      classes={'btn-info-production-orders'}
                    />
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        )}

        {sortByProducts && isCollectionChoosen && !loading ? (
          <div className="production-admin-gender-box">
            <h1>{gender.toUpperCase()}</h1>
            <div className="stats-confirm-icons">
              <p
                onClick={() => setGender('men')}
                style={{ fontWeight: gender === 'men' ? 'bold' : 'lighter' }}
              >
                MEN
              </p>

              <p> / </p>
              <p
                onClick={() => setGender('women')}
                style={{ fontWeight: gender === 'women' ? 'bold' : 'lighter' }}
              >
                WOMEN
              </p>

              {/* <img src="/images/list.svg" alt="all" />
            <img src="/images/multiple.svg" alt="all" /> */}
            </div>
          </div>
        ) : isCollectionChoosen && !loading ? (
          <div className="production-admin-gender-box">
            <h1>FABRICS</h1>
            <div className="stats-confirm-icons">
              <p
                onClick={() => setSortByTotalFabrics(false)}
                style={{ fontWeight: !sortByTotalFabrics ? 'bold' : 'lighter' }}
              >
                BY COLOR
              </p>

              <p> / </p>
              <p
                onClick={() => setSortByTotalFabrics(true)}
                style={{ fontWeight: sortByTotalFabrics ? 'bold' : 'lighter' }}
              >
                TOTALS
              </p>

              {/* <img src="/images/list.svg" alt="all" />
            <img src="/images/multiple.svg" alt="all" /> */}
            </div>
          </div>
        ) : null}

        {/* <div>
          {loading ? (
            <InfoLoaderAdmin />
          ) : sortByProducts ? (
            <div>
              {gender === 'men'
                ? Object.entries(pMen).map(([typeName, typeData]) =>
                    renderTable(typeName, typeData)
                  )
                : Object.entries(pWomen).map(([typeName, typeData]) =>
                    renderTable(typeName, typeData)
                  )}
            </div>
          ) : (
            <>
              {!sortByTotalFabrics ? (
                <div>
                  {Object.keys(fabrics)
                    .sort()
                    .map((typeName) =>
                      renderTableFabrics(typeName, fabrics[typeName])
                    )}
                </div>
              ) : (
                <div>
                  {Object.keys(fabricsTotals)
                    .sort()
                    .map((typeName) =>
                      renderTableFabricsTotals(
                        typeName,
                        fabricsTotals[typeName]
                      )
                    )}
                </div>
              )}
            </>
          )}
        </div> */}
        <div>
          {isCollectionChoosen && sortByProducts && !loading ? (
            <div>
              {gender === 'men'
                ? Object.entries(pMen).map(([typeName, typeData]) =>
                    renderTable(typeName, typeData)
                  )
                : Object.entries(pWomen).map(([typeName, typeData]) =>
                    renderTable(typeName, typeData)
                  )}
            </div>
          ) : isCollectionChoosen && !loading ? (
            <>
              {!sortByTotalFabrics ? (
                <div>
                  {Object.keys(fabrics)
                    .sort()
                    .map((typeName) =>
                      renderTableFabrics(typeName, fabrics[typeName])
                    )}
                </div>
              ) : (
                <div>
                  {Object.keys(fabricsTotals)
                    .sort()
                    .map((typeName) =>
                      renderTableFabricsTotals(
                        typeName,
                        fabricsTotals[typeName]
                      )
                    )}
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </AdminBodyStruct>
  );
}

export default AnalyticsPage;
