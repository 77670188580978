import React, { useState, useEffect } from 'react';
import ButtonBRK from '../../buttons/ButtonBRK';
import InputBRK from '../../input/InputBRK';
import { updateProduct, getProductById } from '../../../functions/products';
import { updateProductionValidation } from '../../../validation/products-validation';
import { useSelector } from 'react-redux';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { createClient } from '../../../functions/clients';
import { toast, ToastContainer } from 'react-toastify';

const initialStateProduct = {
  //pxCapsule and reference should not change
  name: '',
  email: '',
  phoneNumber: '',
  address: '',
};

function AddClient(props) {
  const auth = getAuth();
  const user = useSelector((state) => state.user);
  //state
  const [client, setClient] = useState(initialStateProduct);
  const [image, setImage] = useState([]);

  console.log('client', client);

  //setEditProductId('652a3dae0a6876a0a60c72c9');

  //useffect
  useEffect(() => {
    //loadClient();
  }, []);

  //   const handleSubmit = async (e) => {
  //     //reset
  //     // setErrorFormatEmail(false)
  //     //check form
  //     //let formCheck = checkForm();

  //     // if (formCheck === 'formOk') {
  //     //   setIsFormCorrect(true);
  //     // } else {
  //     //   return;
  //     // }

  //     //check accepted terms
  //     // let termCheck = checkAcceptedTerms();
  //     // if (termCheck === 'termsNotOk') {
  //     //   return;
  //     // }

  //     e.preventDefault();

  //     const config = {
  //       //url: process.env.REACT_APP_REGISTER_REDIRECT_URL,
  //       url: 'http://localhost:3000/complete',
  //       handleCodeInApp: true,
  //     };

  //     //define function for sending emailLink in case user does not exist
  //     const sendEmailToUser = async () => {
  //       //Code.2.1.0 - Register
  //       await sendSignInLinkToEmail(auth, client.email, config)
  //         .then(() => {
  //           const emailForRegistration = 'emailForRegistration';
  //           const existingEmail =
  //             window.localStorage.getItem(emailForRegistration);
  //           if (existingEmail) {
  //             // If the item already exists, remove it
  //             window.localStorage.removeItem(emailForRegistration);
  //           }

  //           window.localStorage.setItem(emailForRegistration, client.email);

  //           //   setClient('');
  //           //   setName('');
  //           //   setSurname('');
  //           //   setIsAcceptedTerms(true);
  //           //   setIsFormCorrect(true);
  //           //   setEmailSetInfoToComplete(true);
  //         })
  //         .catch((error) => {
  //           console.log('error', error);
  //           //   setErrorFormatEmail(true)
  //           // ...
  //         });
  //     };

  //     ///////we are not checking if user exists, we let them register
  //     sendEmailToUser();
  //   };

  //functions
  //   const loadClient = () => {
  //     //console.log('editProductId', editProductId);
  //     getClientById(props.clientId)
  //       .then((res) => {
  //         setClient({
  //           name: res.data.client.name,
  //           email: res.data.client.email,
  //           phoneNumber: res.data.client.phoneNumber,
  //           address: res.data.client.address,
  //         });
  //         console.log('CLIENT FROM DB TO UPDATE->', res.data.client);
  //       })
  //       .catch((err) => console.log(err));
  //   };

  //   const updateClientDB = () => {
  //     //validation before updating
  //     const isValidated = updateProductionValidation(client);

  //     if (isValidated) {
  //       updateProduct(client, props.editProductId)
  //         .then((res) => {
  //           console.log('UPDATED PRODUCT->', res.data.product);
  //           console.log('UPDATED PRODUCT MSG->', res.data.message);
  //           props.closeEditProductModal();
  //           props.loadProducts();
  //         })
  //         .catch((err) => console.log(err));
  //     }
  //   };

  const changeClientHandler = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    // if (updatedName === 'price' || updatedName === 'srp') {
    //   updatedValue = parseFloat(updatedValue);
    // }

    setClient({ ...client, [name]: value });
  };

  //   const createNewClient = () => {
  //     console.log('client', client);
  //     if (client.email === '' || client.name === '') {
  //       toast.error('Please introduce email and name');
  //       return;
  //     }

  //     createClient({ client }, user.token)
  //       .then((res) => {
  //         console.log(res);
  //         toast.success('Client created!');
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         if (
  //           err.response.data.message &&
  //           err.response.data.message ===
  //             'User with same email or name already exists'
  //         ) {
  //           toast.error('User with same email or name already exists');
  //         }
  //       });
  //   };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d{9}$/; // Assumes a 10-digit phone number format
    return regex.test(phoneNumber);
  };

  const createNewClient = () => {
    if (
      !validateEmail(client.email) ||
      client.name === ''
      //   ||
      //   client.address === '' ||
      //   !validatePhoneNumber(client.phoneNumber)
    ) {
      if (!validateEmail(client.email)) {
        toast.error('Please enter a valid email address');
      }
      if (client.name === '') {
        toast.error('Please enter a name');
      }
      //   if (client.address === '') {
      //     toast.error('Please enter an address');
      //   }
      //   if (!validatePhoneNumber(client.phoneNumber)) {
      //     toast.error('Please enter a valid phone number');
      //   }
      return;
    }

    // If all fields are valid, proceed to create the client
    createClient({ client }, user.token)
      .then((res) => {
        console.log(res);
        toast.success('Client created!');
        setTimeout(() => {
          props.closeAddClientModalhandleClick();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        if (
          err.response.data.message &&
          err.response.data.message ===
            'User with same email or name already exists'
        ) {
          toast.error('User with same email or name already exists');
        }
      });
  };

  return (
    <div className="flex-container-edit-product">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex-container-edit-product-input">
        {/* <img
          alt="Image"
          src={`/images/${client.name}.svg`}
          style={{ width: '100%' }}
        /> */}
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Name</h2>
        <InputBRK
          placeholder={'Name'}
          classes={'edit-product-input'}
          value={client.name}
          name={'name'}
          onChange={changeClientHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Email</h2>
        <InputBRK
          placeholder={'Email'}
          classes={'edit-product-input'}
          value={client.email}
          name={'email'}
          onChange={changeClientHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Phone number</h2>
        <InputBRK
          placeholder={'Phone number'}
          classes={'edit-product-input'}
          value={client.phoneNumber}
          name={'phoneNumber'}
          onChange={changeClientHandler}
        />
      </div>
      <div className="flex-container-edit-product-input">
        <h2>Address</h2>
        <InputBRK
          placeholder={'Address'}
          classes={'edit-product-input'}
          value={client.address}
          name={'address'}
          onChange={changeClientHandler}
        />
      </div>

      <ButtonBRK
        name="Save client"
        classes={'btn-accept-updated-product'}
        onClick={createNewClient}
      />
    </div>
  );
}

export default AddClient;
