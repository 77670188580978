import React, { useEffect, useState } from 'react';
import AdminBodyStruct from './AdminBodyStruct';
import SelectorBRK from '../../components/input/SelectorBRK';
import ProductListAdmin from '../../components/UI/ProductListAdmin';
import Modal from '../../modals/Modal';
import EditProduct from '../../components/UI/admin/EditProduct';
import {
  listCapsules,
  updateCapsuleAvailability,
} from '../../functions/capsules';
import { listProducts } from '../../functions/products';
import ImageBigScale from '../../components/UI/ImageBigScale';
import { useSelector } from 'react-redux';
import {
  updateProductOrder,
  updateProductCapsule,
} from '../../functions/products';
import { listStock, deleteStockById } from '../../functions/stock';
import { toast, ToastContainer } from 'react-toastify';
import InputBRK from '../../components/input/InputBRK';
import UpdateCapsule from '../../components/UI/admin/UpdateCapsule';
import InfoLoader from '../../components/loaders/InfoLoader';
import InfoLoaderAdmin from '../../components/loaders/InfoLoaderAdmin';
import AddStock from '../../components/UI/admin/AddStock';
import * as XLSX from 'xlsx/xlsx.mjs';

function ProductsAdminPage() {
  const user = useSelector((state) => state.user);
  //======== STATES
  const [capsule, setCapsule] = useState(false);
  const [capsules, setCapsules] = useState([]);
  const [products, setProducts] = useState([]);
  const [editProductId, setEditProductId] = useState({});
  const [availableCapsule, setAvailableCapsule] = useState(false);
  const [isImageBigScaleOpen, setIsImageBigScaleOpen] = useState(false);
  const [imageBigScaleInfo, setImageBigScaleInfo] = useState();

  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [foundProduct, setFoundProduct] = useState('');
  const [productToChangeCapsule, setProductToChangeCapsule] = useState();
  const [
    isProductToChangeCapsuleModalOpen,
    setIsProductToChangeCapsuleModalOpen,
  ] = useState(false);

  const [loading, setLoading] = useState(false);
  const [stockList, setStockList] = useState([]);
  const [isAddProductStockModalOpen, setIsAddProductStockModalOpen] =
    useState(false);
  const [addProductStockId, setAddProductStockId] = useState({});
  const [showOnlyStock, setShowOnlyStock] = useState(false);

  //functions
  const closeEditProductModal = () => setIsEditProductModalOpen(false);

  const closeAddProductModal = () => setIsAddProductStockModalOpen(false);

  const openEditProductModal = (pid, name) => {
    setIsEditProductModalOpen(true);
    setEditProductId({ id: pid, name: name });
  };

  //======== EFFECTS
  useEffect(() => {
    setLoading(true);
    loadCapsules();
    loadProducts();
    loadStockList();
  }, []);

  const loadCapsules = () => {
    listCapsules(user.token)
      .then((res) => {
        setCapsules(res.data.capsules);
      })
      .catch((err) => console.log(err));
  };

  const loadProducts = () => {
    listProducts(user.token)
      .then((res) => {
        setProducts(res.data.products);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => console.log(err));
  };

  const loadStockList = () => {
    //1.- List stock and depending on
    listStock(user.token)
      .then((res) => {
        setStockList(res.data.stock);
      })
      .catch((err) => console.log(err));
  };

  const checkNumProd = (products) => {
    const p = products.filter((p) => p.pxCapsule === capsule);
    if (p.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleCheckboxChange = () => {
    setAvailableCapsule(!availableCapsule);

    let capsuleId = capsule;
    let isAvailable = !availableCapsule;

    //console.log('capsule', capsuleId, isAvailable);

    updateCapsuleAvailability(capsuleId, isAvailable, user.token)
      .then((res) => {
        //console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const openImageBigScale = (product) => {
    setImageBigScaleInfo(product);
    setIsImageBigScaleOpen(true);
  };

  const closeImageBigScale = () => {
    setImageBigScaleInfo();
    setIsImageBigScaleOpen(false);
  };

  const handleImage = (product) => {
    openImageBigScale(product);
  };

  const changeOrderHandler = (number, pid) => {
    const orderNumber = number;
    updateProductOrder({ productId: pid, orderNumber: orderNumber }, user.token)
      .then((res) => {
        toast.success('Product order changed!');
        loadProducts();
      })
      .catch((err) => {
        toast.error('Could not change product order!');
      });
  };

  const handleFindProductChange = (e) => {
    setFoundProduct(e.target.value);
  };

  const searched = (keyword) => (c) =>
    c.name.toLowerCase().includes(keyword.toLowerCase());

  const getNameCollection = (capsuleId) => {
    const c = capsules.find((c) => c._id === capsuleId);
    if (c) {
      return c.name;
    } else {
      return '';
    }
  };

  const updateCapsuleForProduct = (product) => {
    setProductToChangeCapsule(product);
    setIsProductToChangeCapsuleModalOpen(true);
  };

  const closeProductToChangeCapsuleModal = () =>
    setIsProductToChangeCapsuleModalOpen(false);

  const changeProductCapsule = (capsuleId) => {
    updateProductCapsule(
      { productId: productToChangeCapsule._id, capsuleId: capsuleId },
      user.token
    )
      .then((res) => {
        toast.success('Product capsule changed!');
        setIsProductToChangeCapsuleModalOpen(false);
        setProductToChangeCapsule(''); //reset to no product selected
        loadProducts();
      })
      .catch((err) => {
        toast.error('Could not change product order!');
      });
  };

  const downloadSheetInternalProductDetails = (products) => {
    // Display confirmation alert
    const confirmDownload = window.confirm(
      'Are you sure you want to download the Excel sheet with product data?'
    );

    if (!confirmDownload || !products) {
      return; // Do nothing if the user cancels
    }

    const transformedData = transformDataInternal(products);

    if (transformedData) {
      // Sort transformedData first by "Genero" and then by "Capsula"
      const sortedData = transformedData.slice().sort((a, b) => {
        const genderComparison = a.Genero.localeCompare(b.Genero);
        if (genderComparison !== 0) {
          return genderComparison;
        }
        return a.Capsula.localeCompare(b.Capsula);
      });

      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(sortedData);

      XLSX.utils.book_append_sheet(wb, ws, 'data');

      let workBookName = `Informacion_interna_products.xlsx`;
      XLSX.writeFile(wb, workBookName);
    } else {
      console.log('Could not export excel sheet with product data');
    }
  };

  const transformDataInternal = (products) => {
    return products.map((item) => {
      //get capsule name
      const capsuleName = capsules.find((c) => c._id === item.pxCapsule).name;
      const capsuleGender = capsules.find(
        (c) => c._id === item.pxCapsule
      ).gender;

      // Create the transformed object
      return {
        Capsula: capsuleName && capsuleName,
        Genero: capsuleGender && capsuleGender,
        Producto: item.name,
        Categoria: item.type,
        Referencia: item.reference,
        Tejido: item.fabric,
        Composicion: item.composition,
        ColorRef: item.color[0] && item.color[0],
      };
    });
  };

  const downloadSheetPrices = (products) => {
    // Display confirmation alert
    const confirmDownload = window.confirm(
      'Are you sure you want to download the Excel sheet with product data?'
    );

    if (!confirmDownload || !products) {
      return; // Do nothing if the user cancels
    }

    const transformedData = transformDataPrices(products);

    if (transformedData) {
      // Sort transformedData first by "Genero" and then by "Capsula"
      const sortedData = transformedData.slice().sort((a, b) => {
        const genderComparison = a.Genero.localeCompare(b.Genero);
        if (genderComparison !== 0) {
          return genderComparison;
        }
        return a.Capsula.localeCompare(b.Capsula);
      });

      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(sortedData);

      XLSX.utils.book_append_sheet(wb, ws, 'prices');

      let workBookName = `Prices_AW24_Collection_BRK.xlsx`;
      XLSX.writeFile(wb, workBookName);
    } else {
      console.log('Could not export excel sheet with product prices');
    }
  };

  const transformDataPrices = (products) => {
    return products.map((item) => {
      //get capsule name
      const capsuleName = capsules.find((c) => c._id === item.pxCapsule).name;
      const capsuleGender = capsules.find(
        (c) => c._id === item.pxCapsule
      ).gender;

      // Create the transformed object
      return {
        Capsula: capsuleName && capsuleName,
        Genero: capsuleGender && capsuleGender,
        Producto: item.name,
        Categoria: item.type && item.type,
        Referencia: item.reference,
        Composicion: item.composition && item.composition,
        ColorRef: item.color[0] && item.color[0],
        Precio: item.price && item.price,
        SRP: item.srp && item.srp,
      };
    });
  };

  const openAddStockProductModal = (pid, name) => {
    console.log('PRODUCT LIST ADMIN pid, name', pid, name);
    setAddProductStockId({ id: pid, name: name });
    setIsAddProductStockModalOpen(true);
  };

  const onClickDeleteStock = (stockId) => {
    // Ask for confirmation
    const isConfirmed = window.confirm(
      `WARNING : Are you sure you want to delete this stock?`
    );

    const userEmail = user.email;

    // If the user confirms, proceed with the delete operation
    if (isConfirmed) {
      // Perform the delete operation (replace this with your actual logic)
      deleteStockById(stockId, userEmail, user.token)
        .then((res) => {
          //console.log('DATA --> ', res.data.cart);
          if (res.data.message === 'Stock deleted successfully') {
            toast.success('Stock deleted successfully');
            loadStockList();
          }

          if (res.data.message === 'No auth') {
            toast.error('You are not allowed to delete stock.');
          }
        })
        .catch((err) => {
          toast.error('Could not delete the stock. Please refresh the page.');
          console.log(err);
        });

      // Reload the page or update the UI as needed
      // window.location.reload();
    } else {
      // User chose not to delete, you can handle this case if needed
      console.log('Delete operation canceled');
    }
  };

  const handleShowStock = () => {
    setShowOnlyStock(!showOnlyStock);
  };

  return (
    <AdminBodyStruct>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        show={isEditProductModalOpen}
        onCancel={closeEditProductModal}
        header={editProductId.name}
      >
        <EditProduct
          editProductId={editProductId.id}
          closeEditProductModal={closeEditProductModal}
          loadProducts={loadProducts}
        />
      </Modal>
      <Modal
        show={isAddProductStockModalOpen}
        onCancel={closeAddProductModal}
        header={'Available Stock'}
      >
        <AddStock
          addProductStockId={addProductStockId.id}
          closeAddProductModal={closeAddProductModal}
          capsules={capsules}
          loadStockList={loadStockList}
        />
      </Modal>
      <Modal
        show={isProductToChangeCapsuleModalOpen}
        onCancel={closeProductToChangeCapsuleModal}
        header={editProductId.name}
      >
        <UpdateCapsule
          productToChangeCapsule={productToChangeCapsule}
          closeProductToChangeCapsuleModal={closeProductToChangeCapsuleModal}
          capsules={capsules}
          changeProductCapsule={changeProductCapsule}
        />
      </Modal>
      {imageBigScaleInfo !== undefined && (
        <Modal
          show={isImageBigScaleOpen}
          onCancel={closeImageBigScale}
          className={'img-big-scale-modal'}
        >
          <ImageBigScale
            closeImageBigScale={closeImageBigScale}
            product={imageBigScaleInfo}
          />
        </Modal>
      )}
      <div className="flex-container-collections">
        <div className="flex-container-title-selector mb-1">
          <h2>
            Products <span>({`${products.length}`} items found)</span>
          </h2>
          <InputBRK
            placeholder={'Find a product'}
            onChange={handleFindProductChange}
          />
          {/* <SelectorBRK onSelectGender={changeCapsule} options={capsules} /> */}
        </div>
        {/* <hr className="horizontal-line" /> */}
        <div className="downloads-container">
          <div className="downloads-container">
            <p onClick={() => downloadSheetInternalProductDetails(products)}>
              Download data
            </p>
            <p onClick={() => downloadSheetPrices(products)}>Download prices</p>
          </div>
          <div>
            {showOnlyStock ? (
              <p onClick={() => handleShowStock()}>Show all products</p>
            ) : (
              <p onClick={() => handleShowStock()}>Show only stock</p>
            )}
          </div>
        </div>
        <hr className="horizontal-line" />
        {loading ? (
          <InfoLoaderAdmin />
        ) : (
          <ProductListAdmin
            products={products.filter(searched(foundProduct))}
            onClick={openEditProductModal}
            capId={'pass-all'}
            handleImage={handleImage}
            changeOrderHandler={changeOrderHandler}
            getNameCollection={getNameCollection}
            updateCapsuleForProduct={updateCapsuleForProduct}
            onClickAddStock={openAddStockProductModal}
            stockList={stockList ? stockList : []}
            onClickDeleteStock={onClickDeleteStock}
            showOnlyStock={showOnlyStock}
          />
        )}
      </div>
    </AdminBodyStruct>
  );
}

export default ProductsAdminPage;
