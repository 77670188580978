import React from 'react';

function ButtonInfo(props) {
  return (
    <button
      onClick={props.onClick}
      className={['button-info', props.classes].join(' ')}
    >
      {props.name}
    </button>
  );
}

export default ButtonInfo;
