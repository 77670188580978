function OrderSteps(props) {
  const logoStyle = {
    width: '25px',
    margin: '0',
    padding: '0',
    verticalAlign: 'middle', // Align the image vertically with the text
  };

  return (
    <div className="order-steps-box">
      <div>
        <h2>1. Start adding products from different capsules</h2>
        <p>
          Begin by adding products from various capsules to your selection.
          Explore our range and find the items that best suit your preferences.
        </p>
      </div>

      <div>
        <h2>2. Adding your Logo</h2>
        <p>
          If you wish to include your logo on any of the garments you're
          purchasing, please make sure to select the "Add Logo" option when
          adding quantities to your chosen products. This step ensures that our
          team will apply your logo to the selected garments with precision and
          care.
        </p>
        <p>
          You will know that you have chosen to add your logo to a specific
          garment when{' '}
          <span>
            <img
              src="/images/logo-added-border.svg"
              alt="logo"
              style={logoStyle}
            />{' '}
          </span>{' '}
          is displayed. Additionally, you can remove it by selecting "Remove" if
          you wish.
        </p>
      </div>

      <div>
        <h2>3. Review your selected products in your "Shopping Bag"</h2>
        <p>
          Take a moment to review the products you have added to your shopping
          bag. Edit them if necessary, and feel free to save your progress. You
          can always return to continue adding or editing items later.
        </p>
      </div>

      <div>
        <h2>4. Order confirmation</h2>
        <p>
          When you are satisfied with your selection, proceed to confirm your
          order. Look for the "Confirm order" button. Please note that once you
          confirm the order, no further changes can be made and you will agree
          with our{' '}
          <span
            style={{
              fontStyle: 'italic',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => props.navigate('/terms-and-conditions')}
          >
            Terms and Conditions.
          </span>
        </p>
      </div>
    </div>
  );
}

export default OrderSteps;
