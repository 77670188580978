import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function AdminMobileMenuDrawer(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  //states

  const [currentLocation, setCurrentLocation] = useState({
    dashboard: false,
    collections: false,
    products: false,
    orders: false,
    stats: false,
    clients: false,
  });

  const dashboardClickHandler = () => navigate('/dashboard-page');
  const collectionsClickHandler = () => navigate('/collections-page');
  const productsClickHandler = () => navigate('/products-page');
  const ordersClickHandler = () => navigate('/orders-page');
  const clientsClickHandler = () => navigate('/clients-page');
  const analyticsClickHandler = () => navigate('/analytics-page');

  useEffect(() => {
    const path = location.pathname;
    setCurrentLocation({
      dashboard: path === '/dashboard-page',
      collections: path === '/collections-page',
      products: path === '/products-page',
      orders: path === '/orders-page',
      stats: path === '/analytics-page',
      clients: path === '/clients-page',
    });
  }, [location.pathname]);

  return (
    <CSSTransition
      in={props.show}
      timeout={200}
      classNames="slide-in-right"
      mountOnEnter
      unmountOnExit
    >
      <aside className="mobile-side-drawer-right">
        {user.name !== 'Gabriela' ? (
          <div className="flex-container-mobile-menu">
            <h2
              onClick={dashboardClickHandler}
              className={currentLocation.dashboard ? 'nav-item-highlight' : ''}
            >
              Dashboard
            </h2>
            <h2
              onClick={collectionsClickHandler}
              className={
                currentLocation.collections ? 'nav-item-highlight' : ''
              }
            >
              Collections
            </h2>
            <h2
              onClick={productsClickHandler}
              className={currentLocation.products ? 'nav-item-highlight' : ''}
            >
              Products
            </h2>
            <h2
              onClick={ordersClickHandler}
              className={currentLocation.orders ? 'nav-item-highlight' : ''}
            >
              Orders
            </h2>
            <h2
              onClick={clientsClickHandler}
              className={currentLocation.clients ? 'nav-item-highlight' : ''}
            >
              Clients
            </h2>
            <h2
              onClick={analyticsClickHandler}
              className={currentLocation.stats ? 'nav-item-highlight' : ''}
            >
              Production
            </h2>
          </div>
        ) : (
          <div className="flex-container-mobile-menu">
            <h2
              onClick={collectionsClickHandler}
              className={
                currentLocation.collections ? 'nav-item-highlight' : ''
              }
            >
              Collections
            </h2>
            <h2
              onClick={productsClickHandler}
              className={currentLocation.products ? 'nav-item-highlight' : ''}
            >
              Products
            </h2>

            <h2
              onClick={analyticsClickHandler}
              className={currentLocation.stats ? 'nav-item-highlight' : ''}
            >
              Production
            </h2>
          </div>
        )}
      </aside>
    </CSSTransition>
  );
}

export default AdminMobileMenuDrawer;
