import React from 'react';

function SelectorBRK(props) {
  // State

  // Function
  const handleBRKSelect = (event) => {
    props.onSelectGender(event.target.value);
  };

  return (
    <select
      value={props.value}
      defaultValue="" // Set the default value to an empty string
      className={['styled-select', props.classes].join(' ')}
      onChange={handleBRKSelect}
    >
      <option value="" disabled>
        Select capsule
      </option>
      {props.options.map((g, index) => (
        <option value={g._id} key={index}>
          {g.name}
        </option>
      ))}
    </select>
  );
}

export default SelectorBRK;
