import React from 'react';

const BackdropMobile = (props) => {
  return (
    <aside className="backdrop-mobile" onClick={props.onClick}>
      {props.children}
    </aside>
  );
};

export default BackdropMobile;
