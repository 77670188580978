import SelectorBRK from '../../input/SelectorBRK';
import React, { useState, useEffect } from 'react';
import ButtonBRK from '../../buttons/ButtonBRK';
import InputBRK from '../../input/InputBRK';
import { listCollections } from '../../../functions/collections';
import { useSelector } from 'react-redux';

const genderList = [
  { _id: 'men', name: 'men' },
  { _id: 'women', name: 'women' },
];

const initialStateCollections = [{ _id: '', name: '' }];

function AddCapsules(props) {
  const user = useSelector((state) => state.user);
  //States
  const [newCapsule, setNewCapsule] = useState({
    name: '',
    gender: 'men',
    collectionId: '',
  });
  const [collections, setCollections] = useState(initialStateCollections);

  //Useeffect
  useEffect(() => {
    loadCollections();
  }, []);

  const loadCollections = () => {
    listCollections(user.token)
      .then((res) => {
        setCollections(res.data.collections);
        //we already choose the first option for collection
        setNewCapsule({
          ...newCapsule,
          collectionId: res.data.collections[0]._id,
        });
      })
      .catch((err) => console.log(err));
  };

  //functions - SELECTORS
  const textChangeHandler = (event) => {
    //setEnteredText(event.target.value);
    setNewCapsule({ ...newCapsule, name: event.target.value });
  };

  //final function
  const addNewCapsuleHandler = () => {
    //we can execute the function on the parent component
    props.onCreateNewCapsule(newCapsule);
    setNewCapsule({
      name: '',
      gender: '',
      collectionId: '',
    });
  };

  const changeGender = (gen) => {
    setNewCapsule({ ...newCapsule, gender: gen });
  };

  const changeCollectionId = (colId) => {
    setNewCapsule({ ...newCapsule, collectionId: colId });
  };

  return (
    <div className="flex-container-create-new-collection">
      <h2>Choose collection</h2>
      <SelectorBRK
        classes={'color-white'}
        onSelectGender={changeCollectionId}
        options={collections}
      />
      <h2>Choose gender</h2>
      <SelectorBRK
        classes={'color-white'}
        onSelectGender={changeGender}
        options={genderList}
      />
      <h2>Create capsule</h2>
      <InputBRK
        classes={'input-add-capsule'}
        placeholder={'Capsule name'}
        onChange={textChangeHandler}
        value={newCapsule.name}
      />

      <ButtonBRK
        name="Create capsule"
        classes={'btn-accept-create-new-collection'}
        onClick={addNewCapsuleHandler}
      />
    </div>
  );
}

export default AddCapsules;
