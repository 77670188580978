import React from 'react';

const Backdrop = (props) => {
  return (
    <aside
      // className="backdrop"
      className={['backdrop', props.classes].join(' ')}
      onClick={props.onClick}
    >
      {props.children}
    </aside>
  );
};

export default Backdrop;
