import SelectorBRK from '../../input/SelectorBRK';
import React, { useState } from 'react';
import ButtonBRK from '../../buttons/ButtonBRK';
import InputBRK from '../../input/InputBRK';

function CreateCollection(props) {
  //States
  const [enteredText, setEnteredText] = useState('');

  //Functions
  const addNewCollectionHandler = () => {
    //we can execute the function on the parent component
    props.onCreateNewCollection(enteredText);
    setEnteredText('');
  };

  const textChangeHandler = (event) => {
    setEnteredText(event.target.value);
  };

  return (
    <div className="flex-container-create-new-collection">
      <h2>Create new collection</h2>
      <InputBRK
        classes={'input-add-capsule'}
        value={enteredText}
        onChange={textChangeHandler}
        placeholder={'Collection name'}
      />
      {/* <SelectorBRK
        classes={'selector-create-collection-gender'}
        placeholder={'Gender'}
      /> */}
      <ButtonBRK
        name="Save"
        classes={'btn-accept-create-add-capsule'}
        onClick={addNewCollectionHandler}
      />
    </div>
  );
}

export default CreateCollection;
