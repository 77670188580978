import React, { useState, useEffect } from 'react';

import {
  checkAddedProductsTotal,
  calcTotalSubtotal,
} from '../../functions/qty-check';
import ProductIconsSorted from './ProductIconsSorted';
import ProductsSorted from './ProductsSorted';

//Here we have to check for gender and upper vs lower body

function SummaryClientBag(props) {
  ///console.log('??????? props.cart', props.cart);
  const [isIconMode, setIsIconMode] = useState(false);
  //functions
  const isLogoAdded = (pid) => {
    const product = props.cart.find((p) => p.product._id === pid);

    if (product) {
      if (product.logo && product.logo === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div className="wrapper-product-select">
      <div className="title-box">
        <h1>Shopping Bag</h1>
      </div>

      <div className="subtotal-upper-box sticky-top">
        <div className="exit-container">
          <div className="exit-2-container" onClick={props.onClick}>
            <img
              src="/images/exit-arrow.svg"
              className="arrow-exit"
              alt="arrow-exit"
            />
            <p>Continue shopping</p>
          </div>
          <h3>
            Total: {checkAddedProductsTotal(props.cart)} pieces, Subtotal:{' '}
            {calcTotalSubtotal(props.cart).subtotal.toFixed(2)} €
          </h3>
          {/* <ButtonBRK name="Save progress" /> */}
        </div>
      </div>
      <div className="product-component-box">
        {/* <hr className="horizontal-line"></hr> */}
        <div className="list-icon-box">
          {/* <p
            className={!isIconMode ? 'icon-selected' : ''}
            onClick={() => setIsIconMode(false)}
          >
            List
          </p> */}
          <img
            src={!isIconMode ? '/images/list.svg' : '/images/list_gray.svg'}
            alt="list"
            onClick={() => setIsIconMode(false)}
          />
          <img
            src={
              isIconMode ? '/images/multiple.svg' : '/images/multiple_gray.svg'
            }
            alt="icons"
            onClick={() => setIsIconMode(true)}
          />
          {/* <p
            className={isIconMode ? 'icon-selected' : ''}
            onClick={() => setIsIconMode(true)}
          >
            Icons
          </p> */}
        </div>
        {props.cart && props.cart.length > 0 && !isIconMode ? (
          <React.Fragment>
            <ProductsSorted
              cart={props.cart}
              isLogoAdded={isLogoAdded}
              capsules={props.capsules}
              requiredGender={'men'}
              removeProduct={props.removeProduct}
              addQty={props.addQty}
              reduceQty={props.reduceQty}
              removeLogoToProduct={props.removeLogoToProduct}
              addLogoToProduct={props.addLogoToProduct}
            />
            <ProductsSorted
              cart={props.cart}
              isLogoAdded={isLogoAdded}
              capsules={props.capsules}
              requiredGender={'women'}
              removeProduct={props.removeProduct}
              addQty={props.addQty}
              reduceQty={props.reduceQty}
              removeLogoToProduct={props.removeLogoToProduct}
              addLogoToProduct={props.addLogoToProduct}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ProductIconsSorted
              cart={props.cart}
              capsules={props.capsules}
              removeProduct={props.removeProduct}
              isIconMode={isIconMode}
              requiredGender={'men'}
            />
            <ProductIconsSorted
              cart={props.cart}
              capsules={props.capsules}
              removeProduct={props.removeProduct}
              isIconMode={isIconMode}
              requiredGender={'women'}
            />
          </React.Fragment>
        )}

        {props.cart.length > 0 ? (
          <>
            <div className="exit-container box-style">
              {props.cart.length > 2 && !isIconMode ? (
                <div className="exit-2-container" onClick={props.onClick}>
                  <img
                    src="/images/exit-arrow.svg"
                    className="arrow-exit"
                    alt="arrow-exit"
                  />
                  <p>Continue shopping</p>
                </div>
              ) : (
                <div>
                  <h2></h2>
                </div>
              )}
            </div>
            <div className="subtotal-box">
              <h2>
                Total: {checkAddedProductsTotal(props.cart)} pieces, Subtotal:{' '}
                {calcTotalSubtotal(props.cart).subtotal.toFixed(2)}€
              </h2>
            </div>
          </>
        ) : (
          <div>
            <h2>No products found in your shopping bag.</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default SummaryClientBag;
