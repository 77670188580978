import React from 'react';
import SelectorOrder from '../input/SelectorOrder';
import { capFirstLetter } from '../../functions/cap-first-letter';

/////////// WARNING - READ  /////////////////////////////////////////////////////
// COMPONENT USED BY COLLECTIONS PAGE AND PRODUCTS ADMIN PAGE
/// THIS SHORTCUT USED: product.pxCapsule === props.capId || props.capId === 'pass-all')
/////////////////////////////////////////////////////////////////////////////////

function ProductListAdmin(props) {
  //functions
  const editProductHandle = (pid, name) => {
    props.onClick(pid, name);
  };

  const addStockHandle = (pid, name) => {
    console.log('+++++++++++++++ hanle product--->', pid, name);
    //console.log('PRODUCT LIST ADMIN pid, name', pid, name);
    props.onClickAddStock(pid, name);
  };

  const deleteStockHandle = (pid) => {
    //console.log('PRODUCT LIST ADMIN pid, name', pid, name);
    props.onClickDeleteStock(pid);
  };

  const showStockList = (productId) => {
    let product = props.stockList.find((p) => p.pxProduct._id === productId);

    if (product && product.stockAvailable) {
      // Define the order of sizes as per your requirement
      const sizeOrder = [
        'XXS',
        'XS',
        'S',
        'M',
        'L',
        'XL',
        'XXL',
        '32',
        '34',
        '36',
        '38',
        '40',
        '42',
        '44',
        '46',
        '48',
        '50',
        '52',
        '54',
        '56',
        '58',
        '60',
      ];

      // Sort stockAvailable based on sizeOrder
      product.stockAvailable.sort((a, b) => {
        return sizeOrder.indexOf(a.size) - sizeOrder.indexOf(b.size);
      });

      return (
        <div className="stock-available-box">
          <h3>
            Stock:{' '}
            {product.stockAvailable.map((stock, index) => (
              <span key={index}>
                {stock.size}:{stock.qty}
                {index < product.stockAvailable.length - 1 ? ' / ' : ''}
              </span>
            ))}
          </h3>
          <p onClick={() => deleteStockHandle(product._id)}>Delete stock</p>
        </div>
      );
    }

    return null;
  };

  const filterForStock = (products) => {
    if (props.showOnlyStock) {
      let productsToShow = products.filter((product) =>
        props.stockList.some(
          (stockItem) => stockItem.pxProduct._id === product._id
        )
      );
      return productsToShow;
    } else {
      return products;
    }
  };

  return (
    <div className="flex-container-collection-products">
      {filterForStock(props.products).map((product) => {
        return (
          (product.pxCapsule === props.capId || props.capId === 'pass-all') && (
            <div key={product._id}>
              <div className="product-info-flex-container">
                <div className="info-box-flex-container">
                  {product.image.url && product.image.url !== '' && (
                    <img
                      src={product.image.url}
                      alt="product-img"
                      className="info-img"
                      //onClick={() => props.handleImage(product)}
                    />
                  )}

                  <div className="info-item">
                    <div className="name-price-box">
                      <h2>{`${capFirstLetter(product.name)}`}</h2>
                      <div className="price-item">
                        <h2>{product.price}€</h2>
                        <p>/ SRP: {product.srp}€</p>
                      </div>
                    </div>
                    <p>{`Ref. ${product.reference}`}</p>
                    <p>
                      {product.color} / {product.fabric}
                    </p>
                    <p>{product.composition}</p>
                    <p>{product.description}</p>
                    {props.getNameCollection && (
                      <p
                        className="capsule-name"
                        onClick={() => props.updateCapsuleForProduct(product)}
                      >{`Capsule: ${props.getNameCollection(
                        product.pxCapsule
                      )}`}</p>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="size-box-flex-container">
                    {menListSizesUpperBody.map((item, index) => {
                      return <ButtonChangeQty size={item} />;
                    })}
                  </div> */}

              <div className="edit-product-admin">
                {/*  */}
                <SelectorOrder
                  product={product}
                  classes={'order-selector'}
                  changeOrderHandler={props.changeOrderHandler}
                />
                <div className="edit-and-stock-box">
                  <p
                    className="edit-product"
                    onClick={() => editProductHandle(product._id, product.name)}
                  >
                    Edit
                  </p>
                  <p>{' / '}</p>
                  <p
                    className="edit-product"
                    onClick={() => addStockHandle(product._id, product.name)}
                  >
                    Stock
                  </p>
                </div>
              </div>
              {props.stockList && showStockList(product._id)}
              <hr className="horizontal-line"></hr>
            </div>
          )
        );
      })}
    </div>
  );
}

export default ProductListAdmin;
