import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonBRK from '../components/buttons/ButtonBRK';
//import { auth } from '../firebase';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { createOrUpdateUser } from '../functions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import HeaderLogin from '../components/HeaderLogin';

function LogInItemPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const auth = getAuth();

  //state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  const handleSubmit = async (e) => {
    //console.log('LOGGING...............');
    e.preventDefault();
    try {
      //set laoding to true
      //setLoading(true);
      //login with firebase
      const results = await signInWithEmailAndPassword(auth, email, password);

      //dispatch user, token to redux
      const { user } = results;
      const idTokenResult = await user.getIdTokenResult();

      // call function with axios request (post) to backend
      createOrUpdateUser(idTokenResult.token)
        .then((res) => {
          dispatch({
            type: 'LOGGED_IN_USER',
            payload: {
              email: res.data.email,
              name: res.data.name,
              token: idTokenResult.token,
              role: res.data.role,
              _id: res.data._id,
            },
          });
          //console.log('********** LOOGED');
          //redirect
          setEmail('');
          setPassword('');
          roleBasedRedirect(res);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
      //setLoading(false);
      toast.error('Wrong credentials');
    }
  };

  const roleBasedRedirect = (res) => {
    if (res.data.role === 'admin') {
      navigate('/');
    } else {
      navigate('/');
    }
  };

  const onChangeEmailHandle = (event) => {
    let newEmail = event.target.value;
    setEmail((prevEmail) => newEmail);
  };

  const onChangePasswordHandle = (event) => {
    let newPassword = event.target.value;
    setPassword((prevPassword) => newPassword);
  };

  const sigInMsg = () => {
    toast.info('Please Sign in');
  };

  return (
    <div>
      <HeaderLogin sigInMsg={sigInMsg} />

      <div className="log-in-container">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="log-in-info">
          <h1>Welcome!</h1>
          <p>
            Enter your credentials below to explore our new collections and
            exclusive offerings.
          </p>
          <form className="log-in-form">
            {/* <h1 className="title">PROSHOP X1</h1> */}
            <div>
              <p>Email</p>
              <input
                className="input-log-in-username"
                type="text"
                placeholder="Enter email"
                name="email"
                value={email}
                onChange={onChangeEmailHandle}
              ></input>
              <p>Password</p>
              <input
                className="input-log-in-password"
                type="password"
                placeholder="Enter password"
                name="password"
                value={password}
                onChange={onChangePasswordHandle}
              ></input>
            </div>

            <ButtonBRK
              name="Sign in"
              onClick={handleSubmit}
              classes={'log-in-btn'}
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default LogInItemPage;

{
  /* <p className="error">
        Either you do not have an account or your username or password is wrong
      </p> */
}
