import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function AdminHeader(props) {
  const location = useLocation();
  const pathname = location.pathname;
  // console.log('pathname', pathname);

  return (
    <React.Fragment>
      <div
        className={
          pathname === '/collections-page' ||
          pathname === '/clients-page' ||
          pathname === '/products-page'
            ? 'flex-container-header-admin-desktop'
            : 'display-none'
        }
      >
        <div className="flex-container-header-admin-desktop-adds">
          {pathname === '/collections-page' ? (
            <>
              <p onClick={props.onClickCreateCollection}>
                + Create new collection
              </p>
              <p onClick={props.onClickAddCapsule}>+ Create capsule</p>
              <p onClick={props.onClickAddProducts}>+ Add products</p>
            </>
          ) : pathname === '/clients-page' ? (
            <>
              <p onClick={props.onClickCreateClient}>+ Client</p>
            </>
          ) : pathname === '/products-page' ? (
            <>
              <p onClick={props.onClickCreateProduct}>+ Product</p>
            </>
          ) : null}
        </div>

        {/* <p>Eduardo</p> */}
        {/* <img
        src="./images/user-bold.svg"
        alt="user-img"
        className="icon-user-menu-desktop"
      /> */}
      </div>
      <hr
        className={
          pathname === '/collections-page' ||
          pathname === '/clients-page' ||
          pathname === '/products-page'
            ? 'horizontal-line'
            : 'display-none'
        }
      ></hr>
    </React.Fragment>
  );
}

export default AdminHeader;
