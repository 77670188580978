import React, { useState, useEffect } from 'react';
import ButtonChangeQty from '../buttons/ButtonChangeQty';
import {
  checkAddedProductsTotal,
  calcTotalSubtotal,
} from '../../functions/qty-check';

import { checkSizes } from '../../functions/check-sizes';
import { capFirstLetter } from '../../functions/cap-first-letter';

function ProductCapsuleSelect(props) {
  //dict

  //functions
  const isLogoAdded = (pid) => {
    const product = props.cart.find((p) => p.product._id === pid);

    if (product) {
      if (product.logo && product.logo === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleImage = (product) => {
    props.openImageBigScale(product);
  };

  return (
    <div className="wrapper-product-select">
      {/* <div className="header-component"> */}
      <div className="title-box">
        <h1>{props.capsule.capsuleName}</h1>
      </div>
      <div className="subtotal-upper-box sticky-top">
        <div className="exit-container ">
          <div className="exit-2-container" onClick={props.onClick}>
            <img
              src="/images/exit-arrow.svg"
              className="arrow-exit"
              alt="arrow-exit"
            />
            <p>Go back</p>
          </div>
          <h3>
            Total: {checkAddedProductsTotal(props.cart)} pieces, Subtotal:{' '}
            {calcTotalSubtotal(props.cart).subtotal.toFixed(2)} €
          </h3>
          {/* <ButtonBRK name="Save progress" /> */}
        </div>
      </div>
      <div className="product-component-box">
        {/* <hr className="horizontal-line"></hr> */}
        {/* </div> */}
        {/* <div className="body-component"> */}
        {props.capsule &&
          Object.keys(props.capsule).length !== 0 &&
          props.capsule.products.map((product) => {
            return (
              <React.Fragment key={product._id}>
                <div className="product-info-flex-container">
                  <div className="info-box-flex-container">
                    {product.image.url ? (
                      <img
                        // onClick={() => handleImage(product)}
                        // src={`/images/${product.name}.svg`}

                        src={`${product.image.url}`}
                        alt="product-img"
                        className="info-img"
                      />
                    ) : (
                      <img
                        // onClick={() => handleImage(product)}
                        // src={`/images/${product.name}.svg`}

                        src="/images/not-found-image.png"
                        alt="product-img"
                        className="info-img"
                      />
                    )}

                    <div className="info-item">
                      <div className="name-price-box">
                        <h2>{`${capFirstLetter(product.name)}`}</h2>
                        <div className="price-item">
                          <h2>{product.price}€</h2>
                          <p>/ SRP: {product.srp}€</p>
                        </div>
                      </div>
                      <p>{`Ref. ${product.reference}`}</p>
                      <p>{product.color}</p>
                      <p>{product.composition}</p>
                      <p>{product.description}</p>
                    </div>
                  </div>
                </div>
                <div className="size-box-flex-container">
                  {props.cart &&
                    checkSizes(product, props.capsules).map((item, index) => {
                      return (
                        <ButtonChangeQty
                          key={index}
                          size={item}
                          addQty={() => props.addQty(product, item)}
                          reduceQty={() => props.reduceQty(product, item)}
                          cart={props.cart}
                          pid={product._id}
                          // onClick={() => addQtyHandler(product._id, item)}
                        />
                      );
                    })}
                </div>
                <div className="qty-flex-container">
                  {/* <h3>Cantidad: 5</h3> */}
                  <p onClick={() => props.removeProduct(product)}>
                    Quitar todas las cantidades
                  </p>
                  <div className="flex-container-add-logo">
                    {isLogoAdded(product._id) ? (
                      <>
                        <img src="/images/logo-added-border.svg" alt="logo" />
                        {/* <h3>Logo added</h3> */}
                        <p onClick={() => props.removeLogoToProduct(product)}>
                          Remove
                        </p>
                      </>
                    ) : (
                      <p onClick={() => props.addLogoToProduct(product)}>
                        Add logo
                      </p>
                    )}
                  </div>
                </div>

                <hr className="horizontal-line"></hr>
              </React.Fragment>
            );
          })}

        <div className="exit-container box-style">
          <div className="exit-2-container" onClick={props.onClick}>
            <img
              src="/images/exit-arrow.svg"
              className="arrow-exit"
              alt="arrow-exit"
            />
            <p>Go back</p>
          </div>
        </div>

        <div className="subtotal-box">
          <h2>
            Total: {checkAddedProductsTotal(props.cart)} pieces, Subtotal:{' '}
            {calcTotalSubtotal(props.cart).subtotal.toFixed(2)}€
          </h2>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default ProductCapsuleSelect;
