import React from 'react';
import ButtonBRK from '../buttons/ButtonBRK';
import ButtonInfo from '../buttons/ButtonInfo';

function OrderConfirmationSuccess(props) {
  return (
    <div className="flex-container-order-confirmation">
      <h3>
        Your order has been successfully processed. We appreciate your trust in
        us.
      </h3>
      <div className="flex-container-btn-confirmation">
        {/* <ButtonBRK
          name={'Confirm order'}
          classes={'btn-order-confirmation'}
          onClick={props.confirmOrderHandler}
        /> */}
        <ButtonInfo
          name={'Close'}
          classes={'btn-order-confirmation-success'}
          onClick={props.navigateHome}
        />
      </div>
    </div>
  );
}

export default OrderConfirmationSuccess;
