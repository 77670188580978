import React from 'react';
import { CSSTransition } from 'react-transition-group';

const SummaryDrawer = (props) => {
  return (
    <CSSTransition
      in={props.show}
      timeout={200}
      classNames="slide-in-right"
      mountOnEnter
      unmountOnExit
    >
      <aside className="product-capsule-side-drawer-right">
        {props.children}
      </aside>
    </CSSTransition>
  );
};

export default SummaryDrawer;
