import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './users-reducer';
import { cartReducer } from './cart-reducer';

const store = configureStore({
  reducer: {
    user: userReducer,
    cart: cartReducer,
  },
});

export default store;
