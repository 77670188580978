import React from 'react';
import { sortByGenderSummaryBag } from '../../functions/sortProducts';
import { check } from 'express-validator';

function ProductIconsSorted(props) {
  const checkIndividualStyleNumber = (product) => {
    const sumOfQty = product.pidOrder.reduce(
      (total, obj) => total + obj.qty,
      0
    );

    return sumOfQty;
  };

  return (
    <React.Fragment>
      {props.cart &&
      props.cart.length > 0 &&
      props.isIconMode &&
      Object.entries(
        sortByGenderSummaryBag(props.cart, props.requiredGender, props.capsules)
          .orderSorted
      ).length === 0 ? (
        <div className="products-icons-setup-box">
          <p style={{ fontStyle: 'italic' }}>
            (No products have been added from the {props.requiredGender}'s
            collection yet)
          </p>
        </div>
      ) : (
        Object.entries(
          sortByGenderSummaryBag(
            props.cart,
            props.requiredGender,
            props.capsules
          ).orderSorted
        ).map(([capsuleName, productList]) => (
          <div className="products-icons-setup-box" key={capsuleName}>
            <h3>
              {`${props.requiredGender.toUpperCase()} / ${capsuleName}`}{' '}
              <span style={{ fontStyle: 'italic', fontSize: '12px' }}>
                ({productList.length} styles)
              </span>
            </h3>
            <div className="products-icons-setup">
              {productList.map((p) => (
                <div className="image-container" key={p.product._id}>
                  <img
                    src={p.product.image.url || '/images/not-found-image.png'}
                    alt="product-img"
                    className="info-img"
                  />
                  <div
                    className="circle"
                    onClick={() => props.removeProduct(p.product)}
                  >
                    <img
                      src="/images/close.svg"
                      className="close-icon"
                      alt="close"
                    />
                  </div>
                  {p.logo && (
                    <div className="logo-added">
                      <img
                        src="/images/logo-added-border.svg"
                        alt="logo"
                        className="info-img"
                      />
                    </div>
                  )}
                  <div className="circle-number">
                    <p>{checkIndividualStyleNumber(p)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </React.Fragment>
  );
}

export default ProductIconsSorted;
