//Here we have to check for gender and upper vs lower body

const menListSizesUpperBody = ['S', 'M', 'L', 'XL', 'XXL'];
const womenListSizesUpperBody = ['XS', 'S', 'M', 'L', 'XL'];
const menListSizesLowerBody = ['T38', 'T40', 'T42', 'T44', 'T46', 'T48', 'T50'];
const womenListSizesLowerBody = ['T36', 'T38', 'T40', 'T42', 'T44', 'T46'];
const socksSizes = ['T36-T39', 'T40-T46'];
const oneSizes = ['Single size'];
// const socksSizesWomen = ['36-39', '40-46'];
// const caps = ['One size'];
// const manguito = ['One size'];
// const hats = ['One size'];
// const snood = ['One size'];

//////////////////////////////////////////////////////////////////////////////
///////////////////////// FRONTEND - CLIENTS ////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
const oneSizeAccessories = ['Gorra', 'Manguito', 'Gorro'];
const socks = ['Calcetín corto', 'Calcetín largo'];
const garmentTypeListUpper = [
  'Polo',
  'Polo manga larga',
  'Mid-layer',
  'Mid-layer sin manga',
  'Jersey',
  'Vestido',
  'Cortavientos',
  'Abrigo',
  'Chaqueta',
  'Camiseta',
  'Sudadera',
  'Chaqueta acolchada',
  'Chaleco',
  'Chaleco reversible cortaviento',
  'Chaqueta reversible cortaviento',
];
const garmentTypeListLower = ['Pantalón largo', 'Pantalón corto', 'Falda'];

//////////////////////////////////////////////////////////////////////////////
///////////////////////// PRODUCTION ADMIN ///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
const garmentTypeProductionUpper = [
  'Polo Shirt',
  'Long Sleeve Polo Shirt',
  'Mid-layer',
  'Sleeveless Mid-layer',
  'Jersey',
  'Dress',
  'Windstopper',
  'Coat',
  'Jacket',
  'T-Shirt',
  'Hoodie',
  'Padded Jacket',
  'Vest',
  'Reversible windstopper Vest',
  'Reversible windstopper Jacket',
];

const garmentTypeProductionLower = ['Pants', 'Shorts', 'Skirt'];

const oneSizeAccessoriesProduction = ['Cap', 'Sleeve'];
const socksProduction = ['Short Socks', 'Long Socks'];

////////////////////////////////////////////////////////////
export const checkSizes = (product, capsules) => {
  console.log('*****capsules in CHECKSIZES', capsules);
  console.log('*****product', product);

  // Check if product.pxCapsule is an object or a direct ID
  const capsuleId = product.pxCapsule._id
    ? product.pxCapsule._id
    : product.pxCapsule;
  //console.log('*****capsuleId', capsuleId);

  let capsule = '';

  capsule = capsules.find((cap) => cap.capsuleId === capsuleId);
  //console.log('***** found capsule 1', capsule);

  if (!capsule) {
    capsule = capsules.find((cap) => cap._id === capsuleId);
    //console.log('***** found capsule 2', capsule);
    if (!capsule) {
      return ['M'];
    }
  }

  const capsuleGender = capsule.gender;
  //console.log('capsuleGender', capsuleGender);

  //Logic depending on gender
  if (capsuleGender === 'men') {
    if (garmentTypeListUpper.includes(product.type)) {
      return menListSizesUpperBody;
    } else if (garmentTypeListLower.includes(product.type)) {
      return menListSizesLowerBody;
    } else if (socks.includes(product.type)) {
      return socksSizes;
    } else if (oneSizeAccessories.includes(product.type)) {
      return oneSizes;
    } else {
      return [];
    }
  } else if (capsuleGender === 'women') {
    if (garmentTypeListUpper.includes(product.type)) {
      return womenListSizesUpperBody;
    } else if (garmentTypeListLower.includes(product.type)) {
      return womenListSizesLowerBody;
    } else if (socks.includes(product.type)) {
      return socksSizes;
    } else if (oneSizeAccessories.includes(product.type)) {
      return oneSizes;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const checkSizesAdmin = (gender, typeName) => {
  //Logic depending on gender
  if (gender === 'men') {
    if (garmentTypeProductionUpper.includes(typeName)) {
      return menListSizesUpperBody;
    } else if (garmentTypeProductionLower.includes(typeName)) {
      return menListSizesLowerBody;
    } else if (socksProduction.includes(typeName)) {
      return socksSizes;
    } else if (oneSizeAccessoriesProduction.includes(typeName)) {
      return oneSizes;
    } else {
      return [];
    }
  } else if (gender === 'women') {
    if (garmentTypeProductionUpper.includes(typeName)) {
      return womenListSizesUpperBody;
    } else if (garmentTypeProductionLower.includes(typeName)) {
      return womenListSizesLowerBody;
    } else if (socksProduction.includes(typeName)) {
      return socksSizes;
    } else if (oneSizeAccessoriesProduction.includes(typeName)) {
      return oneSizes;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const checkSizesProductQtyDetails = (gender, typeName) => {
  if (gender === 'men') {
    if (garmentTypeListUpper.includes(typeName)) {
      return menListSizesUpperBody;
    } else if (garmentTypeListLower.includes(typeName)) {
      return menListSizesLowerBody;
    } else if (socks.includes(typeName)) {
      return socksSizes;
    } else if (oneSizeAccessories.includes(typeName)) {
      return oneSizes;
    } else {
      return [];
    }
  } else if (gender === 'women') {
    if (garmentTypeListUpper.includes(typeName)) {
      return womenListSizesUpperBody;
    } else if (garmentTypeListLower.includes(typeName)) {
      return womenListSizesLowerBody;
    } else if (socks.includes(typeName)) {
      return socksSizes;
    } else if (oneSizeAccessories.includes(typeName)) {
      return oneSizes;
    } else {
      return [];
    }
  } else {
    return [];
  }
};
