import React, { useState } from 'react';
import { homePageOrderTotal } from '../../functions/qty-check';
import ProductsOrderDetails from './ProductsOrderDetails';
import ProductIconSortedForOrderDetails from '../ProductIconSortedForOrderDetails';
import { checkCollection } from '../../functions/checkCollection';

function OrderDetails(props) {
  //const [capsules, setCapsules] = useState([]);
  const [isIconMode, setIsIconMode] = useState(false);

  const changeDateFormat = (originalDate) => {
    // Parse the original date string
    const dateObj = new Date(originalDate);

    // Define an array of month names
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Get the month, day, and year from the date object
    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    // Construct the formatted date string
    const formattedDate = `${month} ${day < 10 ? '0' + day : day} ${year}`;
    return formattedDate;
  };

  //console.log(sortByGender(props.order, props.capsules));

  return (
    <div className="order-details-box">
      <div className="order-details-header">
        <h1>Order details</h1>
        <div className="exit-container">
          <div className="exit-2-container" onClick={props.onClick}>
            <img
              src="/images/exit-arrow.svg"
              className="arrow-exit"
              alt="arrow-exit"
            />
            <p onClick={props.closeOrderDetails}>Go back</p>
          </div>
        </div>
        {/* <ButtonBRK name="Save progress" /> */}
        <hr className="horizontal-line-order-details"></hr>
      </div>

      <div className="flex-container-order-datails-info">
        <div className="flex-container-order-details-status-b1">
          <h2>Collection: {checkCollection(props.collectionId)}</h2>
          <h3>{`Order Ref: #${props.orderDet.orderReference.slice(-6)}`}</h3>
          <h3>{`Order date: ${changeDateFormat(props.orderDet.updatedAt)}`}</h3>
          {/* <h4>To deliver 15 September</h4> */}
        </div>
        <div className="flex-container-order-details-status-b2">
          {props.orderDet.discount === 0 ? (
            <>
              <h2>{`Total neto: ${homePageOrderTotal(
                props.orderDet
              ).totalAmount.toFixed(2)} €`}</h2>

              <h3>{`Subtotal: ${homePageOrderTotal(
                props.orderDet
              ).subtotalAmount.toFixed(2)} €`}</h3>
            </>
          ) : (
            <>
              <h2>{`Total neto: ${homePageOrderTotal(
                props.orderDet
              ).totalAmountDiscounted.toFixed(2)} €`}</h2>

              <h3>{`Subtotal: ${homePageOrderTotal(
                props.orderDet
              ).subtotalAmount.toFixed(2)} €`}</h3>
            </>
          )}

          {props.orderDet.paid ? (
            <h3>Payment status: Paid</h3>
          ) : (
            <h3>Payment status: Not paid</h3>
          )}

          {props.orderDet.discount !== 0 && (
            <h4>{`${props.orderDet.discount}% discount applied`}</h4>
          )}
        </div>
      </div>
      {/* <hr className="horizontal-line" /> */}
      <div className="list-icon-box">
        <img
          src={!isIconMode ? '/images/list.svg' : '/images/list_gray.svg'}
          alt="list"
          onClick={() => setIsIconMode(false)}
        />
        <img
          src={
            isIconMode ? '/images/multiple.svg' : '/images/multiple_gray.svg'
          }
          alt="icons"
          onClick={() => setIsIconMode(true)}
        />
      </div>

      {isIconMode ? (
        <>
          <ProductIconSortedForOrderDetails
            cart={props.order}
            capsules={props.capsules}
            removeProduct={props.removeProduct}
            isIconMode={isIconMode}
            requiredGender={'men'}
          />
          <ProductIconSortedForOrderDetails
            cart={props.order}
            capsules={props.capsules}
            removeProduct={props.removeProduct}
            isIconMode={isIconMode}
            requiredGender={'women'}
          />
        </>
      ) : (
        <>
          <ProductsOrderDetails
            requiredGender={'men'}
            order={props.order}
            capsules={props.capsules}
          />

          <ProductsOrderDetails
            requiredGender={'women'}
            order={props.order}
            capsules={props.capsules}
          />
        </>
      )}

      <div className="exit-container">
        <div className="exit-order-details" onClick={props.onClick}>
          <img
            src="/images/exit-arrow.svg"
            className="arrow-exit"
            alt="arrow-exit"
          />
          <p onClick={props.closeOrderDetails}>Go back</p>
        </div>
      </div>
      <div className="end-of-order-details">
        <h2>END OF ORDER</h2>
      </div>
    </div>
  );
}

export default OrderDetails;
