import React from 'react';

function CapsulesListForOrders(props) {
  return (
    <div className="wrapper-capsules-order">
      {['men', 'women'].map((gender) => (
        <div className="gender-box" key={gender}>
          <h1>{gender.toUpperCase()}</h1>
          {/* <div className="testWrapper"> */}
          <ul className="collection-items-box">
            {props.capsules.length > 0 &&
              props.capsules.map((c, index) => {
                return (
                  c.gender === gender && (
                    <li key={index}>
                      <div className="capsule-name-flex-container">
                        {props.checkAddedProducts(props.cart, c.capsuleId) !==
                        0 ? (
                          <p onClick={() => props.openProductDrawer(index)}>
                            {c.capsuleName}{' '}
                            <span className="order-added-products">
                              (
                              {props.checkAddedProducts(
                                props.cart,
                                c.capsuleId
                              )}
                              )
                            </span>{' '}
                          </p>
                        ) : (
                          <p onClick={() => props.openProductDrawer(index)}>
                            {c.capsuleName}{' '}
                          </p>
                        )}
                        <img
                          onClick={() => props.openProductDrawer(index)}
                          src="/images/exit-arrow.svg"
                          className="arrow-exit-reverse"
                          alt="arrow-exit-reverse"
                        />
                      </div>
                    </li>
                  )
                );
              })}
          </ul>
          {/* </div> */}
        </div>
      ))}
    </div>
  );
}

export default CapsulesListForOrders;
