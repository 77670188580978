// export const checkQty = (cart, pid, size) => {

//   let foundProduct = cart.find((p) => p.product._id === pid);

//   if (foundProduct) {
//     //check for size and qty
//     let foundSizeIndex = foundProduct.pidOrder.findIndex(
//       (s) => s.size === size
//     );
//     if (foundSizeIndex !== -1) {
//       return foundProduct.pidOrder[foundSizeIndex].qty;
//     } else {
//       return 0;
//     }
//   } else {
//     return 0;
//   }
// };
export const checkQty = (cart, pid, size) => {
  // Ensure p.product is defined before accessing _id
  let foundProduct = cart.find((p) => p.product && p.product._id === pid);

  if (!foundProduct) {
    //console.log('Product not found for pid:', pid);
  }

  if (foundProduct) {
    // Check for size and qty
    let foundSizeIndex = foundProduct.pidOrder.findIndex(
      (s) => s.size === size
    );
    if (foundSizeIndex !== -1) {
      return foundProduct.pidOrder[foundSizeIndex].qty;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

//for ADD STOCK MODAL
export const checkQtyForStock = (stockAvailable, size) => {
  console.log('stockAvailable', stockAvailable);
  if (stockAvailable) {
    // Check for size and qty
    let foundSizeIndex = stockAvailable.findIndex((s) => s.size === size);
    if (foundSizeIndex !== -1) {
      return stockAvailable[foundSizeIndex].qty;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const checkAddedProducts = (cart, capId) => {
  let checkNumProducts = cart.filter((p) => {
    // Check if p.product.pxCapsule is an object or an ID
    const capsuleId = p.product.pxCapsule._id
      ? p.product.pxCapsule._id
      : p.product.pxCapsule;
    return capsuleId === capId;
  });

  if (checkNumProducts.length > 0) {
    //Add all qty
    let numProducts = checkNumProducts.length;
    let totalPieces = calculateTotalQuantities(checkNumProducts);
    return totalPieces;
  } else {
    return 0;
  }
};

export const checkAddedProductsTotal = (cart, capId) => {
  if (cart.length > 0) {
    //Add all qty
    let totalPieces = calculateTotalQuantities(cart);
    return totalPieces;
  } else {
    return 0;
  }
};

function calculateTotalQuantities(productsArray) {
  let totalQuantities = 0;

  productsArray.forEach((product) => {
    const pidOrder = product.pidOrder;

    pidOrder.forEach((order) => {
      totalQuantities += order.qty;
    });
  });

  return totalQuantities;
}

export const calcTotalLogos = (productsArray) => {
  let totalAmount = 0;

  productsArray.forEach((product) => {
    let pidOrder = product.pidOrder;
    let isLogoIncluded = product.logo;

    if (isLogoIncluded) {
      pidOrder.forEach((order) => {
        const price = 3;
        totalAmount += price * order.qty;
      });
    }
  });

  return totalAmount;
};

export const calcIVA = (productsArray) => {
  const iva = 0.21;

  const subtotal = calcTotalSubtotal(productsArray).subtotal; //already includes logos
  const ivaTotal = subtotal * iva;

  return ivaTotal;
};

export const calcTotalSubtotal = (productsArray) => {
  const orderCost = 5000;
  const discount = 0.03;
  const logoCost = calcTotalLogos(productsArray);
  const iva = 0.21;

  let subtotalWithoutLogos = 0;

  productsArray.forEach((product) => {
    const pidOrder = product.pidOrder;

    pidOrder.forEach((order) => {
      const price = product.product.price;
      subtotalWithoutLogos += price * order.qty;
    });
  });

  let totalAmount = 0;
  let totalAmountDiscounted = 0;

  if (subtotalWithoutLogos > orderCost) {
    //1. *** DISCOUNTED CASE - Calc subtotal discounted

    //1.1 with discount
    const subtotalDiscounted = logoCost + subtotalWithoutLogos * (1 - discount);
    let ivaTotalDiscounted = subtotalDiscounted * iva;
    totalAmountDiscounted = subtotalDiscounted + ivaTotalDiscounted;

    //1.2 without discount
    const subtotal = logoCost + subtotalWithoutLogos;
    let ivaTotal = subtotal * iva;
    totalAmount = subtotal + ivaTotal;

    //ADD SUBTOTAL DISCOUNTED AND SEND AS WELL

    return {
      subtotal,
      subtotalDiscounted,
      totalAmount,
      totalAmountDiscounted,
      ivaTotal,
      ivaTotalDiscounted,
      discount: true,
    };
  } else {
    //1.CACL subtotal including logos and CALC total adding iva to subtotal
    const subtotal = logoCost + subtotalWithoutLogos;
    let ivaTotal = subtotal * iva;
    let ivaTotalDiscounted = ivaTotal;

    const subtotalDiscounted = subtotal;

    totalAmount = subtotal + ivaTotal;
    totalAmountDiscounted = subtotal + ivaTotal;

    return {
      subtotal,
      subtotalDiscounted,
      totalAmount,
      totalAmountDiscounted,
      ivaTotal,
      ivaTotalDiscounted,
      discount: false,
    };
  }
};

export const homePageOrderTotal = (order) => {
  //console.log('order***', order);
  const totalAmount = order.orderTotal;
  const subtotalAmount = order.orderSubTotal; //includes total logos from db
  const logoTotal = order.logoTotal;
  const discount = order.discount;
  const iva = 1.21;

  let subtotalWithoutLogos = '';
  let totalAmountDiscounted = '';

  if (discount === 0) {
    return { totalAmount, totalAmountDiscounted, subtotalAmount };
  } else {
    //apply discount without logos
    subtotalWithoutLogos = (subtotalAmount - logoTotal) * (1 - discount / 100);
    const subtotalDiscounted = subtotalWithoutLogos + logoTotal;

    //total adding logos and applying iva
    totalAmountDiscounted = (subtotalWithoutLogos + logoTotal) * iva;
    return {
      totalAmount,
      totalAmountDiscounted,
      subtotalAmount: subtotalDiscounted,
    };
  }
};
