import React from 'react';

function InfoLoaderAdmin() {
  return (
    <React.Fragment>
      <div className="loading-placeholder-box-admin">
        <div className="loading-placeholder-b1-admin">
          <div className="loading-placeholder-title-admin"></div>
          <div className="loading-placeholder-sentence-1-admin"></div>
          <div className="loading-placeholder-sentence-2-admin"></div>
          <div className="loading-placeholder-sentence-3-admin"></div>
        </div>
        <div>
          <div className="loading-placeholder-btn-admin"></div>
        </div>
      </div>
      <div className="loading-placeholder-box-admin">
        <div className="loading-placeholder-b1-admin">
          <div className="loading-placeholder-title-admin"></div>
          <div className="loading-placeholder-sentence-1-admin"></div>
          <div className="loading-placeholder-sentence-2-admin"></div>
          <div className="loading-placeholder-sentence-3-admin"></div>
        </div>
        <div>
          <div className="loading-placeholder-btn-admin"></div>
        </div>
      </div>
      <div className="loading-placeholder-box-admin">
        <div className="loading-placeholder-b1-admin">
          <div className="loading-placeholder-title-admin"></div>
          <div className="loading-placeholder-sentence-1-admin"></div>
          <div className="loading-placeholder-sentence-2-admin"></div>
          <div className="loading-placeholder-sentence-3-admin"></div>
        </div>
        <div>
          <div className="loading-placeholder-btn-admin"></div>
        </div>
      </div>
      <div className="loading-placeholder-box-admin">
        <div className="loading-placeholder-b1-admin">
          <div className="loading-placeholder-title-admin"></div>
          <div className="loading-placeholder-sentence-1-admin"></div>
          <div className="loading-placeholder-sentence-2-admin"></div>
          <div className="loading-placeholder-sentence-3-admin"></div>
        </div>
        <div>
          <div className="loading-placeholder-btn-admin"></div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InfoLoaderAdmin;
