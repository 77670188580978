import axios from 'axios';

export const listClients = async (authtoken) =>
  await axios.get(`/api/clients`, {
    headers: {
      authtoken,
    },
  });

export const createClient = async (client, authtoken) =>
  await axios.post(`/api/clients`, client, {
    headers: {
      authtoken,
    },
  });

// export const updateClient = async (clientId, authtoken) =>
//   await axios.post(`/api/clients/${capsuleId}`, {
//     headers: {
//       authtoken,
//     },
//   });
