import React, { useState, useEffect } from 'react';
import HeaderBRK from '../components/HeaderBRKmobile';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

import ClientMobileMenuDrawer from '../drawers/ClientMobileMenuDrawer';
import BackdropMobile from '../backdrop/BackdropMobile';

function Terms() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigate = useNavigate();

  //effect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <React.Fragment>
      <HeaderBRK
        openMobileMenu={openMobileMenu}
        // classes={'dark-header'}
        // location={'product-page'}
      />
      <ClientMobileMenuDrawer show={isMobileMenuOpen} />
      {isMobileMenuOpen && <BackdropMobile onClick={closeMobileMenu} />}
      <div className="terms-box">
        <div className="exit-container">
          <div className="exit-2-container" onClick={() => navigate('/')}>
            <img
              src="/images/exit-arrow.svg"
              className="arrow-exit"
              alt="arrow-exit"
            />
            <p>Go back</p>{' '}
            <span style={{ fontStyle: 'italic' }}>/ Terms and Conditions</span>
          </div>

          {/* <h2>AW24 Collection</h2> */}
        </div>
        <hr className="horizontal-line"></hr>
        <div className="terms-body">
          <h1>Terms and Conditions</h1>
          <p>
            This agreement ("Agreement") is made between the company using our
            platform, hereinafter referred to as the "Company," and BE REALLY
            KEEN.
          </p>

          <h2>1. Order Confirmation:</h2>
          <p>
            Upon placing an order, the Company confirms its intention to proceed
            with the purchase of the specified products/services.
          </p>

          <h2>2. Order Acceptance:</h2>
          <p>
            BE REALLY KEEN reserves the right to accept or decline any order
            placed by the Company.
          </p>

          <h2>3. Agreement to Pay:</h2>
          <p>
            The Company acknowledges that no transactions occur on this Platform
            at the time of order confirmation. The Company agrees to pay for the
            confirmed order upon commencement of the production process.
          </p>

          <h2>4. Production Commencement:</h2>
          <p>
            Production initiates promptly upon the conclusion of the sales
            period, adhering closely to the predetermined schedule.
          </p>

          <h2>5. Payment Terms:</h2>
          <p>
            The Company agrees to make payments as per the terms specified in
            the invoice provided by BE REALLY KEEN.
          </p>

          <h2>6. Cancellation:</h2>
          <p>
            The Company may cancel an order within a specified period before
            production commencement. Cancellation terms and fees may apply.
          </p>

          <h2>7. Changes to Orders:</h2>
          <p>
            Any changes to the order must be communicated in writing and agreed
            upon by both parties before production commencement.
          </p>

          <h2>8. Delivery:</h2>
          <p>
            Delivery dates are estimates and subject to change. BE REALLY KEEN
            will make reasonable efforts to meet agreed-upon delivery timelines.
          </p>

          <h2>9. Quality Assurance:</h2>
          <p>
            BE REALLY KEEN strives to maintain high-quality standards. The
            Company is encouraged to report any product defects or discrepancies
            promptly.
          </p>

          <h2>10. Confidentiality:</h2>
          <p>
            Both parties agree to keep confidential any proprietary or sensitive
            information shared during the course of the business relationship.
          </p>

          <h2>12. Governing Law:</h2>
          <p>
            This Agreement will be subject to and interpreted in line with the
            laws of Spain.
          </p>

          <p>
            By confirming an order on this Platform, the Company agrees to these
            terms and conditions. This Agreement constitutes the entire
            understanding between the Company and BE REALLY KEEN and supersedes
            any prior agreements or understandings.
          </p>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Terms;
