import React, { useState, useEffect } from 'react';
import HeaderBRK from '../components/HeaderBRKmobile';

import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

import ClientMobileMenuDrawer from '../drawers/ClientMobileMenuDrawer';
import BackdropMobile from '../backdrop/BackdropMobile';

const TECH = [
  {
    name: '01 Sustainable',
    text: 'Sustainable technical fabric made from regenerated nylon thread derived from the recycling of fishing nets.',
  },
  {
    name: '02 Four way stretch',
    text: 'Bielastic fabric designed for ultimate comfort and flexibility in all directions, thanks to its high elastane composition. With its remarkable stretchability, this fabric guarantees maximum comfort and freedom of movement.',
  },
  {
    name: '03 UV protection',
    text: 'By increasing the thread density per square meter, we have achieved a fabric with enhanced coverage, effectively blocking the penetration of ultraviolet rays. This innovative design provides superior protection against harmful UV radiation,',
  },
  {
    name: '04 Breathable',
    text: 'This fabric promotes excellent moisture-wicking properties, allowing for quick evaporation and keeping your skin dry and comfortable. Enjoy optimal breathability and enhanced moisture management with this innovative textile.',
  },
  {
    name: '05 Antibacterial',
    text: 'Experience advanced technology that combats odor by utilizing silver ions within the fabric’s fibers. This innovative textile effectively blocks bacteria growth, ensuring long-lasting freshness and confidence.',
  },

  {
    name: '06 Quick drying',
    text: 'Introducing advanced sweat dispersion technology, achieved through the unique structure and choice of fibers. This innovative fabric quickly wicks away sweat, allowing it to evaporate rapidly, keeping you dry and comfortable. Experience superior moisture management and stay fresh during even the most intense activities.',
  },
  {
    name: '07 Perfect fit',
    text: 'Our fabrics are lightweight and adaptable. Experience the ultimate comfort and freedom of movement as our garments effortlessly conform to your body.',
  },
  {
    name: '08 Soft',
    text: 'By increasing the number of filaments in the fabric’s structure, we have achieved a remarkably smooth and soft texture. This enhanced fabric provides a luxurious feel against the skin, ensuring unparalleled comfort. Experience the indulgent softness and enjoy a truly delightful touch with this innovative textile.',
  },
  {
    name: '09 Thermal',
    text: 'Experience unrivaled thermal performance with our advanced fabric technology. Our garments excel in heat retention, providing optimal warmth in any weather.',
  },
  {
    name: '10 Windproof',
    text: 'Thanks to the integration of a polyurethane membrane into the fabric, we effectively eliminate the penetration of air to the interior.',
  },
  {
    name: '11 Water repellent',
    text: 'Water repellent membrane that effectively prevents water penetration. Designed for continuous rain conditions, this fabric ensures optimal protection and keeps you dry and comfortable.',
  },
];

function TechPage() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const navigate = useNavigate();

  //effect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <React.Fragment>
      <HeaderBRK
        openMobileMenu={openMobileMenu}
        // classes={'dark-header'}
        // location={'product-page'}
      />
      <ClientMobileMenuDrawer show={isMobileMenuOpen} />
      {isMobileMenuOpen && <BackdropMobile onClick={closeMobileMenu} />}

      <div className="box-container-tech-page">
        <div className="exit-container">
          <div className="exit-2-container" onClick={() => navigate('/')}>
            <img
              src="/images/exit-arrow.svg"
              className="arrow-exit"
              alt="arrow-exit"
            />
            <p>Go back</p> <span style={{ fontStyle: 'italic' }}>/ Tech +</span>
          </div>

          {/* <h2>AW24 Collection</h2> */}
        </div>
        <hr className="horizontal-line"></hr>
        <h1>Tech +</h1>
        <div className="flex-container-techs">
          {TECH.map((kp, index) => {
            {
              /* return index % 2 === 0 ? ( */
            }
            return (
              <div className="flex-container-individual-tech" key={index}>
                <h2>{kp.name}</h2>
                <p>{kp.text}</p>
              </div>
            );
          })}
        </div>
        {/* <div className="grid-container-highlight-item">
          {highlights.map((item, index) => {
            return (
              <div className="flex-container-key" key={index}>
                <h2>{item.name}</h2>
                <p>{item.text}</p>
              </div>
            );
          })}
        </div> */}
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default TechPage;
