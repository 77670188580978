import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonBRK from '../components/buttons/ButtonBRK';
//import { auth } from '../firebase';

import { createOrUpdateUser } from '../functions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, signInWithEmailLink, updatePassword } from 'firebase/auth';
import { toast, ToastContainer } from 'react-toastify';

function CompleteRegisPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const auth = getAuth();

  //state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    //take email, ... from localStorage (setItem in "RegisterScreen")
    //setEmail(window.localStorage.getItem('emailForRegistration'));

    if (user && user.token) {
      //console.log('USE EFFECT IT WOULD NAVEGATE');
      //navigate('/home-page');
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    //some validation
    if (!email || !password) {
      toast.error('Please make sure email and password are provided.');
      return;
    }

    if (password.length < 8) {
      toast.error(
        'Please ensure that your password is a minimum of 8 characters in length.'
      );
      return;
    }

    try {
      const result = await signInWithEmailLink(
        auth,
        email,
        window.location.href
      );

      if (result.user.emailVerified) {
        //remove user from local storage
        // window.localStorage.removeItem('emailForRegistration');
        // window.localStorage.removeItem('subscriptionToNewsletter');
        //window.localStorage.removeItem('nameForRegistration');
        //window.localStorage.removeItem('surnameForRegistration');
        //get user id token
        //  console.log('********************************************');
        // console.log('result.user.emailVerified, result', result);
        // console.log('********************************************');

        let user = auth.currentUser;
        // console.log('**** user auth', user);

        let passwordSuccesfull = false;

        //await user.updatePassword(password);
        updatePassword(user, password)
          .then(() => {
            // Update successful.
            passwordSuccesfull = true;
          })
          .catch((error) => {
            // An error ocurred
            // ...
          });

        // console.log('**** passwordSuccesfull', passwordSuccesfull);

        if (passwordSuccesfull) {
          const idTokenResult = await user.getIdTokenResult();
          // console.log('**** idTokenResult', idTokenResult);
          //redux store

          //call function with axios request (post) to backend

          createOrUpdateUser(idTokenResult.token)
            .then((res) => {
              dispatch({
                type: 'LOGGED_IN_USER',
                payload: {
                  name: res.data.name,
                  email: res.data.email,
                  token: idTokenResult.token,
                  role: res.data.role,
                  _id: res.data._id,
                },
              });

              //navigate('/home-page');

              //if subscriptionToNewsletter in local storage is true, save
              // if (newsletter) {
              //   userNewsletter(newsletter, idTokenResult.token)
              //     .then((res) => console.log('subscribed!'))
              //     .catch((err) => console.log(err));
              // }
            })
            .catch((err) => {
              //console.log('Error registering.', err);
              toast.error('Error registering.');
            });
        }

        //redirect after registration
      }
    } catch (error) {
      //console.log('error firebase', error);
      toast.error(error.message);
    }
  };

  const onChangeEmailHandle = (event) => {
    let newEmail = event.target.value;
    setEmail((prevEmail) => newEmail);
  };

  const onChangePasswordHandle = (event) => {
    let newPassword = event.target.value;
    setPassword((prevPassword) => newPassword);
  };

  return (
    <div className="container">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <form className="form">
        <h1 className="title">Registration</h1>
        <div>
          <p>Username</p>
          <input
            className="input-log-in-username"
            type="text"
            placeholder="Enter email"
            name="email"
            value={email}
            onChange={onChangeEmailHandle}
            // disabled
          ></input>
          <p>Password</p>
          <input
            className="input-log-in-password"
            type="text"
            placeholder="Enter password"
            name="password"
            value={password}
            onChange={onChangePasswordHandle}
          ></input>
        </div>

        <ButtonBRK name="Register" onClick={handleSubmit} />
        {/* <p>Contact us if you do not have an account yet.</p> */}
      </form>
    </div>
  );
}

export default CompleteRegisPage;

{
  /* <p className="error">
        Either you do not have an account or your username or password is wrong
      </p> */
}
