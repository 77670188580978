import React from 'react';
import { checkSizes } from '../../functions/check-sizes';
import { capFirstLetter } from '../../functions/cap-first-letter';
import ButtonChangeQty from '../buttons/ButtonChangeQty';
import { sortByGenderSummaryBag } from '../../functions/sortProducts';

function ProductsSorted(props) {
  const orderSortedEntries = Object.entries(
    sortByGenderSummaryBag(props.cart, props.requiredGender, props.capsules)
      .orderSorted
  );

  return (
    <React.Fragment>
      {orderSortedEntries.length === 0 ? (
        <div className="products-icons-setup-box">
          <p style={{ fontStyle: 'italic' }}>
            (No products have been added from the {props.requiredGender}'s
            collection yet)
          </p>
        </div>
      ) : (
        orderSortedEntries.map(([capsuleName, productList]) => (
          <React.Fragment key={capsuleName}>
            {productList.map((p) => (
              <div className="summary-card" key={p.product._id}>
                <div className="product-info-flex-container">
                  <div className="info-box-flex-container">
                    {
                      <img
                        src={
                          p.product.image.url || '/images/not-found-image.png'
                        }
                        alt="product-img"
                        className="info-img"
                      />
                    }
                    <div className="info-item">
                      <div className="name-price-box">
                        <h2>{`${capFirstLetter(p.product.name)}`}</h2>
                        <div className="price-item">
                          <h2>{p.product.price}€</h2>
                          <p>/ SRP: {p.product.srp}€</p>
                        </div>
                      </div>
                      <p>{`Ref. ${p.product.reference}`}</p>
                      <p>{p.product.color}</p>
                      <p>{p.product.composition}</p>
                      <p>{p.product.description}</p>
                    </div>
                  </div>
                </div>
                <div className="size-box-flex-container">
                  {props.cart &&
                    checkSizes(p.product, props.capsules).map((item, index) => (
                      <ButtonChangeQty
                        key={index}
                        size={item}
                        addQty={() => props.addQty(p.product, item)}
                        reduceQty={() => props.reduceQty(p.product, item)}
                        cart={props.cart}
                        pid={p.product._id}
                      />
                    ))}
                </div>
                <div className="qty-flex-container">
                  <p onClick={() => props.removeProduct(p.product)}>
                    Delete product
                  </p>
                  <div className="flex-container-add-logo">
                    {props.isLogoAdded(p.product._id) ? (
                      <>
                        <img src="/images/logo-added-border.svg" alt="logo" />
                        {/* <h3>Logo added</h3> */}
                        <p onClick={() => props.removeLogoToProduct(p.product)}>
                          Remove
                        </p>
                      </>
                    ) : (
                      <p onClick={() => props.addLogoToProduct(p.product)}>
                        Add logo
                      </p>
                    )}
                  </div>
                </div>
                <hr className="horizontal-line"></hr>
              </div>
            ))}
          </React.Fragment>
        ))
      )}
    </React.Fragment>
  );
}

export default ProductsSorted;
