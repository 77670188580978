import React from 'react';
import SelectorBRK from '../../input/SelectorBRK';

function UpdateCapsule(props) {
  return (
    <div className="flex-container-change-capsule">
      <h2>Change to capsule</h2>
      <SelectorBRK
        classes={'color-white'}
        onSelectGender={props.changeProductCapsule}
        options={props.capsules}
      />
    </div>
  );
}

export default UpdateCapsule;
