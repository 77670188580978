import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LogInItemPage from './pages/LogInItemPage';
import HomePage from './pages/HomePage';
import OrderPage from './pages/OrderPage';
import OrderStockPage from './pages/OrderStockPage';
import { useDispatch } from 'react-redux';
import TechPage from './pages/TechPage';
import DashboardPage from './pages/admin/DashboardPage';
import CollectionsPage from './pages/admin/CollectionsPage';
import OrdersPage from './pages/admin/OrdersPage';
import AnalyticsPage from './pages/admin/AnalyticsPage';
import { currentUser } from './functions/auth';
import { auth } from './firebase';
import ClientRoute from './routes/ClientRoute';
import AdminRoute from './routes/AdminRoute';
import HomePagePlus from './pages/HomePagePlus';
import ClientsPage from './pages/admin/ClientsPage';
import CompleteRegisPage from './pages/CompleteRegisPage';
import ProductPage from './pages/ProductsPage';
import ClientHomePage from './pages/ClientHomePage';
import ProductsAdminPage from './pages/admin/ProductsAdminPage';
import Terms from './pages/Terms';
import NotFoundScreen from './pages/NotFoundScreen';
import CustomDesign from './pages/CustomDesign';

// import React, { useState, useEffect, lazy, Suspense } from 'react';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { currentUser } from './functions/auth';
// import { auth } from './firebase';
// import LazyLoadingFallback from './routes/LazyLoadingFallback';

// const LogInItemPage = lazy(() => import('./pages/LogInItemPage'));
// const HomePage = lazy(() => import('./pages/HomePage'));
// const OrderPage = lazy(() => import('./pages/OrderPage'));
// const TechPage = lazy(() => import('./pages/TechPage'));
// const DashboardPage = lazy(() => import('./pages/admin/DashboardPage'));
// const CollectionsPage = lazy(() => import('./pages/admin/CollectionsPage'));
// const OrdersPage = lazy(() => import('./pages/admin/OrdersPage'));
// const AnalyticsPage = lazy(() => import('./pages/admin/AnalyticsPage'));
// const HomePagePlus = lazy(() => import('./pages/HomePagePlus'));
// const ClientsPage = lazy(() => import('./pages/admin/ClientsPage'));
// const CompleteRegisPage = lazy(() => import('./pages/CompleteRegisPage'));
// const ProductPage = lazy(() => import('./pages/ProductsPage'));
// const ClientHomePage = lazy(() => import('./pages/ClientHomePage'));
// const CustomDesign = lazy(() => import('./pages/CustomDesign'));
// const ProductsAdminPage = lazy(() => import('./pages/admin/ProductsAdminPage'));
// const Terms = lazy(() => import('./pages/Terms'));
// const NotFoundScreen = lazy(() => import('./pages/NotFoundScreen'));

const App = () => {
  const dispatch = useDispatch();
  //const auth = getAuth();
  console.log('EN APP');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();

        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: 'LOGGED_IN_USER',
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: `${idTokenResult.token}`,
                role: res.data.role,
                _id: res.data._id,
              },
            });
          })
          .catch((err) => {
            //console.log(err, '***');
          });
      }
    });

    // cleanup
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <BrowserRouter>
      {/* <Suspense fallback={<LazyLoadingFallback />}> */}
      {/* {shouldShowHeaderAndFooter && <Header />} */}
      <Routes>
        <Route exact path="/login" element={<LogInItemPage />} />
        <Route exact path="/complete" element={<CompleteRegisPage />} />
        {/******************* USER ********************** */}
        {/* <Route
          path="/home-page"
          element={
            <ClientRoute>
              <HomePage />
            </ClientRoute>
          }
        /> */}

        <Route
          path="/"
          element={
            <ClientRoute>
              <HomePagePlus />
            </ClientRoute>
          }
        />

        <Route
          path="/client-home-page"
          element={
            <ClientRoute>
              <ClientHomePage />
            </ClientRoute>
          }
        />

        <Route
          path="/tech"
          element={
            <ClientRoute>
              <TechPage />
            </ClientRoute>
          }
        />

        <Route
          path="/product-page/:gender"
          element={
            <ClientRoute>
              <ProductPage />
            </ClientRoute>
          }
        />

        <Route
          path="/order/:oid"
          element={
            <ClientRoute>
              <OrderPage />
            </ClientRoute>
          }
        />

        <Route
          path="/order-stock/:oid"
          element={
            <ClientRoute>
              <OrderStockPage />
            </ClientRoute>
          }
        />

        <Route
          path="/terms-and-conditions"
          element={
            <ClientRoute>
              <Terms />
            </ClientRoute>
          }
        />

        <Route
          path="/custom-design"
          element={
            <ClientRoute>
              <CustomDesign />
            </ClientRoute>
          }
        />

        {/* <Route
          path="/brand-highlights"
          element={
            <ClientRoute>
              <BrandHighlights />
            </ClientRoute>
          }
        /> */}

        {/* <Route exact path="/order" element={<OrderPage />} />
        <Route exact path="/brand-highlights" element={<BrandHighlights />} /> */}

        {/******************* ADMIN ********************** */}
        <Route
          path="/dashboard-page"
          element={
            <AdminRoute>
              <DashboardPage />
            </AdminRoute>
          }
        />
        <Route
          path="/collections-page"
          element={
            <AdminRoute>
              <CollectionsPage />
            </AdminRoute>
          }
        />
        <Route
          path="/products-page"
          element={
            <AdminRoute>
              <ProductsAdminPage />
            </AdminRoute>
          }
        />
        <Route
          path="/orders-page"
          element={
            <AdminRoute>
              <OrdersPage />
            </AdminRoute>
          }
        />
        <Route
          path="/clients-page"
          element={
            <AdminRoute>
              <ClientsPage />
            </AdminRoute>
          }
        />
        <Route
          path="/analytics-page"
          element={
            <AdminRoute>
              <AnalyticsPage />
            </AdminRoute>
          }
        />

        {/* <Route exact path="/dashboard-page" element={<DashboardPage />} /> */}
        {/* <Route exact path="/collections-page" element={<CollectionsPage />} />
        <Route exact path="/orders-page" element={<OrdersPage />} />
        <Route exact path="/analytics-page" element={<AnalyticsPage />} /> */}
        {/* Add a wildcard route for anything that doesn't match the routes above */}
        <Route
          path="*"
          element={
            <ClientRoute>
              <NotFoundScreen />
            </ClientRoute>
          }
        />
      </Routes>
      {/* {shouldShowHeaderAndFooter && <Footer />} */}
      {/* </Suspense> */}
    </BrowserRouter>
  );
};

export default App;
