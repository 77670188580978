import { checkQty } from '../../functions/qty-check';

function SizeQtyOrderDetail(props) {
  return (
    <div
      className={
        checkQty(props.order, props.pid, props.item) === 0
          ? 'size-qty-details'
          : 'size-qty-details above-zero-qty'
      }
    >
      <p>
        {`${props.item}/`}
        <span>{checkQty(props.order, props.pid, props.item)}</span>
      </p>
    </div>
  );
}

export default SizeQtyOrderDetail;
