import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currentUser } from '../functions/auth';
import LoadingToRedirect from './LoadingToRedirect';

//See that Loading to redirect is used to wait for the async request

const ClientRoute = ({ children, ...rest }) => {
  const user = useSelector((state) => state.user);

  return user && user.token ? children : <LoadingToRedirect />;
  // const [ok, setOk] = useState(false);
  // const [msgRefreshPage, setMsgRefreshPage] = useState(false);

  // useEffect(() => {
  //   if (user && user.token) {
  //     currentUser(user.token)
  //       .then((res) => {
  //         setOk(true);
  //         console.log('PASSED THRO CLIENT ROUTE');
  //       })
  //       .catch((error) => {
  //         console.log(error, 'Please refresh the page');
  //         setMsgRefreshPage(true);
  //         setOk(false);
  //       });
  //   }
  // }, [user]);

  // return ok ? children : <LoadingToRedirect msgRefreshPage={msgRefreshPage} />;
};

export default ClientRoute;
