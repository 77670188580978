import { checkQty } from '../../functions/qty-check';

function ButtonChangeQty(props) {
  return (
    <div className="size-btn-box">
      {checkQty(props.cart, props.pid, props.size) === 0 ? (
        <p>{props.size}</p>
      ) : (
        <p className="light-size">{props.size}</p>
      )}
      {/* <p>{props.size}</p> */}
      <div className="btn-flex-container">
        <button className="add-quantity-button" onClick={props.reduceQty}>
          <span>-</span>
        </button>
        {checkQty(props.cart, props.pid, props.size) === 0 ? (
          <p>{checkQty(props.cart, props.pid, props.size)}</p>
        ) : (
          <p>
            <>{checkQty(props.cart, props.pid, props.size)}</>
          </p>
        )}
        {/* <p>{checkQty(props.cart, props.pid, props.size)}</p> */}
        <button className="add-quantity-button" onClick={props.addQty}>
          <span>+</span>
        </button>
      </div>
    </div>
  );
}

export default ButtonChangeQty;
