import React, { useState, useEffect } from 'react';
import ButtonBRK from '../components/buttons/ButtonBRK';
import ButtonInfo from '../components/buttons/ButtonInfo';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeaderBRK from '../components/HeaderBRKmobile';
import ClientMobileMenuDrawer from '../drawers/ClientMobileMenuDrawer';
import BackdropMobile from '../backdrop/BackdropMobile';
import { useSelector } from 'react-redux';
import {
  getOrdersClient,
  createOrder,
  getOrderById,
} from '../functions/orders';
import { toast, ToastContainer } from 'react-toastify';
import Modal from '../modals/Modal';
import OrderDetails from '../components/UI/OrderDetails';
import OrderDetailsDrawer from '../drawers/OrderDetailsDrawer';
import Backdrop from '../backdrop/Backdrop';
import { homePageOrderTotal } from '../functions/qty-check';
import { listCapsules } from '../functions/capsules';

//========== CONST ==========================================
const currentBrochure = 'New brochure AW24 available';
const clientOrderHistory = ['SS23 Collection', 'AW23 Collection'];

//=================================================================
function HomePage() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [collectionId, setCollectionId] = useState('6526a9adad300220c9b74da1');
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  //one holds the "cart" form of the order, the other the details
  const [individualOrder, setIndividualOrder] = useState([]);
  const [individualOrderDetails, setIndividualOrderDetails] = useState([]);
  const [capsules, setCapsules] = useState([]);

  //========== USE EFFECT =======================================
  useEffect(() => {
    loadOrders();
  }, []);

  const loadOrders = () => {
    const clientId = user._id;
    getOrdersClient(clientId, user.token)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => console.log(err));
  };

  //========== FUNCTIONS =======================================
  function checkClientOrder() {
    if (orders && orders.length > 0) {
      return orders.map((order) => (
        <ul key={order._id}>
          {order.confirmed ? (
            <li className="flex-container-client-status">
              <h2>AW24 Collection: Your order is confirmed.</h2>
              <p onClick={() => openOrderDetails(order._id)}>See details.</p>
            </li>
          ) : (
            <li className="flex-container-client-status">
              <h2>AW24 Collection: You have an order in progress.</h2>
              <div className="flex-container-status-items">
                {order.discount === 0 ? (
                  <h3>
                    ({`Total neto: ${homePageOrderTotal(order).totalAmount} €`})
                  </h3>
                ) : (
                  <h3>
                    (Total neto:{' '}
                    <span style={{ textDecoration: 'line-through' }}>{`${
                      homePageOrderTotal(order).totalAmount
                    } €`}</span>{' '}
                    <span style={{ color: 'red' }}>{`${homePageOrderTotal(
                      order
                    ).totalAmountDiscounted.toFixed(2)} €`}</span>
                    )
                  </h3>
                )}

                <p onClick={() => continueWithOrderHandler(order._id)}>
                  Continue with order
                </p>
                {/* <p onClick={() => confirmOrderHandler(order._id)}>
                  Confirm order
                </p> */}
              </div>
            </li>
          )}
        </ul>
      ));
    } else {
      return (
        <ul>
          <li>No orders found.</li>
        </ul>
      );
    }
  }

  const handleClick = () => {
    const checkOrderInProgress = isOrderOnGoing();

    if (checkOrderInProgress) {
      toast.info('You have an order in progress already.');
    } else {
      //1.- Create new order in db
      createOrder({ collectionId: collectionId }, user.token)
        .then((res) => {
          if (res.data.message === 'ok') {
            const resolveAfter3Sec = new Promise((resolve) =>
              setTimeout(resolve, 2000)
            );
            toast
              .promise(resolveAfter3Sec, {
                pending: 'Preparing collection',
                success: 'Collection prepared',
                error: 'Something went wrong. Please refresh the page.',
              })
              .then(() => {
                setTimeout(() => {
                  navigate(`/order/${res.data.orderId}`);
                  //Delete products from cart
                }, 1000);
              });
          } else {
            toast.error('Could not create new order. Please refresh the page.');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Could not create new order (db)');
        });
    }
  };

  function openNewWindow(url) {
    window.open(url, '_blank');
  }

  const handleClickGoToArticleSolheim = () => {
    openNewWindow(
      'https://golfindustria.es/brk-be-really-keen-dara-a-conocer-en-finca-cortesin-su-coleccion-especial-solheim-cup-2023-durante-la-semana-del-evento/'
    ); // Replace with your desired URL
  };

  const handleClickGoToArticleTheOpen = () => {
    openNewWindow(
      'https://golfindustria.es/brk-be-really-keen-repite-como-colaborador-oficial-de-the-open-con-colecciones-de-ropa-deportiva-disenadas-y-fabricadas-en-espana/#'
    ); // Replace with your desired URL
  };

  const goHighlightsPageHandleClick = () => {
    navigate('/brand-highlights');
  };

  //Functions
  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const isOrderOnGoing = () => {
    //check if there is any order on going to handle the btn
    const isAnyOrderInPorgress = orders.find((o) => o.confirmed === false);

    if (isAnyOrderInPorgress) {
      return true;
    } else {
      return false;
    }
  };

  const continueWithOrderHandler = (orderId) => {
    getOrderById(orderId, user.token)
      .then((res) => {
        if (res.data.isOrderConfirmed) {
          toast.info('This order has already being confirmed.');
          loadOrders();
          return;
        }
        //if order has not being confirmed yet
        navigate(`/order/${orderId}`);
      })
      .catch((err) => {
        toast.error('Could not load the order. Please refresh the page.');
      });
  };

  const closeOrderDetails = () => {
    setShowOrderDetails(false);
    setIndividualOrder([]);
    setIndividualOrderDetails();
  };

  const openOrderDetails = (orderId) => {
    //prepare the details
    let order = orders.find((o) => o._id === orderId);
    if (order) {
      setIndividualOrderDetails(order);
    } else {
      toast.error('Could not find the order!');
      return;
    }

    //prepare capsules
    listCapsules(user.token)
      .then((res) => {
        setCapsules(res.data.capsules);
      })
      .catch((err) => console.log(err));

    //prepare the products
    getOrderById(orderId, user.token)
      .then((res) => {
        setIndividualOrder(res.data.cart);
        setTimeout(() => setShowOrderDetails(true), 500);
      })
      .catch((err) => {
        toast.error('Could not load the order. Please refresh the page.');
      });
  };

  // console.log('orders', orders);
  // console.log('capsules', capsules);

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/******************* COMMON - REFACTOR ***************************/}
      {/* <Header /> */}
      <HeaderBRK openMobileMenu={openMobileMenu} />
      <ClientMobileMenuDrawer show={isMobileMenuOpen} />
      {isMobileMenuOpen && <BackdropMobile onClick={closeMobileMenu} />}
      {/******************* COMMON - REFACTOR ***************************/}
      {/* {individualOrder.length > 0 && (
        <Modal
          show={showOrderDetails}
          onCancel={closeOrderDetails}
          header="Order details"
        >
          <OrderDetails order={individualOrder} />
        </Modal>
      )} */}
      {showOrderDetails && <Backdrop onClick={closeOrderDetails} />}
      {individualOrderDetails && (
        <OrderDetailsDrawer show={showOrderDetails}>
          <OrderDetails
            order={individualOrder}
            orderDet={individualOrderDetails}
            closeOrderDetails={closeOrderDetails}
            capsules={capsules}
          />
        </OrderDetailsDrawer>
      )}

      <div className="box-container-home-page">
        <div
          className="grid-container-create-brochure"
          // style={backgroundStyles}
        >
          <div className="info-flex-container">
            <h1>{currentBrochure}</h1>
          </div>
          <div className="btn-home-page-flex-container">
            <ButtonBRK name="Create new order (AW24)" onClick={handleClick} />
            {/* {isOrderOnGoing() ? (
              <ButtonBRK
                name="Continue with order (AW24)"
                onClick={handleClick}
              />
            ) : (
              <ButtonBRK name="Create new order (AW24)" onClick={handleClick} />
            )} */}

            <ButtonInfo
              name="Why our collections?"
              onClick={goHighlightsPageHandleClick}
            />
          </div>
          {/* <div style={overlayStyles}></div> */}
        </div>
        <div className="flex-container-order-history">
          <div className="order-history">
            <p>
              <b>Order history</b>
            </p>
            <div>{checkClientOrder()}</div>
          </div>
        </div>
        <h1 className="offer-title">Unlock more with our expertise</h1>
        <div className="grid-container-other-work">
          <div className="flex-container-work">
            <img src="/images/design.svg" alt="img" className="img-work" />
            <h2>Custom-made</h2>
            <p>
              We offer personalized collections. Let our design team help you.
            </p>
          </div>
          <div className="flex-container-work">
            <img src="/images/custome.svg" alt="img" className="img-work" />
            <h2>Staff</h2>
            <p>
              Elevate your team's attire and enjoy significant discounts on our
              garments.
            </p>
          </div>
          <div className="flex-container-work">
            <img
              src="/images/label-collection.svg"
              alt="img"
              className="img-work"
            />
            <h2>Own-label-collection</h2>
            <p>
              Choose own-label-collections for your products and give your
              customers a new experience.
            </p>
          </div>
        </div>
        <div className="grid-container-all-season">
          {/* <div></div> */}
          {/* <ButtonBRK name="Show me more" classes={'btn-all-season'} /> */}

          <div className="flex-container-all-season-info">
            <h1>All-season</h1>
            <h2>Year-round essentials.</h2>
            <p>
              Get special discounts when you order in quantities of 50 pieces or
              more
            </p>
          </div>
        </div>
        <h1 className="official-suppliers-title">Official Suppliers</h1>
        <div className="flex-container-official-supplier">
          <img
            src="/images/the-open.svg"
            alt="img-the-open"
            className="img-the-open"
          />
          <div>
            <h1>The Open</h1>
            <h2>Proud official supplier for the British Open</h2>
            <h3>St. Andrews 150th & Royal Liverpool 151th</h3>
            <ButtonInfo
              name="Read article"
              onClick={handleClickGoToArticleTheOpen}
            />
          </div>
        </div>
        <div className="flex-container-official-supplier solheim-reverse">
          <div>
            <h1>The Solheim Cup</h1>
            <h2>Proud official supplier for the Solheim Cup</h2>
            <h3>Finca Cortesín</h3>
            <ButtonInfo
              name="Read article"
              onClick={handleClickGoToArticleSolheim}
            />
          </div>
          <img
            src="/images/the-solheim.svg"
            alt="img-the-open"
            className="img-the-solheim"
          />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default HomePage;
